import React from "react";
import ImgCarousel from "./ImageCarousel/ImgCarousel";
import config from "../../../config";

const ProductImages = ({ data, isPharma }) => {
  let Images = data.length ? data : [];

  return (
    <div>
      <ImgCarousel ProductImages={Images} isPharma={isPharma} />
    </div>
  );
};

export default ProductImages;
