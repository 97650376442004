import React, {useEffect} from 'react';
import Title from '../../../components/Title'
import {clearCart} from "../../../redux/actions/cart";
import {fetchPayCreate} from "../../../redux/actions/order";
import {connect} from "react-redux";
import querystring from "querystring";
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

function formatMoney(n) {
    return "BDT " + (Math.round(n * 100) / 100).toLocaleString();
}

let OrderItems = props => {
    let {classes, active, authData, paymentCreate, paySuccessData, paySuccessLoading, paySuccessErr} = props
    let payment = active ? active.payment_method === 'online-payment' ? 'Online Payment' : 'Cash on Delivery' : null

    return(
        <>
            <div className={classes.header}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={"left"} style={{
                                fontFamily: 'Poppins ,sans-serif',
                                fontWeight: 500,
                                fontSize: 12
                            }}>Product Title</TableCell>
                            <TableCell align={"center"} style={{
                                fontFamily: 'Poppins ,sans-serif',
                                fontWeight: 500,
                                fontSize: 12
                            }}>Qty</TableCell>
                            <TableCell align={"right"} style={{
                                fontFamily: 'Poppins ,sans-serif',
                                fontWeight: 500,
                                fontSize: 12
                            }}>Price</TableCell>
                        </TableRow>
                    </TableHead>

                    {props?.active?.OrderProducts && props?.active?.OrderProducts.map((orderItem) => {
                        return (
                            <>
                                <TableRow>
                                    <TableCell align={"left"} style={{
                                        fontFamily: 'Poppins ,sans-serif',
                                        fontWeight: 400,
                                        fontSize: 12
                                    }}>{orderItem.name}</TableCell>
                                    <TableCell align={"center"} style={{
                                        fontFamily: 'Poppins ,sans-serif',
                                        fontWeight: 400,
                                        fontSize: 12
                                    }}>{orderItem.quantity}</TableCell>
                                    <TableCell align={"right"} style={{
                                        fontFamily: 'Poppins ,sans-serif',
                                        fontWeight: 400,
                                        fontSize: 12
                                    }}>{parseFloat(orderItem.total).toFixed(1)}</TableCell>
                                </TableRow>
                            </>
                        );
                    })}
                </Table>
            </div>
            <div className={classes.header}>
                <div>
                    <Title
                        text={'Payment Method'}
                        fontSize={16}
                        lineHeight={24}
                        fontWeight={'700'}
                        color={'#313132'}
                        margin={'10px 0 0 0'}
                    />
                    <Title
                        text={payment}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'400'}
                        color={'#313132'}
                        margin={'0 0 10px 0'}
                    />
                </div>
                <div>
                    <Title
                        text={'Total payable'}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'400'}
                        color={'#313132'}
                        margin={'10px 0 0 0'}
                    />
                    <Title
                        text={formatMoney(parseFloat(active.total).toFixed(2))}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'700'}
                        color={'#313132'}
                        margin={'0 0 10px 0'}
                    />
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        paySuccessData: state.oderReducer.paySuccessData,
        paySuccessLoading: state.oderReducer.paySuccessLoading,
        paySuccessErr: state.oderReducer.paySuccessErr,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentCreate: (data, token) => dispatch(fetchPayCreate(data, token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItems);
