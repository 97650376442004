import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import { Row, Col, Container } from "react-bootstrap";
import {connect} from "react-redux";
import UpdateAccount from "./components/UpdateAccount";
import UpdateAddress from "./components/UpdateAddress";
import {updateProfile} from "../../redux/actions/auth";
import RecoverPhone from "../Login/RecoverPhone";
import ResetPasswordForm from "../Login/ResetPasswordForm";
import OtpVarificationForm from "../Login/OtpVarificationForm";
import {Dialog} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import LoginForm from "../Login/LoginForm";
import SignUpMobile from "../Login/SignUpMobile";
import SignUpPassword from "../Login/SignUpPassword";
import VerifySuccess from "../Login/VerifySuccess";

const ProfileMain = ({authData, token, updateProfile}) => {
    const [active, setActive] = useState(0);
    const [dailogName, setDailogName] = useState();
    const [otpFor, setOtpFor] = useState();
    const [openLogin, setOpenLogin] = useState(false);
    const history = useHistory();

    const handleLoginOpen = () => {
        setDailogName("recoverPhone")
        setOpenLogin(true);
        document.querySelector("body").classList.add("modal-open")
    };
    const handleLoginClose = (value) => {
        setOpenLogin(value);
        setDailogName("");
        document.querySelector("body").classList.remove("modal-open")
    };
    const handleClose = () => {
        setOpenLogin(false);
        setDailogName("");
        document.querySelector("body").classList.remove("modal-open")
    }
    useEffect(() => {
        if (token === null){
            history.push("/")
        }
    },[token])

    return(
        <ProfileStyle>
            <Title
                text={"Profile"}
                color={"#32AEB0"}
                fontSize={12}
                lineHeight={18}
                fontWeight={"500"}
                textAlign={"left"}
            />
            <Title
                text={"Manage Your Account"}
                color={"#313132"}
                fontSize={24}
                lineHeight={40}
                fontWeight={"500"}
                textAlign={"left"}
                margin={"0 0 30px 0"}
                textTransform={"uppercase"}
            />
            <div className="container">
                <Row>
                    <Col md={4} sm={6} xs={6}>
                        <div className="tab" onClick={() => setActive(0)}>
                            <Title
                                text={"Account"}
                                color={active === 0 ? "#313132" : "#989898"}
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={"500"}
                                textAlign={"left"}
                                margin={"0 0 10px 0"}
                            />
                            <div className={`divider ${active === 0 ? "active" : ""}`} />
                        </div>
                    </Col>
                    <Col md={4} sm={6} xs={6}>
                        <div className="tab" onClick={() => setActive(1)}>
                            <Title
                                text={"Address"}
                                color={active === 1 ? "#313132" : "#989898"}
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={"500"}
                                textAlign={"left"}
                                margin={"0 0 10px 0"}
                            />
                            <div className={`divider ${active === 1 ? "active" : ""}`} />
                        </div>
                    </Col>
                </Row>
                {active === 0 ? (
                    <UpdateAccount profileData={authData}
                                   token={token}
                                   updateProfile={updateProfile}
                                   moveToRecoverPhone={handleLoginOpen}
                    />
                ) : (
                    <UpdateAddress profileData={authData}/>
                )}
                <Dialog
                    open={openLogin}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {dailogName === "loginForm" && (
                        <LoginForm
                            handleClose={handleLoginClose}
                            moveToSignUp={() => setDailogName("signUpMobile")}
                            moveToRecoverPhone={() => setDailogName("recoverPhone")}
                        />
                    )}
                    {dailogName === "recoverPhone" && (
                        <RecoverPhone
                            handleMove={() => setDailogName("otpInput")}
                            otpFor={() => setOtpFor("resetPassword")}
                        />
                    )}
                    {dailogName === "signUpMobile" && (
                        <SignUpMobile
                            handleMove={() => setDailogName("otpInput")}
                            otpFor={() => setOtpFor("signUpPassword")}
                            moveToLogin={() => setDailogName("loginForm")}
                        />
                    )}

                    {dailogName === "signUpPassword" && (
                        <SignUpPassword handleMove={() => setDailogName("loginForm")} />
                    )}

                    {dailogName === "resetPassword" && (
                        <ResetPasswordForm handleMove={() => setDailogName("loginForm")} />
                    )}

                    {dailogName === "otpInput" && (
                        <OtpVarificationForm
                            handleMove={(e) => setDailogName(e)}
                            otpFor={() => setOtpFor("")}
                            pageName={otpFor}
                        />
                    )}

                    {dailogName === "successVerify" && (
                        <VerifySuccess
                            moveToSignUpPass={() => setDailogName("signUpPassword")}
                        />
                    )}
                </Dialog>
            </div>
        </ProfileStyle>
    )
}

const ProfileStyle = styled.div`
  margin: 100px 0;
  padding: 0 55px;
  height: auto;

  .container {
    margin: 0 55px;
  }

  .tab {
    cursor: pointer;
    width: 270px;
    height: 33px;
  }

  .divider {
    width: 100%;
    border-bottom: 2px solid #989898;
  }

  .active {
    border-bottom: 2px solid #313132 !important;
  }

  .button-field {
    margin-top: 100px;
  }

  .edit-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;

    .tab {
      width: 140px;
      height: 30px;
    }

    .container {
      margin: 0;
    }
  }
`;

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        token: state.authReducer.token
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateProfile: (data,token) => dispatch(updateProfile(data,token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);
