import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  containerBox: {
    borderRadius: "5px",
    border: "1px solid #32aeb1",
    padding: "0 5%",
    width: "100%",
  },
  name: {
    color: "#32aeb1",
  },
  address: {
    color: "#7d94a6",
    fontSize: 14,
    border: "none",
  },
  addressEdit: {
    color: "#7d94a6",
    fontSize: 14,
    border: "1px solid black",
  },
}));

const AddressInput = ({ item, name, value, updateAddressLineOne }) => {
  const classes = useStyles();
  const [editAble, setEditAble] = React.useState(true);
  const [inputStyle, setInputStyle] = React.useState(true);

  const [selectOption, setSelectOption] = React.useState(
    `${item.address_1}`
  );

  const handleChange = (actionType, e) => {
    if (actionType === "radio") {
      updateAddressLineOne(selectOption);
    } else {
      updateAddressLineOne(e.target.value);
      setSelectOption(e.target.value);
    }
  };

  useEffect(() => {
    updateAddressLineOne(`${item.address_1}`);
  }, []);

  return (
    <Grid container style={{ paddingBottom: "3%" }}>
      <Box container className={classes.containerBox}>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-around"
          style={{ padding: "3% 0" }}
        >
          <div>
            <FormControlLabel
              value={value}
              onChange={(e) => handleChange("radio", e)}
              control={<Radio style={{ color: "#32aeb1", margin: "auto" }} />}
            />
          </div>

          <div style={{ paddingLeft: "3px" }}>
            <Typography className={classes.name}>{name}</Typography>

            <TextField
              id="standard-multiline-flexible"
              multiline
              maxRows={4}
              defaultValue={`${item.address_1}`}
              placeholder={'Please enter your address'}
              onChange={(e) => handleChange("editBox", e)}
              InputProps={{
                readOnly: editAble,
                disableUnderline: inputStyle,
                border: "2px solid black",
                classes: {
                  input: classes.address,
                },
              }}
            />
          </div>

          <div>
            <IconButton
              onClick={() => {
                setEditAble(!editAble);
                setInputStyle(!inputStyle);
              }}
            >
              <Typography
                style={{
                  textAlign: "right",
                  fontSize: "10px",
                  color: "#7d94a6",
                  paddingLeft: ".7vw",
                }}
              >
                <EditOutlinedIcon />
              </Typography>
            </IconButton>
          </div>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AddressInput;
