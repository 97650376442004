import {CART_PAGE} from "../../constants/cart";

let initState = {
    cart: [],
    cartLoading: false,
    cartErr: null,
    cus_cart: '',
    cart_update: '',
    cus_cartLoading: false,
    cus_cartErr: null,
    allCart: [],
    allCartLoading: false,
    allCartErr: null,
    wishlist: [],
    wishlistLoading: false,
    wishlistErr: null,
    allWishlist: [],
    allWishlistLoading: false,
    allWishlistErr: null,
    checkout: []
}

export default function cartReducer (state = initState, action) {
    switch (action.type) {

        case CART_PAGE.ADD_ITEM_TO_CART.SUCCESS:
            let cartItem = action.result; // action.result = {whole cart item}

            let found = state.cart.length ? state.cart.find (item => item.cartItem.product_id === cartItem.product_id) : null

            let newCart = []

            if (found) {
                newCart = [...state.cart]
                let idx = state.cart.findIndex (item => item.cartItem.product_id === cartItem.product_id)

                newCart[idx].count += 1
            } else {
                let addCart = {
                    count: 1,
                    cartItem: cartItem
                }

                newCart = [...state.cart]

                newCart.push (addCart)
            }

            return {
                ...state,
                cart: newCart
            }

        case CART_PAGE.REMOVE_ITEM_FROM_CART.SUCCESS:
            let removeItem = action.result; // action.result = { count, id }

            let updated = [...state.cart]

            let isAvailable = updated.length ? updated.find (item => item.cartItem.product_id === removeItem.product_id) : null

            if (isAvailable) {
                let inx = updated.findIndex (item => item.cartItem.product_id === removeItem.product_id)

                updated[inx].count -= removeItem.count

                if (updated[inx].count < 1) {
                    updated = updated.filter (item => item.cartItem.product_id !== removeItem.product_id)
                }
            }

            return {
                ...state,
                cart: updated,
                cart_update: updated.length > 0 ? state.cart_update : '',
                cus_cart: updated.length > 0 ? state.cus_cart : '',
            }

        case CART_PAGE.ADD_ITEM_TO_CART_SERVER.MAIN:
            return {
                ...state,
                cus_cart: '',
                cus_cartLoading: true,
                cus_cartErr: null
            }
        case CART_PAGE.ADD_ITEM_TO_CART_SERVER.SUCCESS:
            return {
                ...state,
                cus_cart: action.result,
                cus_cartLoading: false,
                cus_cartErr: null
            }
        case CART_PAGE.ADD_ITEM_TO_CART_SERVER.FAILURE:
            return {
                ...state,
                cus_cart: '',
                cus_cartLoading: false,
                cus_cartErr: action.result
            }

        case CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.MAIN:
            return {
                ...state,
                cart_update: '',
                cus_cartLoading: true,
                cus_cartErr: null
            }
        case CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.SUCCESS:
            return {
                ...state,
                cart_update: action.result,
                cus_cartLoading: false,
                cus_cartErr: null
            }
        case CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.FAILURE:
            return {
                ...state,
                cart_update: '',
                cus_cartLoading: false,
                cus_cartErr: action.result
            }

        case CART_PAGE.CLEAR_CART.SUCCESS:
            return {
                ...state,
                cart: [],
                cart_update: '',
                cus_cart: '',
            }

        case CART_PAGE.FETCH_ALL_CART_ITEMS.MAIN:
            return {
                ...state,
                allCart: [],
                allCartLoading: true,
                allCartErr: null
            }

        case CART_PAGE.FETCH_ALL_CART_ITEMS.SUCCESS:
            return {
                ...state,
                allCart: action.result,
                allCartLoading: false,
                allCartErr: null
            }

        case CART_PAGE.FETCH_ALL_CART_ITEMS.FAILURE:
            return {
                ...state,
                allCart: [],
                allCartErr: action.result,
                allCartLoading: false
            }

        case CART_PAGE.ADD_ITEM_TO_WISHLIST.SUCCESS:
            let addWishItem = action.result // action.result = {whole item}
            let newList = [...state.wishlist]

            let isWish = newList.find (item => item.product_id === addWishItem.product_id)

            if (!isWish) newList.push (addWishItem)

            return {
                ...state,
                wishlist: newList
            }

        case CART_PAGE.REMOVE_ITEM_FROM_WISHLIST.SUCCESS:
            let removeWishList = action.result // action.result = {whole item}

            let updatedList = state.wishlist.filter (item => item.product_id !== removeWishList.product_id)

            return {
                ...state,
                wishlist: updatedList
            }

        case CART_PAGE.CLEAR_WISHLIST.SUCCESS:
            return {
                ...state,
                wishlist: []
            }

        case CART_PAGE.FETCH_ALL_WISHLIST.MAIN:
            return {
                ...state,
                allWishlist: [],
                allWishlistLoading: true,
                allWishlistErr: null
            }

        case CART_PAGE.FETCH_ALL_WISHLIST.SUCCESS:
            return {
                ...state,
                allWishlist: action.result,
                allWishlistLoading: false,
                allWishlistErr: null
            }

        case CART_PAGE.FETCH_ALL_WISHLIST.FAILURE:
            return {
                ...state,
                allWishlist: [],
                allWishlistLoading: false,
                allWishlistErr: action.result
            }

        case CART_PAGE.SET_CHECKOUT.SUCCESS:
            return {
                ...state,
                checkout: action.result
            }

        case CART_PAGE.CLEAR_CHECKOUT.SUCCESS:
            return {
                ...state,
                checkout: []
            }

        default:
            return state
    }
}
