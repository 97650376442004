module.exports = {
  //BASE_URL: 'https://serene-cray.139-59-108-255.plesk.page/',
  //IMG_BASE: 'https://stupefied-colden.139-59-108-255.plesk.page/image/'

  BASE_URL: 'https://api.netmed24.com/',
  //BASE_URL: 'https://api.e-pharma.com.bd/',
  //IMG_BASE: 'https://managerportal.e-pharma.com.bd/image/',
  IMG_BASE: 'https://backoffice.netmed24.com/image/',
  PRICE_LIMIT: 10
}
