import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";
import cashOnDelivery from "../../../localization/images/cash.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0 15%",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
      color: "#32aeb1",
    },
  },
  containerBox: {
    borderRadius: "5px",
    border: "1px solid #32aeb1",
    padding: "0 5%",
  },
}));

const PaymentOption = ({ updatePaymentOne }) => {
  const classes = useStyles();
  const options = [
    // {
    //   name: "Online Payment",
    //   value: "online-payment",
    //   img: "",
    // },
    {
      name: "Cash on Delivery",
      value: "cash-on-delivery",
      img: cashOnDelivery,
    },
  ];

  const [selectOption, setSelectOption] = React.useState("cash-on-delivery");

  const handleChange = (tar, e) => {
    updatePaymentOne(e.target.value);
    setSelectOption(e.target.value);
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ fontSize: 20, padding: "4% 0" }}>
        Payment Method
      </Typography>

      <Box container className={classes.containerBox}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectOption}
          >
            <Grid container>
              {options.map((item) => (
                <Grid
                  itme
                  xs={12}
                  container
                  direction="row"
                  //   justifyContent="flex-end"
                  alignItems="center"
                  style={{ padding: "3% 0", justifyContent: 'space-between' }}
                >
                  <Grid item xs={10}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        style={{ height: 18, width: 18 }}
                        src={item.img}
                      />
                      <Typography
                        style={{ fontSize: 14, paddingLeft: "3%" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      paddingLeft: "7%",
                      paddingTop: "2%",
                    }}
                  >
                    <FormControlLabel
                      onChange={(e) => handleChange(item.name, e)}
                      value={item.value}
                      control={
                        <Radio
                          value={item.value}
                          style={{ color: "#32aeb1", margin: "auto" }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </div>
  );
};

export default PaymentOption;
