import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography,} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import {ApiParams} from "../../../constants/apiParams"
import AddressInput from "./AdderssInput";
import {allSavedAddress} from "../../../redux/actions/auth";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "0 15%",
        "& .MuiTypography-body1": {
            fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
            //   color: "#32aeb1",
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
    },
    containerBox: {
        borderRadius: "5px",
        border: "1px solid #32aeb1",
        padding: "0 5%",
        width: "100%",
    },
    name: {
        color: "#32aeb1",
    },
    address: {
        color: "#7d94a6",
        fontSize: "1vw",
        border: "none",
    },
}));

const DeliveryAddress = ({updateAddressLineOne, token, addressData, authData, allSavedAddress}) => {
    const classes = useStyles();
    const [editAble, setEditAble] = React.useState(true);

    useEffect(() => {
        let params = {
            [ApiParams.customer_id]: authData ? authData.data ? authData.data.userId : '' : ''
        };
        allSavedAddress(params, authData?.data?.token);
    }, [authData ? authData.data ? authData.data.userId : '' : '']);

    const options = [
        {
            name: "Home",
            value: "home",
            address_1: "",
        },
        {
            name: "Office",
            value: "office",
            address_1: "",
        },
    ];

    let address = addressData ? addressData.data ? addressData.data.length ? addressData.data : options : options : options

    return (
        <div className={classes.paper}>
            <Typography
                style={{fontSize: 20, color: "#32aeb1", padding: "5% 0"}}
            >
                Delivery Address
            </Typography>

            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="home"
                >
                    {
                        address.length === 1 ? (
                            <>
                                <AddressInput
                                    item={address[0]}
                                    name={'Home'}
                                    value={'home'}
                                    updateAddressLineOne={updateAddressLineOne}
                                />
                                <AddressInput
                                    item={address[0]}
                                    name={'Office'}
                                    value={'office'}
                                    updateAddressLineOne={updateAddressLineOne}
                                />
                            </>
                        ) : null
                    }
                    {
                        address.length > 1 ? (
                            <>
                                <AddressInput
                                    item={address[1]}
                                    name={'Home'}
                                    value={'home'}
                                    updateAddressLineOne={updateAddressLineOne}
                                />
                                <AddressInput
                                    item={address[0]}
                                    name={'Office'}
                                    value={'office'}
                                    updateAddressLineOne={updateAddressLineOne}
                                />
                            </>
                        ) : null
                    }

                </RadioGroup>
            </FormControl>

            {/*<Box*/}
            {/*  style={{*/}
            {/*    display: "flex",*/}
            {/*    alignItems: "center",*/}
            {/*    justifyContent: "flex-end",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Button*/}
            {/*    style={{*/}
            {/*      color: "#32aeb1",*/}
            {/*      textTransform: "none",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    +Add Address*/}
            {/*  </Button>*/}
            {/*</Box>*/}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        addressData: state.authReducer.addressData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        allSavedAddress: (params, token) => dispatch(allSavedAddress(params, token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress);
