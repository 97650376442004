import {BLOG} from "../../constants/blog";

export function setBlogList(params) {
    return {
        type: BLOG.BLOG_LIST.MAIN,
        params
    }
}

export function setBlogDetails(params) {
    return {
        type: BLOG.BLOG_LIST_DETAIL.MAIN,
        params
    }
}