import { Grid } from "@material-ui/core";
import React from "react";
import CartList from "./CartList";
import DeliveryAddress from "./DeliveryAddress";
import PaymentSummary from "./PaymentSummary";
import PaymentOption from "./PaymentOption";
import styled from "styled-components";

const Checkout = () => {
  const [payOption1, setPayOption1] = React.useState("cash-on-delivery");
  const [address1, setAddress1] = React.useState();

  const updateAddressLineOne = (value) => {
    setAddress1(value);
  };

  const updatePaymentOne = (value) => {
    setPayOption1(value);
  };

  return (
    <StyledDiv>
      <Grid container style={{ padding: "20px 0" }}>
        <Grid item xs={12} sm={12} md={8} style={{ marginBottom: 15 }}>
          <CartList />
        </Grid>
        <Grid item xs={12} sm={12} md={4} style={{ backgroundColor: "#f9f9f9" }}>
          <DeliveryAddress updateAddressLineOne={updateAddressLineOne} />
          <PaymentOption updatePaymentOne={updatePaymentOne} />
          <PaymentSummary
            addressLine1={address1}
            addressLine2={''}
            paymentOption1={payOption1}
            location={"/checkout"}
          />
        </Grid>
      </Grid>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  .place-holder {
    height: 120px;
    width: 120px;
  }
`

export default Checkout;
