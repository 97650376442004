import {ORDER} from "../../constants/order";

let initState = {
    orderData: null,
    orderDataLoading: false,
    orderDataErr: null,
    fileData: null,
    fileDataLoading: false,
    fileDataErr: null,
    paySuccessData: null,
    paySuccessLoading: false,
    paySuccessErr: null,
    orderList: null,
    orderListLoading: false,
    orderListErr: null
}

export default function oderReducer(state = initState, action) {
    switch (action.type) {
        case ORDER.PLACE_ORDER.MAIN:
            return {
                ...state,
                orderData: null,
                orderDataLoading: true,
                orderDataErr: null
            }

        case ORDER.PLACE_ORDER.SUCCESS:
            return {
                ...state,
                orderData: action.result,
                orderDataLoading: false,
                orderDataErr: null
            }

        case ORDER.PLACE_ORDER.FAILURE:
            return {
                ...state,
                orderData: null,
                orderDataLoading: false,
                orderDataErr: action.result
            }

        case ORDER.PLACE_ORDER_CLEAR.SUCCESS:
            return {
                ...state,
                orderData: null,
                orderDataLoading: true,
                orderDataErr: null,
                fileData: null,
                fileDataLoading: false,
                fileDataErr: null,
                paySuccessData: null,
                paySuccessLoading: false,
                paySuccessErr: null
            }
        case ORDER.ORDER_CLEAR.SUCCESS:
            return {
                ...state,
                orderData: null,
                orderDataLoading: true,
                orderDataErr: null,
                fileData: null,
                fileDataLoading: false,
                fileDataErr: null,
                paySuccessData: null,
                paySuccessLoading: false,
                paySuccessErr: null,
                orderList: null,
                orderListLoading: false,
                orderListErr: null
            }
        case ORDER.UPLOAD_FILE_SET.MAIN:
            return {
                ...state,
                fileData: null,
                fileDataLoading: true,
                fileDataErr: null
            }

        case ORDER.UPLOAD_FILE_SET.SUCCESS:
            return {
                ...state,
                fileData: action.result,
                fileDataLoading: false,
                fileDataErr: null
            }

        case ORDER.UPLOAD_FILE_SET.FAILURE:
            return {
                ...state,
                fileData: null,
                fileDataLoading: false,
                fileDataErr: action.result
            }
        case ORDER.ONLINE_PAYMENT_OK.MAIN:
            return {
                ...state,
                paySuccessData: null,
                paySuccessLoading: false,
                paySuccessErr: null
            }

        case ORDER.ONLINE_PAYMENT_OK.SUCCESS:
            return {
                ...state,
                paySuccessData: action.result,
                paySuccessLoading: false,
                paySuccessErr: null
            }

        case ORDER.ONLINE_PAYMENT_OK.FAILURE:
            return {
                ...state,
                paySuccessData: null,
                paySuccessLoading: false,
                paySuccessErr: action.result
            }
        case ORDER.ORDER_LIST_OK.MAIN:
            return {
                ...state,
                orderList: null,
                orderListLoading: false,
                orderListErr: null
            }

        case ORDER.ORDER_LIST_OK.SUCCESS:
            return {
                ...state,
                orderList: action.result,
                orderListLoading: false,
                orderListErr: null
            }

        case ORDER.ORDER_LIST_OK.FAILURE:
            return {
                ...state,
                orderList: null,
                orderListLoading: false,
                orderListErr: action.result
            }
        default:
            return state
    }
}
