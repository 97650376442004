import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    // textAlign: "center",
    padding: "15px 20px",
    // fontWeight: 600,
    // paddingBottom: "7.3vw",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  boxItem: {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textStyle: {
    fontSize: 14,
    color: "#7b7b7b",
    margin: "5px 0",
    "&:hover":{
      color: "#32aeb1",
    }
  },
}));

const Pharmacutical = ({finalManufact, setManufacture}) => {
  const classes = useStyles();
  const [categories, setCategories] = React.useState(finalManufact);

  const handleChange = (e) => {
    let cat = [...categories];

    cat.map((item) => {
      if (item.name === e.target.name) {
        item.checked = !item.checked;
        item.checked ? (item.color = "#d71512") : (item.color = "#7b7b7b");
      }
    });
    setCategories(cat);
  };

  let handleFilterManuf = (id) =>{
    setManufacture(id)

  }

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: 16, color: "#1b1d1e", fontWeight: "500" }}
      >
        PHARMACUTICAL
      </Typography>

      {categories.map((item) => (
        <Box className={classes.boxItem} onClick={()=>handleFilterManuf(item.id)}>
          <Typography
            className={classes.textStyle}
          >
            {/*<Checkbox*/}
            {/*  icon={<RadioButtonUnchecked />}*/}
            {/*  checkedIcon={<CheckCircle style={{ color: item.color }} />}*/}
            {/*  checked={item.checked}*/}
            {/*  onChange={handleChange}*/}
            {/*  // inputProps={{ "aria-label": "primary checkbox" }}*/}
            {/*  name={item.name}*/}
            {/*/>*/}
            {/*&nbsp;*/}
            {item.name}
          </Typography>
          {/*<Typography className={classes.textStyle}>({item.qty})</Typography>*/}
        </Box>
      ))}
    </div>
  );
};

export default Pharmacutical;
