export const ApiServices = {
  SEND_OTP: "api/send-otp",
  CODE_VERIFY: "api/verify-otp",
  USER_SIGN_UP: "api/register",
  USER_LOGIN: "api/login",

  FORGET_OTP: "api/forget-otp",
  FORGET_VERIFY: "api/forget-password-change",

  PASSWORD_REQ: "api/password-change",

  FETCH_CATEGORIES: "api/category-list",
  FETCH_CHILD_CATEGORIES: "api/child-category-list",
  FEATURE_PRODUCT: "api/category-details",
  PRODUCT_DETAIL: "api/product-details",
  ADD_TO_CART: "api/add-to-cart",
  UPDATE_TO_CART: "api/update-cart",
  DELETE_TO_CART: "api/delete-cart-item",
  CHECKOUT_ORDER: "api/order-create",
  ORDER_LIST: "api/order-list",
  FEATURE_BRAND: "api/manufacture-products",
  UPLOAD_FILE: "api/prescription-upload",
  PAYMENT_SUCCESS: "api/create-payment",

  SAVE_ADDRESS: "api/all-save-address",
  UPDATE_ADDRESS: "api/update-address",
  UPDATE_PROFILE: "api/update-profile",
  DELETE_ADDRESS: "api/delete-address",
  SEARCH_PRODUCT: "api/search-product",
  RELATED_PRODUCT: "api/related-product",
  FILTER_PRODUCT: "api/filter-product",
  BLOG_POST_LIST: "api/post-list",
  BLOG_POST_DETAILS: "api/post-details",
};
