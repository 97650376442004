import React from "react";
import Logo from "../../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ThankLogo from "../../../localization/images/thanks.jpg";
import { clearCheckout } from "../../../redux/actions/cart";
import { clearOrder } from "../../../redux/actions/order";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
    padding: "3px 30px 10px 30px",
  },
  smallTextRed: {
    fontSize: "12px",
    color: "#ff0000",
    padding: "3px 30px 10px 30px",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  input: {
    fontSize: "12px",
    "&:invalid": {
      border: "red solid 2px",
    },
  },
}));

const ThankYou = ({ handleClose, clearCheckout, orderData, clearOrder }) => {
  const classes = useStyles();
  const history = useHistory();

  let orderID = orderData
    ? orderData.data
      ? orderData.data.orderData
        ? orderData.data.orderData.order_id
        : ""
      : ""
    : "";
  let invoice = orderData
    ? orderData.data
      ? orderData.data.orderData
        ? orderData.data.orderData.invoice_no
        : ""
      : ""
    : "";
  let invoicePrefix = orderData
    ? orderData.data
      ? orderData.data.orderData
        ? orderData.data.orderData.invoice_prefix
        : ""
      : ""
    : "";
  let paymentMethod = orderData
    ? orderData.data
      ? orderData.data.orderData
        ? orderData.data.orderData.payment_method
        : ""
      : ""
    : "";

  const handleAction = (event) => {
    event.preventDefault();
    clearCheckout();
    handleClose();
    clearOrder();
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div style={{ padding: "5px 0 10px 0" }}>
          <center>
            <img src={ThankLogo} className={classes.bigImg} />
          </center>
        </div>

        <center>
          <Typography className={classes.bigText}>Thank You</Typography>
          {paymentMethod === "online-payment" ? (
            <Typography className={classes.smallTextRed}>
              Your Order is placed with invoice #{invoice}. After prescription
              verification please complete your online payment from order list.
            </Typography>
          ) : (
            <Typography className={classes.smallText}>
              Your Order will be delivered with invoice #{invoice}. You can
              track the delivery in the order section.
            </Typography>
          )}
        </center>

        <div className={classes.lButton}>
          <center style={{ paddingTop: "20px" }}>
            <Button
              className={classes.orderBtn}
              style={{ backgroundColor: "#32aeb1", color: "#fff" }}
              onClick={handleAction}
            >
              Continue Shopping
            </Button>
          </center>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    checkout: state.cartReducer.checkout,
    orderData: state.oderReducer.orderData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCheckout: () => dispatch(clearCheckout()),
    clearOrder: () => dispatch(clearOrder()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
