import {CART_PAGE} from "../../constants/cart";

export function addItemToCart(data) {
    return {
        type: CART_PAGE.ADD_ITEM_TO_CART.MAIN,
        data
    }
}

export function addItemToCartServer(data, token) {
    return {
        type: CART_PAGE.ADD_ITEM_TO_CART_SERVER.MAIN,
        data,
        token
    }
}

export function updateItemToCartServer(data, token) {
    return {
        type: CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.MAIN,
        data,
        token
    }
}

export function removeItemFromCart(data) {
    return {
        type: CART_PAGE.REMOVE_ITEM_FROM_CART.MAIN,
        data
    }
}

export function clearCart() {
    return {
        type: CART_PAGE.CLEAR_CART.MAIN
    }
}

export function addItemToWishlist(data) {
    return {
        type: CART_PAGE.ADD_ITEM_TO_WISHLIST.MAIN,
        data
    }
}

export function removeItemFromWishlist(data) {
    return {
        type: CART_PAGE.REMOVE_ITEM_FROM_WISHLIST.MAIN,
        data
    }
}

export function clearWishlist() {
    return {
        type: CART_PAGE.CLEAR_WISHLIST.MAIN
    }
}

export function setCheckout(data) {
    return {
        type: CART_PAGE.SET_CHECKOUT.MAIN,
        data
    }
}

export function clearCheckout() {
    return {
        type: CART_PAGE.CLEAR_CHECKOUT.MAIN
    }
}

