import React, { useEffect, useState } from "react";
import Logo from "../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import OTPInput from "otp-input-react";
import VerificationExpireCountdown from "./VerificationExpireCountdown";
import { forgetReq, logout, verifyOTP } from "../../redux/actions/auth";
import { connect } from "react-redux";
import querystring from "querystring";
import { useAutocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
    paddingBottom: "20px",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  input: {
    fontSize: "12px",
    "&:invalid": {
      border: "red solid 2px",
    },
  },
}));

const OtpVarificationForm = ({
  handleMove,
  otpFor,
  pageName,
  verifyOTP,
  otpData,
  codeVerify,
  codeVerifyLoading,
  codeVerifyErr,
  logout,
  forgetReq,
  passwordReq,
  passwordReqLoading,
  passwordReqErr,
}) => {
  const classes = useStyles();
  const history = useHistory();

  //Otp box mode
  let [isDisable, setIsDisable] = useState(true);
  let [vCode, setVCode] = useState("");
  let [otpErr, setOtpErr] = useState("");

  const [number, setNumber] = useState("");
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [errorTxt, setErrorTxt] = useState(null);

  //console.log("otp: " + vCode);

  const change = (name, e) => {
    e.persist();
    if (name === "number") {
      setNumber(e.target.value);
    }
    if (name === "password") {
      setPassword(e.target.value);
    }
    if (name === "password_confirmation") {
      setPasswordConfirm(e.target.value);

      if (password !== e.target.value) {
        setErrorTxt("Passwords do not match");
      } else {
        setErrorTxt(null);
      }
    }
  };

  const handleAction = (event) => {
    event.preventDefault();
    if (pageName === "resetPassword") {
      if (vCode.length === 4) {
        if (password === passwordConfirm) {
          if (otpData) {
            let data = querystring.stringify({
              client_phone_number: number ? number : otpData?.data?.telephone,
              req_otp: vCode,
              new_password: password,
            });
            forgetReq(data);
          } else {
            setOtpErr("OTP session expired");
          }
        } else {
          setErrorTxt("Passwords do not match");
        }
      } else {
        setOtpErr("Please enter valid OTP");
      }
    } else {
      if (vCode.length === 4) {
        let data = querystring.stringify({
          client_phone_number: number ? number : otpData?.data?.mobile,
          verify_code: vCode,
        });
        verifyOTP(data);
      } else {
        setOtpErr("Please enter valid OTP");
      }
    }
  };

  const handleActionResend = (event) => {
    event.preventDefault();
    if (pageName === "signUpPassword") {
      handleMove("signUpMobile");
      setVCode("");
    }
    if (pageName === "resetPassword") {
      handleMove("recoverPhone");
      setVCode("");
    }
    logout();
  };

  useEffect(() => {
    if (passwordReq) {
      handleMove("loginForm");
      logout();
      setVCode("");
    }
    if (passwordReqErr) {
      setOtpErr(passwordReqErr.message);
      setVCode("");
    }
  }, [passwordReq, passwordReqErr]);

  useEffect(() => {
    if (codeVerify) {
      if (codeVerify.data) {
        // check pageName to go on which page (signUp or resetPassword)
        //console.log("n p: " + pageName);
        handleMove(pageName);
        otpFor();
        setVCode("");
      } else {
        setOtpErr(codeVerify.message);
      }
    }
    if (codeVerifyErr) {
      setOtpErr(codeVerifyErr.message);
    }
  }, [codeVerify, codeVerifyErr]);

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div>
          <Typography className={classes.bigText}>
            Enter the verify code
          </Typography>
          <Typography className={classes.smallText}>
            We just send you a verification code via phone
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <OTPInput
            value={vCode}
            onChange={setVCode}
            autoFocus={false}
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure
            inputStyles={{
              width: 30,
              height: 30,
              fontSize: 18,
              marginRight: 10,
              border: 0,
              outline: "none !important",
              borderBottom: "1px solid black",
            }}
          />
        </div>

        {pageName === "resetPassword" ? (
          <div style={{ marginTop: 24 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Number"
                  onChange={change.bind(null, "number")}
                  label="Phone Number"
                  type="number"
                  id="number"
                  defaultValue={otpData?.data?.telephone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  onChange={change.bind(null, "password")}
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password_confirmation"
                  onChange={change.bind(null, "password_confirmation")}
                  label="Confirm Password"
                  type="password"
                  id="password_confirmation"
                  autoComplete="current-password_confirmation"
                  error={errorTxt}
                  helperText={errorTxt}
                />
              </Grid>
            </Grid>
            {passwordReq ? (
              passwordReq.message.length ? (
                <div
                  style={{
                    color: "#066714",
                    fontFamily: "Poppins",
                    fontSize: 12,
                    marginTop: 20,
                  }}
                >
                  {passwordReq.message}
                </div>
              ) : null
            ) : null}
          </div>
        ) : null}

        {otpErr ? (
          otpErr.length ? (
            <div
              style={{
                color: "#ff0000",
                fontFamily: "Poppins",
                fontSize: 12,
                marginTop: 20,
              }}
            >
              {otpErr}
            </div>
          ) : null
        ) : null}

        <div className={classes.lButton}>
          <center style={{ paddingTop: "20px" }}>
            <Button
              // type="submit"
              //disabled={isDisable}
              className={classes.orderBtn}
              style={{ backgroundColor: "#32aeb1", color: "#fff" }}
              onClick={handleAction}
            >
              SUBMIT CODE
            </Button>
          </center>
          {/*<center>*/}
          {/*  <Typography*/}
          {/*    className={classes.smallText}*/}
          {/*    style={{*/}
          {/*      display: "flex",*/}
          {/*      flexDirection: "row",*/}
          {/*      justifyContent: "center",*/}
          {/*      marginTop: "10px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    The verify code will expire in &nbsp;{" "}*/}
          {/*    <VerificationExpireCountdown />{" "}*/}
          {/*  </Typography>*/}
          {/*</center>*/}

          <center
            style={{
              paddingBottom: "7vw",
            }}
          >
            <Link onClick={handleActionResend}>
              <Typography
                style={{
                  color: "#32aeb1",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Resend Code
              </Typography>
            </Link>
          </center>
        </div>

        {codeVerifyLoading === true ? (
          <center>
            <div style={{ marginTop: 20 }}>
              <LinearProgress />
            </div>
          </center>
        ) : null}

        {passwordReqLoading === true ? (
          <center>
            <div style={{ marginTop: 20 }}>
              <LinearProgress />
            </div>
          </center>
        ) : null}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    otpData: state.authReducer.otpData,
    codeVerify: state.authReducer.codeVerify,
    codeVerifyLoading: state.authReducer.codeVerifyLoading,
    codeVerifyErr: state.authReducer.codeVerifyErr,
    passwordReq: state.authReducer.passwordReq,
    passwordReqLoading: state.authReducer.passwordReqLoading,
    passwordReqErr: state.authReducer.passwordReqErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyOTP: (data) => dispatch(verifyOTP(data)),
    forgetReq: (data) => dispatch(forgetReq(data)),
    logout: () => dispatch(logout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpVarificationForm);
