import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {Card, ListItem} from "@material-ui/core";
import styled from "styled-components";
import Title from "../../../components/Title";
import OrderDetailsMobile from "./OrderDetailsMobile";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import moment from "moment";

const MobileOrderHistory = props => {
    const classes = useStylesCard();
    let {orders, active, setActive, statusCheck, statusCheckOnline, onPrint } = props
    let handleActive = id => {
        setActive(id)
    }
    return (
        <StyledList>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <List className="list" component="nav" aria-label="main mailbox folders">
                        {orders ? orders.length ? orders.map((item, key) => {
                            let status = ''
                            if (item.payment_method === 'cash-on-delivery') {
                                status = statusCheck(item.order_status_id)
                            } else {
                                status = statusCheckOnline(item.order_status_id)
                            }
                            return (
                                <Accordion>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <ListItem
                                            button
                                            onClick={() => handleActive(key)}
                                            className="list-text"
                                        >
                                            <Title
                                                text={`Order ID#${item.order_id}`}
                                                color={'#313132'}
                                                fontSize={16}
                                                lineHeight={24}
                                                fontFamily={'Poppins'}
                                                fontWeight={'500'}
                                                textAlign={'left'}
                                            />
                                            <Title
                                                text={status.name}
                                                color={'#313132'}
                                                fontSize={10}
                                                lineHeight={14}
                                                fontFamily={'Poppins'}
                                                fontWeight={'400'}
                                                textAlign={'left'}
                                            />
                                            {/*<Title*/}
                                            {/*    text={'Date: ' + moment(item.date_modified).format('DD-MMM-YYYY')}*/}
                                            {/*    fontSize={14}*/}
                                            {/*    lineHeight={24}*/}
                                            {/*    fontWeight={'400'}*/}
                                            {/*    color={'#313132'}*/}
                                            {/*    margin={'0 0 10px 0'}*/}
                                            {/*/>*/}
                                        </ListItem>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <OrderDetailsMobile
                                            active={orders[active]}
                                            statusCheck={statusCheck}
                                            statusCheckOnline={statusCheckOnline}
                                            onPrint={onPrint}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) : null : null}
                    </List>
                </CardContent>
            </Card>
        </StyledList>
    );
};

let useStylesCard = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        marginBottom: 30,
        boxShadow: "0px 0px 10px #0000000D",
    },
    cardContent: {
        padding: "0px",
    }
}));

let StyledList = styled.div`
  .MuiCardContent-root:last-child{
    padding: 0px !important;
  }
  .MuiList-padding {
    padding: 0px !important;
  }
  .list-text {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 32px;
    padding-top: 42px;
  }
  .list {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .list {
      max-width: 100%;
    }
  }
`

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .03)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0, 0, 0, .03)",
        marginBottom: -1,
        minHeight: 40,
        "&$expanded": {
            minHeight: 40,
            borderBottom: 0,
            backgroundColor: "#EBEBEB"
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
            borderBottom: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default MobileOrderHistory;
