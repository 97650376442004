import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {Divider, StepConnector} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

import Title from '../../../components/Title';
import styled from "styled-components";
import ReactToPrint from "react-to-print";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    cardLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    customLabel: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
        borderBottom: '0.1px solid #F5F5F5',
        borderBottomWidth: 'thin'
    },
    dateTime: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    }
}));

const QontoConnector = withStyles({
    alternativeLabel: {
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#9C9434',
        },
    },
    vertical: {
        padding: 0,
        marginLeft: 7,
    },
    line: {
        borderTopWidth: 3,
        borderRadius: 1,
    },
    lineVertical: {
        minHeight: 30,
        borderLeftStyle: 'solid',
        borderLeftWidth: 1
    }
})(StepConnector);

function getCODSteps() {
    let orderStatusList = [
        {label: 'Order Pending', id: 1},
        {label: 'Order Processing', id: 2},
        {label: 'Order Picked Up For Delivery', id: 3},
        {label: 'Payment Complete', id: 4},
        {label: 'Delivered', id: 5}
    ]

    return orderStatusList;
}

function getSteps() {
    let orderStatusList = [
        {label: 'Order Pending', id: 1},
        {label: 'Order Processing', id: 2},
        {label: 'Payment Complete', id: 3},
        {label: 'Order Picked Up For Delivery', id: 4},
        {label: 'Delivered', id: 5}
    ]

    return orderStatusList;
}


function getCancelSteps() {
    let orderStatusList = [
        {label: 'Order Pending', id: 1},
        {label: 'Canceled', id: 2}
    ]

    return orderStatusList;
}

let StepperComponent = (props) => {
    const classes = useStyles();
    let {activeOrder, statusCheck, statusCheckOnline, onPrint} = props;

    let activeStatus = ''
    if (activeOrder.payment_method === 'cash-on-delivery'){
        activeStatus = statusCheck(activeOrder.order_status_id)
    } else {
        activeStatus = statusCheckOnline(activeOrder.order_status_id)
    }

    let steps = [];
    if (activeOrder.payment_method === 'cash-on-delivery'){
        if (activeStatus.id == 5) {
            steps = getCancelSteps()
        } else {
            steps = getCODSteps()
        }
    } else {
        if (activeStatus.id == 5) {
            steps = getCancelSteps()
        } else {
            steps = getSteps()
        }
    }


    return (
        <StyledStatus className={classes.root}>
            <>
                {
                    activeStatus.name === "Delivered" ?
                        <>
                            <div className={classes.cardLabel}>
                                <Title
                                    text={'Invoice: '+ activeOrder?.invoice_prefix+activeOrder?.invoice_no}
                                    fontSize={16}
                                    lineHeight={24}
                                    margin={'0 0 20px 0'}
                                />

                                <Title
                                    text={'Order ID#'+ activeOrder?.order_id}
                                    fontSize={16}
                                    lineHeight={24}
                                    margin={'0 0 20px 0'}
                                />
                                {/*<ReactToPrint*/}
                                {/*    trigger={() => (*/}
                                {/*        <div style={{cursor: 'pointer'}}>*/}
                                {/*            <Title*/}
                                {/*                text={"Print"}*/}
                                {/*                color={'#1241ca'}*/}
                                {/*                fontSize={16}*/}
                                {/*                lineHeight={24}*/}
                                {/*                fontWeight={'500'}*/}
                                {/*                margin={'0 0 20px 0'}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*    content={() => onPrint.current}*/}
                                {/*/>*/}

                            </div>
                        </> :
                        <>
                            <div className={classes.cardLabel}>
                                <Title
                                    text={'Order Status'}
                                    fontSize={16}
                                    lineHeight={24}
                                    margin={'0 0 20px 0'}
                                />
                                <Title
                                    text={activeStatus.name}
                                    fontSize={14}
                                    lineHeight={20}
                                    fontWeight={'400'}
                                    margin={'0 0 20px 0'}
                                />
                            </div>
                            <Divider />
                            <Stepper
                                activeStep={activeStatus.id + 1}
                                color='secondary'
                                orientation="vertical"
                                connector={<QontoConnector />}
                            >
                                {steps.map((step, index) => (
                                    <Step key={step.id}>
                                        <StepLabel>
                                            <div className={classes.customLabel}>
                                                <Title
                                                    text={step.label}
                                                    fontSize={12}
                                                    fontWeight={'bold'}
                                                    lineHeight={20}
                                                />
                                            </div>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                }
            </>
        </StyledStatus>
    );
}

let StyledStatus = styled.div`
  @media(max-width: 1280px){
    .title {
      font-size: 10px;
    }
  }
`

export default StepperComponent;
