import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HomeIcon from "@material-ui/icons/Home";
import bkash from "../../../localization/images/bkash.png";
import nogod from "../../../localization/images/nogod.png";
import rocket from "../../../localization/images/rocket.png";
import visa from "../../../localization/images/visa.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    padding: "10% 15%",

    "& .MuiTypography-body1": {
      fontSize: 14,
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  title: {
    color: "#DF1A33",
  },
  divider: {
    background: "#DF1A33",
    height: "3px",
    margin: "5px 0",
  },
  section: {
    paddingBottom: "20px",
  },
}));

const DeliveryOption = () => {
  const classes = useStyles();
  const images = [bkash, nogod, rocket, visa];
  return (
    <div className={classes.paper}>
      <div className={classes.section}>
        <Typography className={classes.title}>
          Delivery Options <InfoOutlinedIcon />
        </Typography>
        {/* <Typography style={{ color: "#1b1d1e" }}>
          Dhaka, Dhaka North, Bonani Road No. 12
        </Typography>
        <Typography style={{ textAlign: "right", color: "#32aeb1" }}>
          Change
        </Typography> */}

        <Divider classes={{ root: classes.divider }} />
      </div>

      <div className={classes.section}>
        <Typography className={classes.title}>
          <span style={{ color: "#32aeb1" }}>
            <HomeIcon />
          </span>{" "}
          Home Delivery
        </Typography>
        <Grid container>
          <Grid item xs={7}>
            <Typography style={{ color: "#1b1d1e" }}>1-2 Days</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ textAlign: "right", color: "#1b1d1e" }}>
              {/* $250 */}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.section}>
        <Typography className={classes.title}>
          <span style={{ color: "#32aeb1" }}>
            <HomeIcon />
          </span>{" "}
          Cash on Delivery
        </Typography>
        <Typography style={{ color: "#1b1d1e" }}>Available</Typography>

        {/*<Grid container spacing={2}>*/}
        {/*  {images.map((image) => (*/}
        {/*    <Grid item xs={6}>*/}
        {/*      <img style={{ width: "100%" }} src={image} />*/}
        {/*    </Grid>*/}
        {/*  ))}*/}
        {/*</Grid>*/}
      </div>

      <Divider classes={{ root: classes.divider }} />
    </div>
  );
};

export default DeliveryOption;
