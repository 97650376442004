import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosServices';
import {ApiServices} from '../../../network/ApiServices';
import {CART_PAGE} from "../../constants/cart";

function* addItemToCart(actions) {
    yield put({
        type: CART_PAGE.ADD_ITEM_TO_CART.SUCCESS,
        result: actions.data
    })
}

function* addItemToCartServer(actions) {
    try {
        let result = yield call(
            AxiosServices.postForm,
            ApiServices.ADD_TO_CART,
            actions.data, actions.token
        )
        yield put({
            type: CART_PAGE.ADD_ITEM_TO_CART_SERVER.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: CART_PAGE.ADD_ITEM_TO_CART_SERVER.FAILURE,
            result: err.response.data
        })
    }

}

function* updateItemToCartServer(actions) {
    try {
        let result = yield call(
            AxiosServices.putEncoded,
            ApiServices.UPDATE_TO_CART,
            actions.data, actions.token
        )
        yield put({
            type: CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.FAILURE,
            result: err.response.data
        })
    }

}

function* removeItemFromCart(actions) {
    yield put({
        type: CART_PAGE.REMOVE_ITEM_FROM_CART.SUCCESS,
        result: actions.data
    })
}

function* clearCart() {
    yield put({
        type: CART_PAGE.CLEAR_CART.SUCCESS
    })
}

function* addItemToWishList(actions) {
    yield put({
        type: CART_PAGE.ADD_ITEM_TO_WISHLIST.SUCCESS,
        result: actions.data
    })
}

function* removeItemFromWishList(actions) {
    yield put({
        type: CART_PAGE.REMOVE_ITEM_FROM_WISHLIST.SUCCESS,
        result: actions.data
    })
}

function* clearWishlist() {
    yield put({
        type: CART_PAGE.CLEAR_WISHLIST.SUCCESS
    })
}

function* setCheckout(actions) {
    yield put({
        type: CART_PAGE.SET_CHECKOUT.SUCCESS,
        result: actions.data
    })
}

function* clearCheckout() {
    yield put({
        type: CART_PAGE.CLEAR_CHECKOUT.SUCCESS
    })
}

export default function* cartSagas() {
    yield takeLatest(CART_PAGE.ADD_ITEM_TO_CART.MAIN, addItemToCart)
    yield takeLatest(CART_PAGE.ADD_ITEM_TO_CART_SERVER.MAIN, addItemToCartServer)
    yield takeLatest(CART_PAGE.UPDATE_ITEM_TO_CART_SERVER.MAIN, updateItemToCartServer)
    yield takeLatest(CART_PAGE.REMOVE_ITEM_FROM_CART.MAIN, removeItemFromCart)
    yield takeLatest(CART_PAGE.CLEAR_CART.MAIN, clearCart)
    yield takeLatest(CART_PAGE.ADD_ITEM_TO_WISHLIST.MAIN, addItemToWishList)
    yield takeLatest(CART_PAGE.REMOVE_ITEM_FROM_WISHLIST.MAIN, removeItemFromWishList)
    yield takeLatest(CART_PAGE.CLEAR_WISHLIST.MAIN, clearWishlist)
    yield takeLatest(CART_PAGE.SET_CHECKOUT.MAIN, setCheckout)
    yield takeLatest(CART_PAGE.CLEAR_CHECKOUT.MAIN, clearCheckout)
}
