import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Countdown from "react-countdown";
import { Box, Grid, Typography } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
      color: "#23232c",
    },
  },
}));

// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
  return (
    <div>
      {minutes}:{seconds}
    </div>
  );
};

const VerificationExpireCountdown = () => {
  const classes = useStyles();

  return <Countdown date={Date.now() + 5000} renderer={renderer} />;
};

export default VerificationExpireCountdown;
