export const ORDER = {
    PLACE_ORDER: {
        MAIN: 'PLACE_ORDER',
        SUCCESS: 'PLACE_ORDER_SUCCESS',
        FAILURE: 'PLACE_ORDER_FAILURE'
    },
    PLACE_ORDER_CLEAR: {
        MAIN: 'PLACE_ORDER_CLEAR',
        SUCCESS: 'PLACE_ORDER_CLEAR_SUCCESS',
        FAILURE: 'PLACE_ORDER_CLEAR_FAILURE'
    },
    ORDER_CLEAR: {
        MAIN: 'ORDER_CLEAR',
        SUCCESS: 'ORDER_CLEAR_SUCCESS',
        FAILURE: 'ORDER_CLEAR_FAILURE'
    },
    UPLOAD_FILE_SET: {
        MAIN: 'UPLOAD_FILE_SET',
        SUCCESS: 'UPLOAD_FILE_SET_SUCCESS',
        FAILURE: 'UPLOAD_FILE_SET_FAILURE'
    },
    ONLINE_PAYMENT_OK: {
        MAIN: 'ONLINE_PAYMENT_OK',
        SUCCESS: 'ONLINE_PAYMENT_OK_SUCCESS',
        FAILURE: 'ONLINE_PAYMENT_OK_FAILURE'
    },
    ORDER_LIST_OK: {
        MAIN: 'ORDER_LIST_OK',
        SUCCESS: 'ORDER_LIST_OK_SUCCESS',
        FAILURE: 'ORDER_LIST_OK_FAILURE'
    }
}
