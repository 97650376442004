import {CircularProgress, Grid} from "@material-ui/core";
import React, { useEffect } from "react";
import DeliveryOption from "./components/DeliveryOption";
import Description from "./components/Description";
import Favourites from "./components/Favourites";
import ProductImages from "./components/ProductImages";
import ProductInfo from "./components/ProductInfo";
import TopRatedProducts from "./components/TopRatedProducts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProductDetailsData } from "../../redux/actions/details";
import { ApiParams } from "../../constants/apiParams";
import styled from "styled-components";
import MyProgressBar from "../../components/MyProgressBar";

const Index = (props) => {
  let { id } = props.match.params;
  let {productDetailsLoading} = props

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      [ApiParams.product_id]: id,
    };
    props.getFetchProductDetailData(params);
  }, [props.match.params]);

  let item = props.productDetails ? props.productDetails.data : "";
  let ProductDescriptions = item
    ? item.ProductDescriptions
      ? item.ProductDescriptions
      : []
    : [];
  let ProductManufacturer = item
    ? item.ProductManufacturer
      ? item.ProductManufacturer
      : []
    : [];
  let ProductAttributes = item
    ? item.ProductAttributes
      ? item.ProductAttributes
      : []
    : [];
  let pGroup = ProductAttributes.find((p) => p.attribute_id === 4);

  let description = ProductDescriptions
    ? ProductDescriptions
    : "";

  let Images = item
    ? item.ProductImages
      ? item.ProductImages.length
        ? item.ProductImages
        : []
      : []
    : [];
  let image = {
    product_image_id: 1750670290,
    product_id: item.product_id,
    image: item.image,
    sort_order: 0,
  };
  Images.push(image);

  let isPharma = item.is_pharma_product === 1 ? 'pharma' : 'noPharma'

  return (
    <Main>
      {
        productDetailsLoading === true ? (
            <div className="loader">
              <MyProgressBar/>
            </div>
        ) : (
            <Grid container>
              <Grid item sm={12} md={8} style={{ paddingRight: "1.5%" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container style={{ padding: "3% 6%" }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <ProductImages data={Images} isPharma={isPharma} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <ProductInfo
                            description={description}
                            ProductManufacturer={ProductManufacturer}
                            ProductAttributes={ProductAttributes}
                            data={item}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Description description={description} />
                    {/* <Favourites /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                  item
                  sm={12}
                  md={4}
                  style={{ backgroundColor: "#F9F9F9", padding: "0 auto" }}
              >
                <DeliveryOption />
                <TopRatedProducts group_name={pGroup?.text} height={'130px'}/>
              </Grid>
            </Grid>
        )
      }
    </Main>
  );
};

let Main = styled.div`
  .loader {
    height: 100vh;
    text-align: center;
  }
`;

function mapStateToProps(state) {
  return {
    productDetails: state.detailsReducer.productDetails,
    productDetailsLoading: state.detailsReducer.productDetailsLoading,
    cart: state.cartReducer.cart,
    wishlist: state.cartReducer.wishlist,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchProductDetailData: (params) =>
      dispatch(fetchProductDetailsData(params)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
