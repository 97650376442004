import React, { useEffect, useState } from "react";
import Logo from "../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { logout, sendOTP } from "../../redux/actions/auth";
import querystring from "querystring";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
    paddingBottom: "20px",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  input: {
    fontSize: "12px",
    // "&:invalid": {
    //   border: "red solid 2px",
    // },
  },
}));

let isNum = (char) => {
  if (char >= "0" && char <= "9") {
    return true;
  } else return false;
};

const validatePhone = (phone) => {
  if (phone.length) {
    for (let i = 0; i < phone.length; i++) {
      if (isNum(phone[i]) === false) return false;
    }
    if (phone[0] === "0") {
      if (phone[1] === "1") {
        if (phone.length === 11) return true;
        else return false;
      } else return false;
    } else return false;
  } else return false;
};

const SignUpMobile = ({
  handleMove,
  otpFor,
  moveToLogin,
  sendOTP,
  otpData,
  otpDataLoading,
  otpDataErr,
  logout,
}) => {
  const classes = useStyles();
  const history = useHistory();
  let [phone, setPhone] = useState("");
  let [phoneErr, setPhoneErr] = useState("");
  let [authError, setAuthError] = useState("");

  const moveTo = () => {
    moveToLogin();
    logout();
  };

  const handleAction = (event) => {
    event.preventDefault();
    if (phone.length) {
      if (!phoneErr.length) {
        let data = querystring.stringify({
          client_phone_number: phone,
        });
        sendOTP(data);
        otpFor(); // set otp for signUp
      }
    } else {
      setPhoneErr("please enter a phone number");
    }
  };

  useEffect(() => {
    if (otpData) {
      if (otpData.data) {
        handleMove("otpInput");
      } else {
        setAuthError(otpData.message);
      }
    }
    if (otpDataErr) {
      setAuthError(otpDataErr.message);
    }
  }, [otpData, otpDataErr]);

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  let handlePhone = (e) => {
    setPhone(e.target.value);
  };

  let handlePhoneBlur = () => {
    let valid = validatePhone(phone);

    if (valid) {
      setPhoneErr("");
    } else {
      setPhoneErr("Phone number is not valid");
    }
  };

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div className={classes.bigText}>Create your account</div>

        <form onSubmit={handleAction}>
          <div>
            <TextField
              fullWidth="true"
              label="Mobile Number"
              required
              onChange={handlePhone}
              onBlur={handlePhoneBlur}
              inputProps={{
                className: classes.input,
                //   pattern: "[0-9]{12}",
              }}
            />
          </div>

          {phoneErr ? (
            phoneErr.length ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {phoneErr}
              </div>
            ) : null
          ) : null}

          {authError ? (
            authError.length ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {authError}
              </div>
            ) : null
          ) : null}

          <div className={classes.lButton}>
            <center style={{ paddingTop: "20px" }}>
              <Button
                className={classes.orderBtn}
                style={{ backgroundColor: "#32aeb1", color: "#fff" }}
                // onClick={() => handleAction("otpInput")}
                type="submit"
              >
                NEXT
              </Button>
            </center>

            <center
              style={{
                paddingTop: "7vw",
                color: "#090f47",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Already have an account?
              <Link onClick={moveTo}> &nbsp; Login</Link>
            </center>
          </div>
        </form>

        {otpDataLoading === true ? (
          <center>
            <div style={{ marginTop: 20 }}>
              <LinearProgress />
            </div>
          </center>
        ) : null}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    otpData: state.authReducer.otpData,
    otpDataLoading: state.authReducer.otpDataLoading,
    otpDataErr: state.authReducer.otpDataErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendOTP: (data) => dispatch(sendOTP(data)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpMobile);
