import React from 'react';
import styled from "styled-components";
import ChangingProgressProvider from "./ChangingProgressProvider";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const MyProgressBar = () => {
    return (
        <StyledMain>
            <div className="progress-view">
                <ChangingProgressProvider values={[10, 20, 30, 40, 60, 80, 100]}>
                    {percentage => (
                        <CircularProgressbar value={percentage} text={`${percentage}%`} />
                    )}
                </ChangingProgressProvider>
            </div>
        </StyledMain>
    );
};

const StyledMain = styled.div`
  .progress-view {
    height: 60px;
    width: 60px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export default MyProgressBar;
