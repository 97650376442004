import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { Box, Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: "0 20px",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textStyle: {
    fontSize: 14,
    color: "#7b7b7b",
    // margin: "5px 0",
  },
  btnStyle: {
    backgroundColor: "#23232D",
    color: "#fff",
    fontSize: 12,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "black",
    },
  },
});

function valuetext(value) {
  return `${value}°C`;
}

export default function FilterByPrice({setStart, setEnd}) {
  const classes = useStyles();
  const [value, setValue] = React.useState([1, 650]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let handleChangeValue = () =>{
    setStart(value[0])
    setEnd(value[1])
  }

  return (
    <div className={classes.root}>
      {/* <Typography id="range-slider" gutterBottom>
        Temperature range
      </Typography> */}
      <Typography
        style={{ fontSize: 16, color: "#1b1d1e", fontWeight: "500" }}
      >
        FILTER BY PRICE
      </Typography>
      <Slider
        style={{ color: "#d71512" }}
        max={3000}
        min={1}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />

      <Box className={classes.boxItem}>
        <Typography className={classes.textStyle}>
          Price:{" "}
          <span style={{ fontWeight: "600" }}>
            ৳{value[0]} - ৳{value[1]}
          </span>
        </Typography>
        <Button className={classes.btnStyle} onClick={handleChangeValue}>FILTER</Button>
      </Box>
    </div>
  );
}
