export const ALL_PRODUCTS = {
  ALL_PRODUCTS_DATA: {
    MAIN: "ALL_PRODUCTS_DATA",
    SUCCESS: "ALL_PRODUCTS_DATA_SUCCESS",
    FAILURE: "ALL_PRODUCTS_DATA_FAILURE",
  },
  SEARCH_PRODUCTS: {
    MAIN: "SEARCH_PRODUCTS",
    SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
    FAILURE: "SEARCH_PRODUCTS_FAILURE",
  },
  RELATED_PRODUCTS: {
    MAIN: "RELATED_PRODUCTS",
    SUCCESS: "RELATED_PRODUCTS_SUCCESS",
    FAILURE: "RELATED_PRODUCTS_FAILURE",
  },
  FILTER_PRODUCTS: {
    MAIN: "FILTER_PRODUCTS",
    SUCCESS: "FILTER_PRODUCTS_SUCCESS",
    FAILURE: "FILTER_PRODUCTS_FAILURE",
  },
  CHILD_CATEGORY: {
    MAIN: "CHILD_CATEGORY",
    SUCCESS: "CHILD_CATEGORY_SUCCESS",
    FAILURE: "CHILD_CATEGORY_FAILURE",
  },
  CLEAR_SEARCH_PRODUCTS: {
    SUCCESS: "CLEAR_SEARCH_PRODUCTS_SUCCESS",
  },
};
