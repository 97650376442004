import React, {useEffect, useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Title from "../../../components/Title";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AddressFiled from "./AddressField";
import {connect} from "react-redux";
import {allSavedAddress, deleteAddress} from "../../../redux/actions/auth";
import {ApiParams} from "../../../constants/apiParams";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import querystring from "querystring";

const Accordion = withStyles({
    root: {
        //border: "1px solid rgba(0, 0, 0, .03)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0, 0, 0, .03)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const UpdateAddress = ({
                           allSavedAddress,
                           authData,
                           token,
                           addressData,
                           addressDataLoading,
                           addressDataErr, deleteAddress
                       }) => {
    const classes = useStyles();

    useEffect(() => {
        let params = {
            [ApiParams.customer_id]: authData.data.userId
        };
        allSavedAddress(params, authData?.data?.token);
    }, []);

    let addresses = addressData ?
        addressData.data ?
            addressData.data.length ?
                addressData.data :
                [] :
            [] :
        []

    const [addressField, setAddressField] = useState(false);
    const [shippingField, setShippingField] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.main}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    {
                        addresses.length ? addresses.map((item, key) => {

                            const handleAddressField = (event) => {
                                event.preventDefault()
                                let data = querystring.stringify({
                                    customer_id: item.customer_id,
                                    customer_auth: 'authData.data.token',
                                    address_id: item.address_id,
                                })

                                deleteAddress(data, authData?.data?.token)
                            };
                            return (
                                <div>
                                    <Accordion expanded={expanded === key} onChange={handleChange(key)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div style={{width: '100%'}}>
                                                <div className="edit-address"
                                                     style={{width: '100%', justifyContent: 'space-between'}}>
                                                    <Title
                                                        text={"Shipping Address"}
                                                        color={"#313132"}
                                                        fontSize={16}
                                                        lineHeight={20}
                                                        fontWeight={"500"}
                                                        textTransform={"uppercase"}
                                                        fontFamily={"Poppins"}
                                                    />
                                                    <div
                                                        onClick={handleAddressField}
                                                        style={{
                                                            marginLeft: "auto",
                                                            backgroundColor: "#EFEFEF",
                                                            padding: "5px 18px 5px 18px",
                                                            borderRadius: "50px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#313132",
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                fontFamily: "Poppins",
                                                            }}
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Title
                                                        text={item.address_1}
                                                        color={"#313132"}
                                                        fontSize={14}
                                                        lineHeight={22}
                                                        fontWeight={"400"}
                                                        margin={"30px 0 10px 0"}
                                                        fontFamily={"Poppins"}
                                                    />
                                                    <Title
                                                        text={item.address_2}
                                                        color={"#313132"}
                                                        fontSize={14}
                                                        lineHeight={22}
                                                        fontWeight={"400"}
                                                        margin={"30px 0 10px 0"}
                                                        fontFamily={"Poppins"}
                                                    />
                                                    <Title
                                                        text={item.city}
                                                        color={"#313132"}
                                                        fontSize={14}
                                                        lineHeight={22}
                                                        fontWeight={"400"}
                                                        margin={"10px 0"}
                                                        fontFamily={"Poppins"}
                                                    />
                                                    <Title
                                                        text={"Bangladesh"}
                                                        color={"#313132"}
                                                        fontSize={14}
                                                        lineHeight={22}
                                                        fontWeight={"400"}
                                                        margin={"10px 0 30px 0"}
                                                        fontFamily={"Poppins"}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AddressFiled address={item}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    <div className="divider"/>
                                </div>
                            )
                        }) : null
                    }
                </CardContent>
            </Card>
        </div>
    );
}
const useStyles = makeStyles({
    main: {
        margin: "10px 17px",
    },
    root: {
        minWidth: 275,
        marginBottom: 17,
        boxShadow: "0px 0px 10px #0000000D",
    },
    cardContent: {
        minHeight: '40vh',
        paddingBottom: "0px !important",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        addressData: state.authReducer.addressData,
        addressDataLoading: state.authReducer.addressDataLoading,
        addressDataErr: state.authReducer.addressDataErr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        allSavedAddress: (params, token) => dispatch(allSavedAddress(params, token)),
        deleteAddress: (data, token) => dispatch(deleteAddress(data, token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAddress);
