import React,{useState} from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import arrowImg from '../localization/images/btn-arrow-green.svg';
import arrowImgHover from '../localization/images/btn-arrow-white.svg';
import VisibilitySensor from "react-visibility-sensor";
const Button = ({onSubmit, text,link,img,hoverImg,fontSize,fontWeight,color,letterSpacing,lineHeight,margin,background,border,width})=>{
  let [btnArrow, setBtnArrow] = useState(arrowImg)

  let handleArrowImgChange = (img) =>  {
    setBtnArrow(img)
  };

  return(
    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledBtn
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          background={background}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          margin={margin}
          border={border}
          img={img}
          width={width}
          hoverImg={hoverImg}
          className={`${isVisible ? 'anim-active' : ''} fade-up dc-btn`}
        >
          {link ? (
            <Link to={link || '/'}>
                        <span
                          onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                          onMouseLeave={() => handleArrowImgChange(arrowImg)}
                          onClick={onSubmit}
                        >
                            {text} <img src={img || btnArrow} alt=""/>
                        </span>
            </Link>
          ) : (
            <a>
                          <span
                            onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                            onMouseLeave={() => handleArrowImgChange(arrowImg)}
                            onClick={onSubmit}
                          >
                            {text} <img src={img || btnArrow} alt=""/>
                        </span>
            </a>
          )}
        </StyledBtn>
      }
    </VisibilitySensor>
  )
};

const StyledBtn = styled.div`
  &.dc-btn{
    margin:${props => props.margin || '0'};
    width:${props => props.width || '173px'};
    height: 50px;
    a{
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || 14}px;
      font-weight:${props => props.fontWeight || 500};
      margin:0;
      line-height:${props => props.lineHeight || 21}px;
      color:${props => props.color || `${hover}`};
      text-transform: capitalize;
      border:${props => props.border || `1px solid ${hover}`};
      background-color:${props => props.background || `transparent`};
      letter-spacing:${props => props.letterSpacing || `2px`};
      position: relative;
      &:after{
        height: 0;
        background-color: ${hover};
        top: 0;
        left: 0;
        width: 100%;
        content: '';
        position: absolute;
        transition:height .4s ease;
      }
      span {
        height: 100%;
        width: 100%;
        z-index: 2;
        position: relative;
        transition: all .3s ease !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color:${props=> props.color || `${hover}`};
        img{
          transition:transform .5s ease;
          margin-left: 10px;
          position: relative;
          object-fit: contain;
          display: inline;
          height: 14px;
        }
      }

      &:hover{
        border-color: ${hover} !important;
        span {
          color: #ffffff;
          img{
            transform: translateX(5px);
          }
        }
        &:after {
          height:100%;
        }
      }
    }
  }
`;





export default Button;
