import React from "react";
import { Img } from "react-image";
import placeholder from "../localization/images/product-placeholder.jpeg";
import placeholderOld from "../localization/images/nonPharma.png";
const Placeholder = () => <img src={placeholder} alt="product img loader" />;

const Image = ({ url, isPharma, alt = "placeholder", className, style }) => {
  let pImg;
  if (isPharma === "pharma") {
    pImg = placeholder;
  } else {
    pImg = placeholderOld;
  }
  return (
    <Img
      draggable={false}
      style={style}
      src={url}
      alt={alt}
      loader={<img src={pImg} alt={"product img loader"} />}
      unloader={<img src={pImg} alt="product img loader" />}
      className={className}
    />
  );
};

export default Image;
