import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../globalStyleVars';
import mainLogo from '../localization/images/logo.svg';
import hambuerger from '../localization/images/hamburger-black.svg';
import hambuergerWhite from '../localization/images/close.svg';
import {Link,useLocation} from 'react-router-dom';
//import menuBg from '../localization/images/dynamic/about-detail.jpg';
import $ from 'jquery';

const Menu = ({logo, color, img, setSectionId }) => {
  let [menuItems, setMenuItems] = useState(false);
  let [newClass, setNewClass] = useState('');
  let [mobileSlide, setMobileSlide] = useState('');

  let handleMenuToggle = (e) => {
    e.stopPropagation();
    setMenuItems(!menuItems);
  };

  let handleClose = () => {
    setMenuItems(false)
  };

  let {pathname} = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 100) {
        setNewClass('menu-fixed')
      } else {
        setNewClass('')
      }
    });

    // menu click on mobile
    $('.mobile-menu .menuItems__top__single').each(function () {
      let $this = $(this);
      if($this.find('ul').length > 0){
        $this.addClass('hasChild')
      }
    });

    $('.hasChild').on('click',function () {
      var $this_ul = $(this).find('ul'),
        $this = $(this);
      $('.hasChild ul').not($this_ul).slideUp();
      $('.hasChild').not($this).removeClass('active');
      $this_ul.slideToggle();
      $this.toggleClass('active');
    });

    $('.mobile-menu .menuItems__top__single li a').click(function () {
      $('.menu-items').removeClass('true');
      $('.menu-bar').removeClass('true');

    })



  }, []);

  return (
    <div>
      <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
        <Container>
          <Row>
            <li onClick={handleMenuToggle}>
              <a href="javascript:void(0)">
                <img src={img || hambuerger} alt=""/>
                <img src={hambuergerWhite} alt=""/>
              </a>
            </li>
            <Col className="menu-bar__logo"><Link to="/"><img src={logo || mainLogo} alt=""/></Link> </Col>
            {/*<Col className="menu-bar__logo"><a href="/"><img src={logo || mainLogo} alt=""/></a> </Col>*/}
            <Col xs={9} className="menu-bar__menu-lists">
              <ul onClick={handleClose}>
                <li><Link className={pathname === '/about' ? 'active' : ''} to="/about">Products</Link></li>
                <li><Link className={pathname === '/division' ? 'active' : ''} to="/division">Shop by Room</Link></li>
                <li><Link className={pathname === '/services' ? 'active' : ''} to="/services">Shop by Brand</Link></li>
                <li><Link className={pathname === '/sustainability/details' ? 'active' : ''} to="/sustainability/details">Sustainability</Link></li>
                <li><Link className={pathname === '/media' ? 'active' : ''} to="/media">Media</Link></li>
                <li><Link className={pathname === '/contact' ? 'active' : ''} to="/contact">Contact</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </StyledMenu>

      {/*toggle menu */}


      <StyleToggleMenu className={`menu-items ${menuItems}`}>
        <Container>
          <div className="desktop-menu">
            <div className="menuItems__top" onClick={handleMenuToggle}>

              <div className="menuItems__top__bg">
                {/*<img src={menuBg} alt=""/>*/}
              </div>


              <div className="menuItems__top__single">
                <Link to={'/about'}>Palmal Group</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('who-we-are')}>
                    <Link to={'/about'}>Who we are</Link>
                  </li>
                  <li onClick={() => setSectionId('mission')}>
                    <Link to={'/about'}>Vision & Mission</Link>
                  </li>
                  <li onClick={() => setSectionId('award')}>
                    <Link to={'/about'}>Certification</Link>
                  </li>
                  <li><Link to={'/career'}>Career</Link></li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/division'}>Divisions</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Washing</Link>
                  </li>
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Knitting</Link>
                  </li>
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Printing</Link>
                  </li>
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Garments</Link>
                  </li>
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Embroidery</Link>
                  </li>
                  <li onClick={() => setSectionId('division')}>
                    <Link to={'/division'}>Composite</Link>
                  </li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/services'}>Servcies</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('garments-services')}>
                    <Link to={'/services'}>Garments</Link>
                  </li>
                  <li onClick={() => setSectionId('printing-services')}>
                    <Link to={'/services'}>Printing</Link>
                  </li>
                  <li onClick={() => setSectionId('embroidery-services')}>
                    <Link to={'/services'}>Embroidery</Link>
                  </li>
                  <li onClick={() => setSectionId('dying-services')}>
                    <Link to={'/services'}>Dying</Link>
                  </li>
                  <li onClick={() => setSectionId('washing-services')}>
                    <Link to={'/services'}>Washing</Link>
                  </li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/media'}>Media</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>Photo</Link></li>
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>Video</Link></li>
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>News</Link></li>
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>Garments</Link></li>
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>Events</Link></li>
                  <li onClick={() => setSectionId('media')}><Link to={'/media'}>CSR</Link></li>
                </ul>
              </div>

              <div className="menuItems__top__single">
                <Link to={'/contact'}>Contact</Link>
                <ul className="has-sub">
                  <li><Link to={'/contact'}>Contact</Link></li>
                </ul>
              </div>

            </div>
          </div>

          <div className="mobile-menu" >
            <div className="menuItems__top">
              <div className={`menuItems__top__single`}>
                <Link to={'/about'}>Palmal Group</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('who-we-are')}>
                    <Link onClick={handleClose} to={'/about'}>Who we are</Link>
                  </li>
                  <li onClick={() => setSectionId('mission')}>
                    <Link onClick={handleClose} to={'/about'}>Vision & Mission</Link>
                  </li>
                  <li onClick={() => setSectionId('award')}>
                    <Link onClick={handleClose} to={'/about'}>Certification</Link>
                  </li>
                  <li><Link onClick={handleClose} to={'/career'}>Career</Link></li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/division'}>Divisions</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Washing</Link></li>
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Knitting</Link></li>
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Printing</Link></li>
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Garments</Link></li>
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Embroidery</Link></li>
                  <li onClick={() => setSectionId('division')}><Link onClick={handleClose} to={'/division'}>Composite</Link></li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/services'}>Servcies</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('garments-services')}>
                    <Link to={'/services'}>Garments</Link>
                  </li>
                  <li onClick={() => setSectionId('printing-services')}>
                    <Link to={'/services'}>Printing</Link>
                  </li>
                  <li onClick={() => setSectionId('embroidery-services')}>
                    <Link to={'/services'}>Embroidery</Link>
                  </li>
                  <li onClick={() => setSectionId('dying-services')}>
                    <Link to={'/services'}>Dying</Link>
                  </li>
                  <li onClick={() => setSectionId('washing-services')}>
                    <Link to={'/services'}>Washing</Link>
                  </li>
                </ul>
              </div>


              <div className="menuItems__top__single">
                <Link to={'/media'}>Media</Link>
                <ul className="has-sub">
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>Photo</Link></li>
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>Video</Link></li>
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>News</Link></li>
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>Garments</Link></li>
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>Events</Link></li>
                  <li onClick={() => setSectionId('media')}><Link onClick={handleClose} to={'/media'}>CSR</Link></li>
                </ul>
              </div>

              <div className="menuItems__top__single">
                <Link to={'/contact'}>Contact</Link>
                <ul className="has-sub">
                  <li><Link onClick={handleClose} to={'/contact'}>Contact</Link></li>
                </ul>
              </div>

            </div>
          </div>
        </Container>
      </StyleToggleMenu>


    </div>
  )
};
const StyledMenu = styled.div`
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  height: 90px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  transition: background-color .3s ease;
  background-color: #fff;

  .row {
    align-items: center;
  }

  &.menu-fixed {
    background-color: ${hover};

    .menu-bar__menu-lists {
      ul {
        li {
          a {
            &:hover, &.active {
              color: ${darkGreen};
            }
          }

        }
      }
    }
  }

  &.true {
    background-color: transparent;
  }

  .menu-bar__logo {
    img {
      height: 30px;
      object-fit: contain;
    }
  }

  .menu-bar__menu-lists {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        a {
          font-size: 18px;
          font-weight: bold;
          line-height: 27px;
          text-transform: capitalize;
          color: ${props => props.color || '#ffffff'};
          transition: color .3s ease;

          &:hover, &.active {
            color: ${hover};
          }
        }

        &:nth-last-of-type(1) a {
          transition: opacity .4s ease;
          transition-delay: 0s;

          &:hover {
            opacity: .4 !important;
          }
        }

        &:nth-last-of-type(1) a {
          img:nth-last-of-type(1) {
            display: none;
            height: 22px;
          }
        }
      }
    }
  }

  &.true {
    li:not(:last-child) a {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    li:nth-last-of-type(1) a img {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block !important;
      }
    }
  }

  @media (max-width: 991px) {
    .menu-bar__menu-lists {
      ul {
        justify-content: flex-end;

        li:not(:last-child) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    height: 65px;
    .menu-bar__logo img {
      height: 45px;
    }
  }
`;

const StyleToggleMenu = styled.div`
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 100%;
  background: ${hover};
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition:all .6s ease;
  &.true{
    opacity:1;
    visibility: visible;

  }
  .menuItems__top {
    height: calc(100vh - 130px);
    display: flex;
    padding:80px;
    position: relative;
    //background:  #0366C2;
    margin-top: 90px;
    max-height:600px;

    &__bg{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 100%;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.8);
        right: 0;
        bottom: 0;
      }
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__single {
      position: relative;
      z-index: 3;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: max-content;
      flex-direction: column;
      padding-right: 8px;
      > a {
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-transform: capitalize;
        line-height: 30px;
        margin-bottom: 26px;
        transition:letter-spacing .6s ease;
        &:hover{
          letter-spacing: 2px;
        }
      }

      ul {
        text-align: left;
        li {
          a {
            display: block;
            font-size: 16px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 18px;
            transition:padding .4s ease;
            &:hover{
              padding-left: 10px;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }

    }
  }

  @media(min-width:1440px){
    @media(min-height:800px){
      display: flex;
      align-items: center;
      .menuItems__top{
        padding: 100px;
        margin-top: 0;
      }
    }

  }


  @media(min-width: 768px){
    .mobile-menu{
      display: none;
    }
  }

  @media(max-width: 767px){
    height: 100vh;
    padding-top: 30px;
    .menuItems__top {
      height:auto;
      display: block;
      padding:0px 0px 0 0px;
      position: relative;
      min-height: 100vh;
      background-color: transparent;
      &__bg{
        display: none !important;
      }
      &__single {
        display: block;
        width: 100%;
        >a{
          font-size: 15px;
          display: block;
          margin-bottom: 15px
        }
        ul{
          display: none;
        }
      }
    }
    .Footer__social {
      margin-top: 50px;
    }
    .desktop-menu{
      display: none;
    }

    .mobile-menu{
      .menuItems__top__single.active > a{
        color: #ffffff;
        opacity: .7;
      }
    }
  }

  .menuItems__top__single{
    position: relative;
    &:after{
      content: '';
      background-image: url(${hambuergerWhite});
      background-size: 13px;
      position: absolute;
      right: -17px;
      height: 50px;
      width: 50px;
      top: -12px;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(45deg);
      margin: auto;
      z-index: 3;
      display: none;
      transition: all .4s ease;
    }
    ul{
      margin-bottom: 30px;
    }
    &.hasChild{
      &:after{
        display: block;
      }
    }
    &.active{
      &:after{
        transform:none;
      }
      > a{
        color: ${hover};
        ul li a{
          color: #d4d4d4 !important;
        }
      }
    }


  }
`;
export default Menu;
