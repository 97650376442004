import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("lg")]: {
      height: "62px",
    },
  },
}));

const BlankBar = () => {
  const classes = useStyles();

  return <div className={classes.paper}></div>;
};

export default BlankBar;
