import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import productImage from "../../../localization/images/product.jpg";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    padding: "5% 6%",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  sectionTitle: {
    fontSize: "1.4vw",
    fontWeight: 500,
    color: "#293341",
  },
}));

const Favourites = () => {
  const classes = useStyles();
  const products = [
    {
      pic: productImage,
      name: "Ayour Harvel SPE 30",
      price: "25",
      rating: 3,
    },
    {
      pic: productImage,
      name: "Ayour Harvel SPE 30",
      price: "340",
      rating: 4,
    },
    {
      pic: productImage,
      name: "Ayour Harvel SPE 30",
      price: "560",
      rating: 2,
    },
    {
      pic: productImage,
      name: "Ayour Harvel SPE 30",
      price: "420",
      rating: 3,
    },
  ];
  return (
    <div className={classes.paper}>
      <Typography className={classes.sectionTitle}>
        Selected Favourites
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        // alignItems="center"
        spacing={3}
      >
        {products.map((product) => (
          <Grid item xs={3}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: "#e8e8e8",
                padding: ".73vw",
                // maxWidth: "170px",
                // maxHeight: "150px",
              }}
            >
              <Avatar
                src={productImage}
                variant="square"
                style={{ width: "100%", height: "100%" }}
              >
                PP
              </Avatar>
            </Box>
            <Typography
              style={{
                color: "#545C67",
                fontSize: ".8vw",
                fontWeight: "500",
              }}
            >
              Ayur Herbal SPF 30
            </Typography>
            <Typography style={{ color: "#7B7B7B", fontSize: "0.77vw" }}>
              $380.00
            </Typography>
            <Rating
              size="small"
              name="read-only"
              value={product.rating}
              readOnly
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Favourites;
