export const CART_PAGE = {
    ADD_ITEM_TO_CART: {
        MAIN: 'ADD_ITEM_TO_CART',
        SUCCESS: 'ADD_ITEM_TO_CART_SUCCESS',
        FAILURE: 'ADD_ITEM_TO_CART_FAILURE'
    },
    ADD_ITEM_TO_CART_SERVER: {
        MAIN: 'ADD_ITEM_TO_CART_SERVER',
        SUCCESS: 'ADD_ITEM_TO_CART_SERVER_SUCCESS',
        FAILURE: 'ADD_ITEM_TO_CART_SERVER_FAILURE'
    },
    UPDATE_ITEM_TO_CART_SERVER: {
        MAIN: 'UPDATE_ITEM_TO_CART_SERVER',
        SUCCESS: 'UPDATE_ITEM_TO_CART_SERVER_SUCCESS',
        FAILURE: 'UPDATE_ITEM_TO_CART_SERVER_FAILURE'
    },
    REMOVE_ITEM_FROM_CART: {
        MAIN: 'REMOVE_ITEM_FROM_CART',
        SUCCESS: 'REMOVE_ITEM_FROM_CART_SUCCESS',
        FAILURE:  'REMOVE_ITEM_FROM_CART_FAILURE'
    },
    CLEAR_CART: {
        MAIN: 'CLEAR_CART',
        SUCCESS: 'CLEAR_CART_SUCCESS',
        FAILURE: 'CLEAR_CART_FAILURE'
    },
    FETCH_ALL_CART_ITEMS: {
        MAIN: 'FETCH_ALL_CART_ITEMS',
        SUCCESS: 'FETCH_ALL_CART_ITEMS_SUCCESS',
        FAILURE: 'FETCH_ALL_CART_ITEMS_FAILURE'
    },
    ADD_ITEM_TO_WISHLIST: {
        MAIN: 'ADD_ITEM_TO_WISHLIST',
        SUCCESS: 'ADD_ITEM_TO_WISHLIST_SUCCESS',
        FAILURE: 'ADD_ITEM_TO_WISHLIST_FAILURE'
    },
    REMOVE_ITEM_FROM_WISHLIST: {
        MAIN: 'REMOVE_ITEM_FROM_WISHLIST',
        SUCCESS: 'REMOVE_ITEM_FROM_WISHLIST_SUCCESS',
        FAILURE:  'REMOVE_ITEM_FROM_WISHLIST_FAILURE'
    },
    CLEAR_WISHLIST: {
        MAIN: 'CLEAR_WISHLIST',
        SUCCESS: 'CLEAR_WISHLIST_SUCCESS',
        FAILURE: 'CLEAR_WISHLIST_FAILURE'
    },
    FETCH_ALL_WISHLIST: {
        MAIN: 'FETCH_ALL_WISHLIST',
        SUCCESS: 'FETCH_ALL_WISHLIST_SUCCESS',
        FAILURE: 'FETCH_ALL_WISHLIST_FAILURE'
    },
    SET_CHECKOUT: {
        MAIN: 'SET_CHECKOUT',
        SUCCESS: 'SET_CHECKOUT_SUCCESS',
        FAILURE: 'SET_CHECKOUT_FAILURE'
    },
    CLEAR_CHECKOUT: {
        MAIN: 'CLEAR_CHECKOUT',
        SUCCESS: 'CLEAR_CHECKOUT_SUCCESS',
        FAILURE: 'CLEAR_CHECKOUT_FAILURE'
    }
}
