import {DETAILS} from '../../constants/details'

const initialState = {
    productDetails: '',
    productDetailsLoading: false,
    productDetailsErr: null
};

export default function detailsReducer(state = initialState, action) {
    switch (action.type) {
        case DETAILS.PRODUCT_DETAIL_DATA.MAIN:
            return {
                ...state,
                productDetails: '',
                productDetailsLoading: true,
                productDetailsErr: null
            }

        case DETAILS.PRODUCT_DETAIL_DATA.SUCCESS:
            return {
                ...state,
                productDetails: action.result,
                productDetailsLoading: false,
                productDetailsErr: null
            }

        case DETAILS.PRODUCT_DETAIL_DATA.FAILURE:
            return {
                ...state,
                productDetails: '',
                productDetailsLoading: false,
                productDetailsErr: action.result
            }

        default:
            return state
    }
}
