import {BLOG} from "../../constants/blog";

let initState = {
    blogList: [],
    blogLoading: false,
    blogErr: null,
    blogDetail: '',
    blogDetailLoading: false,
    blogDetailErr: null
}

export default function blogReducer (state = initState, action) {
    switch (action.type) {
        case BLOG.BLOG_LIST.MAIN:
            return {
                ...state,
                blogList: [],
                blogLoading: true,
                blogErr: null
            }

        case BLOG.BLOG_LIST.SUCCESS:
            return {
                ...state,
                blogList: action.result.data,
                blogLoading: false,
                blogErr: null
            }

        case BLOG.BLOG_LIST.FAILURE:
            return {
                ...state,
                blogList: [],
                blogLoading: false,
                blogErr: action.result
            }
        case BLOG.BLOG_LIST_DETAIL.MAIN:
            return {
                ...state,
                blogDetail: '',
                blogDetailLoading: true,
                blogDetailErr: null
            }
        case BLOG.BLOG_LIST_DETAIL.SUCCESS:
            return {
                ...state,
                blogDetail: action.result.data,
                blogDetailLoading: false,
                blogDetailErr: null
            }
        case BLOG.BLOG_LIST_DETAIL.FAILURE:
            return {
                ...state,
                blogDetail: '',
                blogDetailLoading: false,
                blogDetailErr: action.result
            }
        default:
            return state;
    }
}