import React, { useState, useEffect } from "react";
import ImageCarousel from "./ImageCarousel";
import "./styles.css";
import config from "../../../../config";

const ImgCarousel = ({ ProductImages, isPharma }) => {
  const [images, setImages] = useState();

  useEffect(() => {
    setImages(
      ProductImages.map((item) => ({
        id: item.product_image_id,
        url: config.IMG_BASE + item.image,
      }))
    );
  }, [ProductImages]);

  return (
    <div>
      <ImageCarousel
        images={images}
        isPharma={isPharma}
        imgName={ProductImages[0].image}
      />
    </div>
  );
};

export default ImgCarousel;
