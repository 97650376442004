import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    margin: "10px 0",
  },
  textStyle: {
    fontSize: ".8vw",
    color: "#1b1d1e",
    textAlign: "center",
  },
  iconStyle: {
    fontSize: "1.4vw",
    textAlign: "center",
  },
  fullBox: {
    border: "1px solid #f0f0f0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mediumBox: {
    padding: "0 10px",
    borderRight: "1px solid #f0f0f0",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  smallBox: {
    padding: "0 10px",
    borderLeft: "1px solid #f0f0f0",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const SortBox = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.fullBox}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box className={classes.mediumBox}>
            <Typography className={classes.textStyle}>
              13 Products Found
            </Typography>
          </Box>
          <Box className={classes.mediumBox}>
            <Typography className={classes.textStyle}>
              Sort Best Selling
            </Typography>
          </Box>
        </Box>

        {/* Icon part */}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box className={classes.smallBox}>
            <ViewModuleIcon
              className={classes.iconStyle}
              style={{ color: "#d71512" }}
            />
          </Box>
          {/* <Box className={classes.smallBox}>
            <MenuIcon
              className={classes.iconStyle}
              style={{ color: "#1b1d1e" }}
            />
          </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default SortBox;
