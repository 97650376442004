import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";
import { HOME } from "../../constants/home/index";

function* fetchCategoryData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FETCH_CATEGORIES,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.BRAND_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.BRAND_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchTodayProductData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.TODAY_PRODUCT_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.TODAY_PRODUCT_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchEssentialProductData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.ESSENTIAL_PRODUCT_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.ESSENTIAL_PRODUCT_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchFeatureProductData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FEATURE_PRODUCT_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.FEATURE_PRODUCT_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchFeatureBrandData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_BRAND,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FEATURE_BRAND_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.FEATURE_BRAND_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchConcernProductData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.CONCERN_PRODUCT_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.CONCERN_PRODUCT_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchExpConcernData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FETCH_CHILD_CATEGORIES,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.EXP_CONCERN_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.EXP_CONCERN_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchSpecialData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.SPECIAL_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.SPECIAL_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

export default function* homeSagas() {
  yield takeLatest(HOME.BRAND_DATA.MAIN, fetchCategoryData);
  yield takeLatest(HOME.TODAY_PRODUCT_DATA.MAIN, fetchTodayProductData);
  yield takeLatest(HOME.ESSENTIAL_PRODUCT_DATA.MAIN, fetchEssentialProductData);
  yield takeLatest(HOME.FEATURE_PRODUCT_DATA.MAIN, fetchFeatureProductData);
  yield takeLatest(HOME.FEATURE_BRAND_DATA.MAIN, fetchFeatureBrandData);
  yield takeLatest(HOME.CONCERN_PRODUCT_DATA.MAIN, fetchConcernProductData);
  yield takeLatest(HOME.EXP_CONCERN_DATA.MAIN, fetchExpConcernData);
  yield takeLatest(HOME.SPECIAL_DATA.MAIN, fetchSpecialData);
}
