export const ApiParams = {
  category_id: "categoryId",
  filter_category: "filter_category",
  filter_manufacture: "filter_manufacture",
  price_start: "price_start",
  price_end: "price_end",
  product_id: "productId",
  limit: "limit",
  offset: "offset",
  parent_id: "parent_id",
  manufacture_list: "manufacture_list",
  customer_id: "customer_id",
  customer_auth: "customer_auth",
  search_text: "search_text",
  group_name: "group_name",
  page_id: "page_id",
  post_id: "post_id",
};
