import axios from 'axios'
import config from '../config'
import querystring from "querystring";

function getIRequestProp({token = ""}) {
  let serverUrl = config.BASE_URL;
  let headers = {'Content-Type': 'application/json'}
  if (token !== "") {
    headers = Object.assign({}, headers, {"x-access-token": token});
  }
  return {
    serverUrl: serverUrl,
    requestHeader: headers
  }
}

function postIRequestProp({token = ""}) {
  let serverUrl = config.BASE_URL;
  let headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
  if (token !== "") {
    headers = Object.assign({}, headers, {"x-access-token": token});
  }
  return {
    serverUrl: serverUrl,
    requestHeader: headers
  }
}

function postIRequestFromData({token = ""}) {
  let serverUrl = config.BASE_URL;
  let headers =  {'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL'}
  if (token !== "") {
    headers = Object.assign({}, headers, {"x-access-token": token});
  }
  return {
    serverUrl: serverUrl,
    requestHeader: headers
  }
}

async function get(url, parameter, token = "") {
  let {serverUrl, requestHeader} = getIRequestProp({token: token});
  return axios.get(serverUrl + url, {
    params: parameter,
    headers: requestHeader
  })
}

async function postFormData(url, body,  token = "") {
  let {serverUrl, requestHeader} = postIRequestFromData({token: token});

  let formData = new FormData();
  formData.append("upload_pres", body.upload_pres);
  formData.append("customer_id", body.customer_id);
  formData.append("customer_auth", body.customer_auth);
  formData.append("order_id", body.order_id);
  formData.append("note", body.note);

  return await axios.post(serverUrl + url, formData, {
    headers: requestHeader
  })
}

async function postForm(url, body,  token = "") {
  let {serverUrl, requestHeader} = postIRequestProp({token: token});
  return await axios.post(serverUrl + url, body, {
    headers: requestHeader
  })
}

async function post(url, body,  token = "") {
  let {serverUrl, requestHeader} = getIRequestProp({token: token});
  return await axios.post(serverUrl + url, body, {
    headers: requestHeader
  })
}

async function put(url, body,  token = "") {
  let {serverUrl, requestHeader} = getIRequestProp({token: token});
  return await axios.put(serverUrl + url, body, {
    headers: requestHeader
  })
}

async function putEncoded(url, body,  token = "") {
  let {serverUrl, requestHeader} = postIRequestProp({token: token});
  return await axios.put(serverUrl + url, body, {
    headers: requestHeader
  })
}

async function patch(url, body,  token = "") {
  let {serverUrl, requestHeader} = getIRequestProp({token: token});
  return await axios.patch(serverUrl + url, body, {
    headers: requestHeader
  })
}

async function remove(url, body,  token = "") {
  let {serverUrl, requestHeader} = getIRequestProp({token: token});
  return await axios.delete(serverUrl + url, {
    data: body,
    headers: requestHeader
  })
}

async function removeEncoded(url, body,  token = "") {
  let {serverUrl, requestHeader} = postIRequestProp({token: token});
  return await axios.delete(serverUrl + url, {
    data: body,
    headers: requestHeader
  })
}

export const AxiosServices = {
  get,
  postForm,
  postFormData,
  post,
  put,
  putEncoded,
  patch,
  remove,
  removeEncoded
}
