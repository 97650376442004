import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {clearOrder} from "../../redux/actions/order";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Row} from "react-bootstrap";

let PaymentFailure = ({clearOrder}) => {
    const history = useHistory();

    const handleToOrder =()=>{
        clearOrder()
        history.push('/order-list')
    }

    return (
        <StyledSuccess>
            <div className="main">
                <Title
                    text={"Order Processing failed. Please Try Again"}
                    fontWeight={"bold"}
                    fontSize={24}
                    lineHeight={32}
                    margin={"30px 0 10px 0"}
                    textAlign={"center"}
                />
                <div onClick={handleToOrder} style={{cursor: "pointer"}}>
                    <Title
                        text={`Payment was failed. Please retry from order list`}
                        fontWeight={400}
                        fontSize={18}
                        lineHeight={24}
                        margin={"0 0 10px 0"}
                        textAlign={"center"}
                        color={"#419AE8"}
                    />
                </div>
            </div>
        </StyledSuccess>
    );
};

let StyledSuccess = styled.div`
  background-color: #f8f8f8;
  padding: 120px 40px;
  height: 120vh;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

function mapDispatchToProps(dispatch) {
    return {
        clearOrder: () => dispatch(clearOrder())
    };
}

export default connect(null, mapDispatchToProps)(PaymentFailure);
