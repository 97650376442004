import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
      color: "#797979",
    },

    padding: "5%",
  },
}));

const TermsConditions = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: "30px", textAlign: "center", color: "#505050" }}
      >
        Terms and Conditions
      </Typography>

      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ color: "#797979", fontSize: "16px" }}
        >
          1. Regulations <br />
          2. Security & Privacy
          <br />
          3. Commitment Agreement
          <br />
          4. Information and Advice
          <br />
          5. Pricing
          <br />
          6. Completed Orders
          <br />
          7. Concluding a binding contract
          <br />
          8. Payment
          <br />
          9. Authorisation of orders for medicines
          <br />
          10. Postage Costs
          <br />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ color: "#797979", fontSize: "16px" }}
        >
          11.Delivery of orders
          <br />
          12. Returns Policy
          <br />
          13. Refunds Policy
          <br />
          14. Complaints Procedure
          <br />
          15. Signed for deliveries
          <br />
          16. Notification of errors
          <br />
          17. Information on this website
          <br />
          18. Third party rights
          <br />
          19. Our right to vary T&Cs
          <br />
        </Grid>
      </Grid>

      <div style={{ color: "#797979", fontSize: "16px", padding: "2% 0" }}>
        This service is provided by Taj Associates trading as ‘Netmed24'. By
        using this service you are confirming that you understand and agree to
        be legally bound by these Terms and Conditions. These terms and
        conditions apply to your access to the Netmed24 website
        ("www.Netmed24.com.bd"). Please read these terms and conditions
        carefully before accessing and/or ordering any medicine from our Website
        and Mobile Apps. If you access the Website, and/or place an order for
        medicine, you agree to be bound by these terms and conditions. If you do
        not agree with the terms and conditions please do not use our Website
        and Mobile Apps. Netmed24 Mobile apps and website is owned and operated
        by Taj Associates trading as ‘Netmed24', registered in the Bangladesh at
        25/5 Taj Square, Taj Mahal Road, Mohammadpur, Dhaka 1207, with the
        company trade License number: 047354. If you have any questions about
        our website please contact us: Tel: +88 013 164 58 575 E-mail:
        Netmed24@gmail.com
      </div>

      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          1. Regulation
        </Typography>
        Our dispensing pharmacy is regulated by Department of Drug
        Administration, Bangladesh under License no:
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          2. Security & Privacy
        </Typography>
        Your privacy is imperative to us and the following information explains
        what measures we take to protect it.
        <div style={{ paddingTop: "1%", color: "#797979", fontSize: "16px" }}>
          1. Netmed24 uses security technology, including firewalls and Secure
          Socket Layers to protect information submitted through this website.{" "}
          <br />
          2. We do not have access to your financial information which is
          securely transferred directly to our card processing agents, who
          securely process it on our instructions. <br />
          3. During your visit to our site we may gather certain personal
          information that is necessary to set up your account, for the purposes
          of billing, delivery of your medicine and queries. We only keep your
          information for as long as is necessary to process your order, process
          any applicable refunds, and respond to any complaints/feedback or to
          provide you with promotional information you have subscribed to.{" "}
          <br />
          4. Electronic transmissions are never completely private or secure
          therefore we encourage customers to ensure that any computer (tablet
          or smart phone) you use to access your online patient record is
          suitably protected from potential interception. We will use the
          information you provide with other information we obtain about you to
          administer and provide the goods and/or services you request. <br />
          5. We will not share your personal information with any organisation
          other than those directly involved in delivering these services,
          namely the dispensing pharmacy and the courier service. This service
          does not take responsibility to inform your regular GP of the
          medication prescribed by our doctors. If you want us to do this we
          would require your GP details and your consent.
        </div>
      </div>
      <div style={{ paddingBottom: "1%" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          3. Commitment Agreement
        </Typography>{" "}
        <br />
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{ color: "#797979", fontSize: "16px" }}
          >
            <strong style={{ color: "#797979" }}>Our Commitment to you</strong>
            <br />
            <br />
            Professional responsibility
            <br />
            <br />
            provide a professional and transparent service
            <br />
            <br />
            Prescribe in the best interest of the patient
            <br />
            <br />
            Take all reasonable steps to protect patients' personal information
            <br />
            <br />
            Safe and Secure patient experience
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ color: "#797979", fontSize: "16px" }}
          >
            <b style={{ color: "#797979" }}>Patient's Commitment to Netmed24</b>
            <br />
            Provide accurate information
            <br />
            <br />
            Confirm you are aged 18 or over
            <br />
            <br />
            Comply with instructions
            <br />
            <br />
            Notifying your Doctor is your responsibility
            <br />
            <br />
            Notify our doctors of any side-effects
          </Grid>
        </Grid>
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          4. Information and Advice
        </Typography>
        The information and advice provided by Netmed24 when you use the service
        is based on the information you have supplied to us. It is your
        responsibility to ensure this information is accurate and you accept
        that failure to do so (whether intentionally or not) will affect the
        information and advice we give to you and the medicines we supply to you
        and, as such, may have consequences for which we are not responsible.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          5. Pricing
        </Typography>
        All prices on this website are in BDT and include VAT unless expressly
        stated otherwise. We take care to ensure that all pricing information on
        our website is accurate but occasionally there may be an error. In the
        event that an error has occurred and the price charged is too high, we
        will identify all those that have overpaid and refund the difference.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          6. Completed Orders
        </Typography>
        When you place an order for medicines or take up a service that involves
        the prescribing of medicines this will require us to perform an
        assessment of your clinical suitability for the medicines before the
        medicines can be approved for supply. You agree to us commencing this
        service and an assessment of your clinical suitability when you place
        the order and, as such if you cancel your order once the prescription
        has been issued you will be liable for the prescription fee but refunded
        the full cost of the medicine and delivery costs.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          7. Concluding a binding contract
        </Typography>
        When we receive an order from you to purchase goods or services from us,
        we will confirm that we have received the order by sending a
        confirmation email to the contact email address or by mobile apps
        notification or mobile SMS that you supplied. Professional codes of
        conduct and legal restrictions may limit the number and frequency of any
        item we are permitted to supply to you and, as such, we have no
        obligation to supply quantities exceeding the relevant permitted maximum
        and will charge only for those we do supply. We reserve the right to
        reject any order.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          8. Payment
        </Typography>
        In providing payment card details, you confirm that you are authorized
        to use the card and authorize us, or our payment service provider, to
        take payment in full for the items in your order and any associated
        postage and packaging fees and any other charges that become due to us
        under these terms and conditions.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          9. Authorization of orders for medicines
        </Typography>
        Our doctors can only make a decision as to whether it is in the best
        interests of the patient to receive a medicine when the doctor has all
        relevant information. On occasion, this may require the doctor to ask
        the patient for additional information further to the information that
        was provided in response to an online questionnaire.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          10. Postage Costs
        </Typography>
        Postage and packaging is charged on all orders of medicines under 150
        BDT that are sent in the post. The different costs associated with
        postage can be seen under 'Delivery' on our website.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          11. Delivery of orders
        </Typography>
        Our partner pharmacy will dispense all medicines. Medicines will be
        dispatched normally within 1-2 working days of the doctor authorizing
        prescription.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          12. Returns Policy
        </Typography>
        You can return any unwanted items within 7 days of receipt for a
        replacement at 25/5 Taj Square, Taj Mahal Road, Mohammadpur, Dhaka 1207.
        Please inform us of the returned item via email at epharma18@gmail.com
        Temperature controlled Products cannot be returned due to our quality
        ensure policy. Any additional cost and tracking of returned items are
        the responsibility of the customer. Returned item(s) must be undamaged,
        unopened and in their original packaging; Netmed24 reserves the right to
        decline a refund in such instances. Cancelling your order: In order to
        cancel your order please either phone or email us at the earliest
        instance. Please note our pharmacist sometimes issue medicines within
        minutes or hours of an order being placed in which case you will be
        liable for the processing fee but eligible for a full refund of the
        remaining costs including medicine excluding 3rd party commission.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          13. Refunds Policy
        </Typography>
        Your refund will automatically be processed within 3-5 working days but
        in some cases can take up to 14 days. We want you to be happy with your
        purchase. If you're not, just return the product to the dispensing
        pharmacy, following the instructions above, and we'll exchange or refund
        it to the credit or debit card of the person who originally placed and
        paid for the order.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          14. Complaints Procedure
        </Typography>
        Netmed24 aims to maintain 100% customer satisfaction, but we realize
        there maybe instances where this is not the case. We operate a
        continuous improvement policy in customer care and try our best to get
        things right first time. If you are unhappy with anything please contact
        us immediately and our staff will be more than happy to rectify any
        issues you may have. For complaints please direct your concerns to
        epharma18@gmail.com. We will endeavor to respond to you within 48 hours.
        If it is more of an urgent matter then please phone us instead to
        rectify any issues at the earliest possible opportunity.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          15. Signed for deliveries
        </Typography>
        1. Signed for: Netmed24 is liable for misplaced items and redelivery of
        items in this case.
        <br />
        2. Standard delivery: It is your responsibility to ensure that someone
        is available to sign for any medicines that are delivered at your chosen
        delivery address or to collect the medicine from our nearest outlets by
        yourself. The person signing for medicines does not need to be the named
        patient. You acknowledge and accept that we have no control over the
        postal system and we have no liability in respect of items that are not
        received by you after we have dispatched them. It is your responsibility
        to ensure that the person signing for the medicines delivers them to
        you.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          16. Notification of errors
        </Typography>
        You should check the content of each delivery promptly upon receipt. If
        you believe that items have been sent to you in error you should contact
        us immediately on Tel: +88 013 164 58 575 or E-mail: epharma18@gmail.com
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          17. Information on this website
        </Typography>
        We take care to ensure that all information available on our website
        about our business, services and any products mentioned is accurate.
        However, some things may develop or chance leading to the information
        being out of date occasionally. General medical information provided on
        the website is not a substitute for specific and personalized medical
        advice and should not be read or used as such. Links to other websites:
        Netmed24 provides links from this website to websites that are owned and
        controlled by third parties such as health resources. These links are
        provided only for your convenience and we have no control over and will
        have no liability in respect of those websites.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          18. Third party rights
        </Typography>
        Nothing in these Terms and Conditions is intended to, nor shall it grant
        a benefit on any third party and a person who is not a party to these
        Terms and Conditions has no rights to enforce them.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          19. Our right to vary T&Cs
        </Typography>
        We reserve the right to revise and amend these terms and conditions from
        time to time. You will be subject to the terms and conditions that are
        in force at the time that you order goods from us unless:
        <br />
        <br />
        1. Any change to these terms and conditions is required to be made by
        law or governmental authority, in which case the changes may apply to
        orders previously placed by you; or
        <br />
        2. We notify you of any changes to these terms and conditions before we
        accept your order, in which case we have the right to assume that you
        have accepted the change to the terms and conditions unless you notify
        us to the contrary within seven (7) working days of receipt by you of
        the goods.
      </div>
    </div>
  );
};

export default TermsConditions;
