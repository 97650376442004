import React, {useEffect} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import RightIcon from "../../../localization/images/navRightBlack.svg";
import LeftIcon from "../../../localization/images/navLeftBlack.svg";
import {ApiParams} from "../../../constants/apiParams";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {Link} from "react-router-dom";
import {Img} from "react-image";

import {connect} from "react-redux";
import {fetchTodayProductData} from "../../../redux/actions/home";
import {
    addItemToCart,
    addItemToCartServer,
    addItemToWishlist,
    removeItemFromCart,
    updateItemToCartServer,
} from "../../../redux/actions/cart";
import config from "../../../config";
import placeholder from "../../../localization/images/product-placeholder.jpeg";
import querystring from "querystring";

const Placeholder = () => <img src={placeholder} alt="product img loader"/>;

let checkCartAvailable = (cart, id) => {
    if (cart) {
        if (cart.length) {
            let found = cart.find((item) => item.cartItem.product_id === id);
            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

let checkWishlistAvailable = (wishlist, id) => {
    if (wishlist) {
        if (wishlist.length) {
            let found = wishlist.find((item) => item.product_id === id);

            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

const TodaysDeal = ({
                        getFetchTodayProductData,
                        todayProduct,
                        addItemToCart,
                        addItemToCartServer,
                        updateItemToCartServer,
                        cus_cart,
                        authData,token,
                        removeItemFromCart,
                        addItemToWishlist,
                        cart,
                        wishlist,
                    }) => {
    useEffect(() => {
        let params = {
            [ApiParams.category_id]: "16",
            [ApiParams.limit]: "6",
            [ApiParams.offset]: "0",
        };
        getFetchTodayProductData(params);
    }, []);

    let data = todayProduct ? todayProduct.data : null;
    let ProductToCategories = data ? data.ProductToCategories : [];
    let size = 4;
    let featureProduct = ProductToCategories.slice(0, size);

    function getCurrent(cart, product_id) {
        let found = cart.length
            ? cart.find((item) => item.cartItem.product_id === product_id)
            : null;

        return found;
    }

    const params = {
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 1000,
        // pagination: {
        //     el: '.swiper-pagination',
        //     clickable: true
        // },
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            960: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1500: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            1501: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev'
        // },
    };

    return (
        <>
            {featureProduct.length ? (
                <StyledToday>
                    <Container className="news-events-slider">
                        <Row>
                            <Col sm={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingBottom: 24,
                                        marginTop: 32,
                                    }}
                                >
                                    <div className="top-title">Today's Deal</div>
                                    <div
                                        style={{
                                            marginLeft: "auto",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                        className="viewBtn"
                                    >
                                        <Link
                                            to={`/products/cat=${16}`}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{fontSize: 12, fontWeight: 400}}>
                                                View all
                                            </div>
                                            <NavigateNextIcon/>
                                        </Link>
                                    </div>
                                </div>
                                {featureProduct.length ? (
                                    <Swiper {...params}>
                                        {featureProduct.map((item) => {
                                            let productData = item
                                                ? item.Product
                                                    ? item.Product
                                                    : []
                                                : [];
                                            let product = productData
                                                ? productData[0]
                                                    ? productData[0]
                                                    : ""
                                                : "";
                                            let ProductDescriptions = product
                                                ? product.ProductDescriptions
                                                    ? product.ProductDescriptions
                                                    : ""
                                                : "";

                                            let ProductImages = product
                                                ? product.ProductImages
                                                    ? product.ProductImages
                                                    : []
                                                : [];
                                            let images = ProductImages
                                                ? ProductImages[0]
                                                    ? ProductImages[0]
                                                    : ""
                                                : "";

                                            let addToCart = (item) => {
                                                addItemToCart(item);

                                                if (authData) {
                                                    let data = querystring.stringify({
                                                        customer_id: authData?.data?.userId,
                                                        customer_auth: 'customer_auth',
                                                        product_id: item.product_id,
                                                        quantity: 1,
                                                        option: 'text me'
                                                    })
                                                    addItemToCartServer(data,authData?.data?.token)
                                                    // if (cus_cart) {
                                                    //     let data = querystring.stringify({
                                                    //         customer_id: authData?.data?.userId,
                                                    //         customer_auth: 'customer_auth',
                                                    //         cart_id: cus_cart?.data?.cart_id,
                                                    //         product_id: item.product_id,
                                                    //         quantity: 1,
                                                    //         option: 'text me'
                                                    //     })
                                                    //     updateItemToCartServer(data,authData?.data?.token)
                                                    // } else {
                                                    //
                                                    // }
                                                }

                                            };

                                            let addToWishlist = (item) => {
                                                addItemToWishlist(item);
                                            };

                                            let isCart = checkCartAvailable(cart, item.product_id);
                                            let isWish = checkWishlistAvailable(
                                                wishlist,
                                                item.product_id
                                            );

                                            let currentItem = getCurrent(cart, item.product_id);
                                            let qty = currentItem ? currentItem.count : 1;

                                            return (
                                                <div className="news-events-slider__single">
                                                    <Link to={`/details/${product.product_id}`}>
                                                        <div className="news-events-slider__single__img">
                                                            <Img
                                                                src={config.IMG_BASE + product.image}
                                                                alt=""
                                                                loader={<Placeholder/>}
                                                                unloader={<Placeholder/>}
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="news-events-slider__single__content">
                                                        <ProductInfo>
                                                            <h3 className="product-title">
                                                                {ProductDescriptions.name}
                                                            </h3>
                                                            <div className="product-meta">
                                                                <div className="productPriceWrapper">
                                                                    {/*<span className="discountedPrice">*/}
                                                                    {/*     TK {product.price}*/}
                                                                    {/*</span>*/}
                                                                    <span className="product-price">
                                    TK {parseFloat(product.price)}
                                  </span>
                                                                </div>
                                                                {/*<div>*/}
                                                                {/*    <Button*/}
                                                                {/*        className="cart-button"*/}
                                                                {/*        variant="secondary"*/}
                                                                {/*        borderRadius={6}*/}
                                                                {/*    >*/}
                                                                {/*        <ButtonText>*/}
                                                                {/*            +*/}
                                                                {/*        </ButtonText>*/}
                                                                {/*    </Button>*/}
                                                                {/*</div>*/}
                                                                {isCart ? (
                                                                    <div id="wrapper" className="is-cart">
                                                                        <button
                                                                            type="button"
                                                                            //onClick={() => addToCart(item)}
                                                                        >
                                                                            <ShoppingCartIcon/> &nbsp; &nbsp;ADD TO
                                                                            CART
                                                                        </button>
                                                                        {/*<Counter*/}
                                                                        {/*    item={item}*/}
                                                                        {/*    variant="lightHorizontal"*/}
                                                                        {/*    value={currentItem.count}*/}
                                                                        {/*    onIncrement={addItemToCart}*/}
                                                                        {/*    onDecrement={removeItemFromCart}*/}
                                                                        {/*/>*/}
                                                                    </div>
                                                                ) : (
                                                                    <div id="wrapper">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => addToCart(item)}
                                                                        >
                                                                            <ShoppingCartIcon/> &nbsp; &nbsp;ADD TO
                                                                            CART
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </ProductInfo>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Swiper>
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </StyledToday>
            ) : null}
        </>
    );
};

const StyledToday = styled.div`
  background-color: #fff;
  position: relative;

  .container {
    max-width: 95%;
  }

  .viewBtn {
    color: #313132;
  }

  .viewBtn:hover {
    color: #1ec3aa;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }

  .top-title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    // padding-left: 8px;
    // padding-bottom: 16px;
    color: #2fa5aa;
  }

  #wrapper {
    width: 100%;
    height: 100%;
  }

  button {
    height: 40px;
    position: relative;
    width: 130px;
    border-radius: 4px;
    font-size: 12px;
    background: linear-gradient(90deg, #1f8184 30%, rgb(30 195 170) 30% 100%);
    color: #fff;
    border: none;
  }

  .is-cart {
    button {
      height: 40px;
      position: relative;
      width: 130px;
      border-radius: 4px;
      font-size: 12px;
      background: linear-gradient(90deg, #dc3545 30%, rgb(30 195 170) 30% 100%);
      color: #fff;
      border: none;
    }
  }

  .news-events-slider {
    &__single {
      border: 1px solid #f0f0f0;

      &__content {
        background-color: #f0f0f0;
        padding: 20px;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.5);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
        }

        .line {
          margin-top: 4px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
      }

      &__img {
        padding-top: 71%;
        position: relative;
        overflow: hidden;
        z-index: 3;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;
        }

        &:hover {
          img {
            transform: scale(1.05);
            filter: contrast(140%);
          }
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    //margin-left: -50px;
    //margin-right: -50px;
    //padding-right: 50px;
    //padding-left: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      right: 0;
      top: 0;
      z-index: 2;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }

  @media (max-width: 1024px) {
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
    min-height: 123px;
  }

  .product-title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    // word-wrap: break-word;
    //margin: -10px 0 7px 0;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  .product-weight {
    text-align: center;
    margin-top: 14px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #898686;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .product-meta {
    margin-top: 8px;
    //display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-height: 32px;
    }

    .productPriceWrapper {
      position: relative;
      margin-bottom: 8px;

      .product-price {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #b61026;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #d98b99;
        padding: 0 5px;
        margin-right: 15px;
        text-decoration: line-through;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .cart-button {
      border: 1px solid #000000;
      border-radius: 4px;
      height: 24px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      font-weight: 700;
      color: #000;
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8%;
      }

      .btn-text {
        @media (max-width: 767px) {
          display: block;
        }
      }

      &:hover {
        color: #ffffff;
        background-color: #009e7f;
        border-color: #009e7f;
      }

      svg {
        fill: currentColor;
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }

      button {
        width: 130px;
        height: 40px;
      }

      .incBtn {
        top: 0;
        justify-content: center;
      }

      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }

      input[type="number"] {
        left: 0;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: block;
  }
`;

function mapStateToProps(state) {
    return {
        todayProduct: state.homeReducer.todayProduct,
        cart: state.cartReducer.cart,
        cus_cart: state.cartReducer.cus_cart,
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        wishlist: state.cartReducer.wishlist,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFetchTodayProductData: (params) => dispatch(fetchTodayProductData(params)),
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addItemToCartServer: (data,token) => dispatch(addItemToCartServer(data,token)),
        updateItemToCartServer: (data,token) => dispatch(updateItemToCartServer(data,token)),
        removeItemFromCart: (data) => dispatch(removeItemFromCart(data)),
        addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaysDeal);
