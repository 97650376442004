import {AUTH} from "../../constants/auth";

export function sendOTP(data) {
    return {
        type: AUTH.SET_OTP.MAIN,
        data
    }
}

export function logout() {
    return {
        type: AUTH.LOGOUT_USER.MAIN
    }
}

export function verifyOTP(data) {
    return {
        type: AUTH.VERIFY_CODE.MAIN,
        data
    }
}

export function forgetOTPSend(data) {
    return {
        type: AUTH.FORGET_CODE.MAIN,
        data
    }
}

export function forgetReq(data) {
    return {
        type: AUTH.FORGET_PASS_REQ.MAIN,
        data
    }
}

export function signUp(data) {
    return {
        type: AUTH.SET_SIGN_UP.MAIN,
        data
    }
}

export function login(data) {
    return {
        type: AUTH.SET_LOGIN.MAIN,
        data
    }
}

export function allSavedAddress(params, token) {
    return {
        type: AUTH.ALL_ADDRESS.MAIN,
        params: params,
        token: token
    }
}

export function updateAddress(data, token) {
    return {
        type: AUTH.UPDATE_ADDRESS.MAIN,
        data,
        token
    }
}

export function updateProfile(data, token) {
    return {
        type: AUTH.UPDATE_PROFILE.MAIN,
        data,
        token
    }
}

export function deleteAddress(data, token) {
    return {
        type: AUTH.DELETE_ADDRESS.MAIN,
        data,
        token
    }
}
