import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { CounterBox, CounterButton, CounterValue } from './CounterStyle';

const Counter = (
    {
        onDecrement,
        onIncrement,
        value,
        item,
        variant,
        className,
    }
) => {
    let addItem = () => {
        onIncrement(item)
    }

    let removeItem = () => {
        let data = {
            count: 1,
            product_id: item.product_id
        }
        onDecrement(data)
    }

    return (
        <CounterBox variant={variant} className={className}>
            <div onClick={removeItem} style={{ cursor: 'pointer' }}>
                <CounterButton variant={variant}>
                    <RemoveIcon style={{ width: 10 }} />
                </CounterButton>
            </div>
            <CounterValue>{value}</CounterValue>
            <div onClick={addItem} style={{ cursor: 'pointer' }}>
                <CounterButton variant={variant}>
                    <AddIcon style={{ width: 10 }} />
                </CounterButton>
            </div>
        </CounterBox>
    );
};

export default Counter
