import Index from "./container/home/";
import React from "react";
import StyledGlobal from "./globalStyle";
import Footer from "./components/Footer";
import Error from "./container/error";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import TopBar from "./components/TopBar/TopBar";
import BlankBar from "./components/TopBar/BlankBar";
import ProductDetails from "./container/productDetails";
import YourCart from "./container/addToCart";
import Checkout from "./container/addToCart/components/Checkout";
import HideAppBar from "./components/TopBar/HideAppBar";
import AllProducts from "./container/AllProduct/AllProducts";
import DiabeticCare from "./container/DiabeticCare/DiabeticCare";
import Success from "./container/payment/Success";
import Order from "./container/order";
import { connect } from "react-redux";
import PaymentCancel from "./container/payment/Cancel";
import PaymentFailure from "./container/payment/Failure";
import ProfileMain from "./container/Profile";
import About from "./staticPage/About";
import Contact from "./staticPage/Contact";
import TermsConditions from "./staticPage/TermsConditions";
import PrivacyPolicy from "./staticPage/PrivacyPolicy";
import Blog from "./container/blog";
import BlogDetail from "./container/blog-details";

function App({ authData }) {
  const location = useLocation();
  return (
    <React.Fragment>
      <TopBar />
      <HideAppBar />
      <BlankBar />
      <StyledGlobal />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={Index} />
          <Route path="/details/:id" component={ProductDetails}/>
          <Route path="/your-cart" component={YourCart} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/products/cat=:id" component={AllProducts}/>
          <Route path="/med-care" component={DiabeticCare}/>
          <Route path="/payment-success" component={Success} />
          <Route path="/payment-cancel" component={PaymentCancel} />
          <Route path="/payment-fail" component={PaymentFailure} />
          <Route path="/profile" component={ProfileMain} />
          <Route exact path="/order-list">
            {authData ? (
              authData.data.telephone ? (
                <Order />
              ) : (
                <Redirect to="/" />
              )
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/about" component={About} />
          <Route path="/blog" component={Blog} />
            <Route exact path="/blog-detail/:blog_id" component={BlogDetail}/>
          <Route path="/contact" component={Contact} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route component={Error} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  };
}

export default connect(mapStateToProps, null)(App);
