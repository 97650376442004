import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SortBox from "./SortBox";
import Products from "./Products";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    margin: "10px 15px 0 20px",
  },
  textStyle: {
    fontSize: 18,
    color: "#1b1d1e",
    fontWeight: "500",
  },
}));

const RightSection = ({ products, handleLoadMore, filter }) => {
  const classes = useStyles();
  let ProductToCategories = products ? products.ProductToCategories ? products.ProductToCategories.length ? products.ProductToCategories
      : [] : [] : []
  let CategoryDescriptions = products ? products.CategoryDescriptions : [];
  let catagory = CategoryDescriptions ? CategoryDescriptions.length ? CategoryDescriptions[0].name : '' : ''
  let filterProduct = filter ? filter.length ? filter : [] : []
  let totalProduct = filterProduct ? filterProduct.length ? filterProduct.length :
      ProductToCategories.length : 0


  return (
    <div className={classes.root}>
      <Typography className={classes.textStyle}>{catagory}</Typography>

      <SortBox totalProduct={totalProduct}/>
      <Products products={products} filterProduct={filterProduct} handleLoadMore={handleLoadMore}/>
    </div>
  );
};

export default RightSection;
