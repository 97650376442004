import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, LinearProgress, TextField } from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import FileUpload from "./FileUpload";
import {
  clearCart,
  clearCheckout,
  setCheckout,
} from "../../redux/actions/cart";
import { clearOrder, createOrder, fileUpload } from "../../redux/actions/order";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    zIndex: 999998,
    padding: "0 5%",
    "& .MuiInputBase-root": {
      fontSize: "15px",
      color: "#1ec3aa",
      borderBottom: "none",
    },
  },
  roundBtn: {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid rgba(0,0,0,0.5)",
    color: "rgba(0,0,0,0.5)",
    fontSize: "14px",
    marginBottom: "5px",
    fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
  },
}));

let calculatePrice = (carts) => {
  let price = 0;

  if (carts.length) {
    let len = carts.length;
    for (let i = 0; i < len; i++) {
      let calc =
        carts[i].count *
        parseFloat(
          carts[i].cartItem.price
            ? carts[i].cartItem.price
            : carts[i].cartItem.Product[0].price
        );

      price += calc;
    }
    return price;
  } else {
    return 0;
  }
};

const MultiPrescription = ({
  handleClose,
  fromOrder,
  addressLine1,
  addressLine2,
  paymentOption1,
  cart,
  authData,
  token,
  clearCart,
  setCheckout,
  orderData,
  orderDataLoading,
  orderDataErr,
  location,
  checkout,
  createOrder,
  clearOrder,
  fileUpload,
  fileData,
  logout,
  fileDataLoading,
  fileDataErr,
}) => {
  const classes = useStyles();
  const [newFilesInfo, setNewFilesInfo] = React.useState({
    prescripFiles: [],
  });
  let [message, setMessage] = useState("");
  const [upload, setUpload] = React.useState("");
  const [uploadErr, setUploadErr] = React.useState("");
  const [addressErr, setAddressErr] = React.useState("");

  let price = calculatePrice(cart);

  let orderItem = [];
  cart.map((item) => {
    let cartItem = item ? (item.cartItem ? item.cartItem : "") : "";
    let count = item ? (item.count ? item.count : "") : "";
    let productData = cartItem
      ? cartItem.Product
        ? cartItem.Product
        : []
      : [];
    let product = productData ? (productData[0] ? productData[0] : "") : "";
    let ProductDescriptions = product
      ? product.ProductDescriptions
        ? product.ProductDescriptions
        : ""
      : "";

    //part2
    let detailProductDescriptions = cartItem
      ? cartItem.ProductDescriptions
        ? cartItem.ProductDescriptions
        : []
      : [];
    let description = detailProductDescriptions
      ? detailProductDescriptions
      : "";
    let data = {
      product_id: cartItem.product_id,
      name: ProductDescriptions.name
        ? ProductDescriptions.name
        : description.name,
      model: product.model ? product.model : cartItem.model,
      quantity: count,
      price: product.price ? product.price : cartItem.price,
      total: (count * (product.price ? product.price : cartItem.price)).toFixed(
        2
      ),
      tax: "",
      reward: "",
    };

    orderItem.push(data);
  });

  let handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const updateUploadedFiles = (files) =>
    setNewFilesInfo({ ...newFilesInfo, prescripFiles: files });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fromOrder === false) {
      newFilesInfo.prescripFiles.map((file) => {
        let data = {
          upload_pres: file,
          customer_id: authData.data.userId,
          customer_auth: authData?.data?.token,
          note: message,
        };
        fileUpload(data, authData?.data?.token);
      });
    } else {
      setCheckout(cart);
      let data = {
        customer_id: authData.data.userId,
        customer_auth: "authData.data.token",
        firstname: authData.data.firstname,
        lastname: authData.data.lastname,
        email: authData.data.telephone + "@email.com",
        telephone: authData.data.telephone,
        payment_firstname: paymentOption1,
        payment_lastname: paymentOption1,
        payment_company: "Netmed24",
        payment_address_1: addressLine1
          ? addressLine1.length
            ? addressLine1
            : ""
          : "",
        payment_address_2: "",
        payment_city: "Dhaka",
        payment_postcode: "1200",
        payment_method: paymentOption1,
        payment_code: "1200",

        shipping_firstname: authData.data.firstname,
        shipping_lastname: authData.data.lastname,
        shipping_company: "",
        shipping_address_1: addressLine1
          ? addressLine1.length
            ? addressLine1
            : ""
          : "",
        shipping_address_2: addressLine2
          ? addressLine2.length
            ? addressLine2
            : ""
          : "",
        shipping_city: "Dhaka",
        shipping_postcode: "1200",

        comment: paymentOption1,
        shipping: 0,
        sub_total: price,
        total: price,
        orderItem: orderItem,
      };
      createOrder(data, authData?.data?.token);
    }
  };

  let handleCloseNow = () => {
    setUpload("");
    handleClose();
    clearOrder();
  };

  useEffect(() => {
    if (orderData) {
      if (orderData.status === 200) {
        clearCart();
        newFilesInfo.prescripFiles.map((file) => {
          let data = {
            upload_pres: file,
            customer_id: authData.data.userId,
            customer_auth: authData?.data?.token,
            order_id: orderData
              ? orderData.data
                ? orderData.data.orderData
                  ? orderData.data.orderData.order_id
                  : 0
                : 0
              : 0,
            note: message,
          };
          fileUpload(data, authData?.data?.token);
        });
      }
    }
    if (orderDataErr) {
      logout();
      setAddressErr(orderDataErr.message);
    }
  }, [orderData, orderDataErr]);

  useEffect(() => {
    if (fileData) {
      if (fileData.status === 200) {
        setUpload(fileData.message + " A customer care officer will contact you shortly.");
      } else {
        setUploadErr(fileData.message);
      }
    }
    if (fileDataErr) {
      logout();
      setUploadErr(fileDataErr.message);
    }
  }, [fileUpload, fileDataErr]);

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <FileUpload
              accept=".jpg,.png,.jpeg,.pdf"
              label="Profile Image(s)"
              multiple
              updateFilesCb={updateUploadedFiles}
            />
            <Button
              onClick={handleCloseNow}
              style={{ position: "absolute", right: 6, top: 6 }}
            >
              <span className={classes.roundBtn}>
                <ClearRoundedIcon />
              </span>
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} style={{ margin: "20px 0" }}>
          {fileDataLoading === true ? (
            <center>
              <div style={{ marginTop: 20 }}>
                <LinearProgress />
              </div>
            </center>
          ) : null}
          {fileData ? (
            fileData.message.length ? (
              <div
                style={{
                  color: "#066714",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {fileData.message + " A customer care officer will contact you shortly."}
              </div>
            ) : null
          ) : null}
          {uploadErr ? (
            uploadErr.length ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {uploadErr}
              </div>
            ) : null
          ) : null}
          <form onSubmit={handleSubmit} style={{ border: "2px solid #AEAEB0" }}>
            <div style={{ minHeight: "250px", padding: "10px" }}>
              <TextField
                id="standard-textarea"
                placeholder="Write Your message..."
                multiline
                fullWidth
                onChange={handleMessage}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>

            <div>
              <Button
                type="submit"
                disabled={
                  newFilesInfo.prescripFiles
                    ? newFilesInfo.prescripFiles.length
                      ? false
                      : true
                    : true
                }
                style={{
                  backgroundColor: newFilesInfo.prescripFiles
                    ? newFilesInfo.prescripFiles.length
                      ? "#1ec3aa"
                      : "#7a7979"
                    : "#7a7979",
                  color: "#fff",
                  borderRadius: "0",
                  marginRight: "0",
                  marginLeft: "auto",
                  display: "block",
                }}
              >
                Done
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.cartReducer.cart,
    authData: state.authReducer.authData,
    token: state.authReducer.token,
    checkout: state.cartReducer.checkout,
    orderData: state.oderReducer.orderData,
    orderDataLoading: state.oderReducer.orderDataLoading,
    orderDataErr: state.oderReducer.orderDataErr,
    fileData: state.oderReducer.fileData,
    fileDataLoading: state.oderReducer.fileDataLoading,
    fileDataErr: state.oderReducer.fileDataErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCart: () => dispatch(clearCart()),
    setCheckout: (data) => dispatch(setCheckout(data)),
    createOrder: (data, token) => dispatch(createOrder(data, token)),
    clearCheckout: () => dispatch(clearCheckout()),
    clearOrder: () => dispatch(clearOrder()),
    fileUpload: (data, token) => dispatch(fileUpload(data, token)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiPrescription);
