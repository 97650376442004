export const HOME = {
  BRAND_DATA: {
    MAIN: "BRAND_DATA",
    SUCCESS: "BRAND_DATA_SUCCESS",
    FAILURE: " BRAND_DATA_FAILURE",
  },
  TODAY_PRODUCT_DATA: {
    MAIN: "TODAY_PRODUCT_DATA",
    SUCCESS: "TODAY_PRODUCT_DATA_SUCCESS",
    FAILURE: "TODAY_PRODUCT_DATA_FAILURE",
  },
  ESSENTIAL_PRODUCT_DATA: {
    MAIN: "ESSENTIAL_PRODUCT_DATA",
    SUCCESS: "ESSENTIAL_PRODUCT_DATA_SUCCESS",
    FAILURE: "ESSENTIAL_PRODUCT_DATA_FAILURE",
  },
  FEATURE_PRODUCT_DATA: {
    MAIN: "FEATURE_PRODUCT_DATA",
    SUCCESS: "FEATURE_PRODUCT_DATA_SUCCESS",
    FAILURE: "FEATURE_PRODUCT_DATA_FAILURE",
  },
  FEATURE_BRAND_DATA: {
    MAIN: "FEATURE_BRAND_DATA",
    SUCCESS: "FEATURE_BRAND_DATA_SUCCESS",
    FAILURE: "FEATURE_BRAND_DATA_FAILURE",
  },
  CONCERN_PRODUCT_DATA: {
    MAIN: "CONCERN_PRODUCT_DATA",
    SUCCESS: "CONCERN_PRODUCT_DATA_SUCCESS",
    FAILURE: "CONCERN_PRODUCT_DATA_FAILURE",
  },
  EXP_CONCERN_DATA: {
    MAIN: "EXP_CONCERN_DATA",
    SUCCESS: "EXP_CONCERN_DATA_SUCCESS",
    FAILURE: "EXP_CONCERN_DATA_FAILURE",
  },
  SPECIAL_DATA: {
    MAIN: "SPECIAL_DATA",
    SUCCESS: "SPECIAL_DATA_SUCCESS",
    FAILURE: "SPECIAL_DATA_FAILURE",
  },
};
