import React from "react";
import styled from "styled-components";
import Image from "../../../../components/Image";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {Link} from "react-router-dom";
import config from "../../../../config/index";

import {connect} from "react-redux";
import {
    addItemToCart,
    addItemToCartServer,
    addItemToWishlist,
    updateItemToCartServer,
} from "../../../../redux/actions/cart";
import querystring from "querystring";

let checkCartAvailable = (cart, id) => {
    if (cart) {
        if (cart.length) {
            let found = cart.find((item) => item.cartItem.product_id === id);
            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

let checkWishlistAvailable = (wishlist, id) => {
    if (wishlist) {
        if (wishlist.length) {
            let found = wishlist.find((item) => item.product_id === id);

            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

const Product = ({
                     item,
                     addItemToCart,
                     addItemToWishlist,
                     addItemToCartServer,
                     updateItemToCartServer,
                     cus_cart,
                     authData,token,
                     cart,
                     wishlist,
                 }) => {
    let productData = item ? (item.Product ? item.Product : []) : [];
    let product = productData ? (productData[0] ? productData[0] : "") : "";
    let ProductDescriptions = product
        ? product.ProductDescriptions
            ? product.ProductDescriptions
            : ""
        : "";

    let ProductImages = product
        ? product.ProductImages
            ? product.ProductImages
            : []
        : [];
    let images = ProductImages ? (ProductImages[0] ? ProductImages[0] : "") : "";
    let isPharma = product.is_pharma_product === 1
        ? "pharma"
        : "noPharma";

    let addToCart = (item) => {
        addItemToCart(item);
      if (authData) {
        let data = querystring.stringify({
          customer_id: authData?.data?.userId,
          customer_auth: 'customer_auth',
          product_id: item.product_id,
          quantity: 1,
          option: 'text me'
        })
        addItemToCartServer(data, authData?.data?.token)
        // if (cus_cart) {
        //   let data = querystring.stringify({
        //     customer_id: authData?.data?.userId,
        //     customer_auth: 'customer_auth',
        //     cart_id: cus_cart?.data?.cart_id,
        //     product_id: item.product_id,
        //     quantity: 1,
        //     option: 'text me'
        //   })
        //   updateItemToCartServer(data,authData?.data?.token)
        // } else {
        //
        // }
      }
    };

    let addToWishlist = (item) => {
        addItemToWishlist(item);
    };

    let isCart = checkCartAvailable(cart, item.product_id);
    let isWish = checkWishlistAvailable(wishlist, item.product_id);

    return (
        <ProductCardWrapper>
            <Link to={`/details/${item.product_id}`}>
                <ProductImageWrapper>
                    <Image
                        url={config.IMG_BASE + (item ? item.image ? item.image : product.image : product.image)}
                        isPharma={isPharma}
                        className="product-image"
                        style={{position: "relative"}}
                        alt={"Product"}
                    />
                    {/*<DiscountPercent>*/}
                    {/*  <img src={discountImg} alt="discount" />*/}
                    {/*  <span> Save 10%</span>*/}
                    {/*</DiscountPercent>*/}
                </ProductImageWrapper>
            </Link>
            <ProductInfo>
                <h3 className="product-title">{item.ProductDescriptions ? item.ProductDescriptions.name : ProductDescriptions.name}</h3>
                <div className="product-meta">
                    <div className="productPriceWrapper">
                        {/*<span className="discountedPrice">*/}
                        {/*   TK {product.price}*/}
                        {/*</span>*/}
                        <span className="product-price">
              TK {parseFloat(item.price ? item.price : product.price)}
            </span>
                    </div>
                    {/*<div>*/}
                    {/*    <Button*/}
                    {/*        className="cart-button"*/}
                    {/*        variant="secondary"*/}
                    {/*        borderRadius={6}*/}
                    {/*    >*/}
                    {/*        <ButtonText>*/}
                    {/*            +*/}
                    {/*        </ButtonText>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                    {isCart ? (
                        <div id="wrapper" className="is-cart">
                            <button type="button" onClick={() => addToCart(item)}>
                                <ShoppingCartIcon/> &nbsp; &nbsp;ADD TO CART
                            </button>
                        </div>
                    ) : (
                        <div id="wrapper">
                            <button type="button" onClick={() => addToCart(item)}>
                                <ShoppingCartIcon/> &nbsp; &nbsp;ADD TO CART
                            </button>
                        </div>
                    )}
                </div>
            </ProductInfo>
        </ProductCardWrapper>
    );
};

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

const ProductImageWrapper = styled.div`
  height: 220px;
  background-color: #ffffff;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    object-fit: cover;
    transition: all 1s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
      filter: contrast(140%);
    }
  }

  @media (max-width: 767px) {
    height: 145px;
  }
`;

const DiscountPercent = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #000;
  width: 75px;
  height: 25px;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 2;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    text-align: left;
    object-fit: cover;
  }

  span {
    position: absolute;
    left: 4px;
    transform: rotate(180deg);
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
    min-height: 123px;
  }

  .product-title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    //margin: -10px 0 7px 0;
    width: 100%;
    text-align: center;
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  .product-weight {
    text-align: center;
    margin-top: 14px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #898686;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .product-meta {
    margin-top: 8px;
    //display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-height: 32px;
    }

    .productPriceWrapper {
      position: relative;
      margin-bottom: 8px;

      .product-price {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #b61026;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #d98b99;
        padding: 0 5px;
        margin-right: 15px;
        text-decoration: line-through;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    #wrapper {
      width: 100%;
      height: 100%;
    }

    button {
      height: 40px;
      position: relative;
      width: 130px;
      border-radius: 4px;
      font-size: 12px;
      background: linear-gradient(90deg, #1f8184 30%, rgb(30 195 170) 30% 100%);
      color: #fff;
      border: none;
    }

    .is-cart {
      button {
        height: 40px;
        position: relative;
        width: 130px;
        border-radius: 4px;
        font-size: 12px;
        background: linear-gradient(90deg,
        #dc3545 30%,
        rgb(30 195 170) 30% 100%);
        color: #fff;
        border: none;
      }
    }

    .cart-button {
      border: 1px solid #000000;
      border-radius: 4px;
      height: 24px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      font-weight: 700;
      color: #000;
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8%;
      }

      .btn-text {
        @media (max-width: 767px) {
          display: block;
        }
      }

      &:hover {
        color: #ffffff;
        background-color: #009e7f;
        border-color: #009e7f;
      }

      svg {
        fill: currentColor;
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }

      button {
        width: 130px;
        height: 40px;
      }

      .incBtn {
        top: 0;
        justify-content: center;
      }

      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }

      input[type="number"] {
        left: 0;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: block;
  }
`;

function mapStateToProps(state) {
    return {
        cart: state.cartReducer.cart,
        wishlist: state.cartReducer.wishlist,
        cus_cart: state.cartReducer.cus_cart,
        authData: state.authReducer.authData,
        token: state.authReducer.token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
        addItemToCartServer: (data,token) => dispatch(addItemToCartServer(data, token)),
        updateItemToCartServer: (data,token) => dispatch(updateItemToCartServer(data,token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
