import React, {useEffect} from 'react';
import Banner from "./components/Banner";
import Details from "./components/Details";
import ShareOnSocialMedia from "./components/ShareOnSocialMedia";
import {Container} from "react-bootstrap";
import styled from "styled-components";
import {Divider} from "@material-ui/core";
import {setBlogDetails, setBlogList} from "../../redux/actions/blog";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";

const BlogDetail = (props) =>{
    let { blog_id } = props.match.params;
    useEffect(()=>{
        window.scrollTo(0, 0);
        let params = {
            [ApiParams.post_id]: blog_id,
        }
        props.setBlogDetails(params)
    },[])

    return(
        <DetailsDiv>
            <Banner/>
            <Details blogDetail={props.blogDetail}/>
            <Container><Divider style={{borderColor: "#fff",margin: "40px 0 31px 0"}}/></Container>
            <ShareOnSocialMedia blogDetail={props.blogDetail}/>
        </DetailsDiv>
    )
};
const DetailsDiv = styled.div`
  background: #F5F5F5;
`

function mapStateToProps(state) {
    return {
        blogDetail: state.blogReducer.blogDetail,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBlogDetails: (params) => dispatch(setBlogDetails(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);