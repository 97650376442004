import React, { useEffect } from "react";
import Logo from "../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Grid, LinearProgress } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { logout, signUp } from "../../redux/actions/auth";
import { connect } from "react-redux";
import querystring from "querystring";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
    paddingBottom: "20px",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  resize: {
    fontSize: "12px",
  },
}));

const SignUpPassword = ({
  handleMove,
  moveToSignUp,
  otpData,
  codeVerify,
  signData,
  signDataLoading,
  signDataErr,
  signUp,
  logout,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [lastName, setLastName] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [password, setPassword] = React.useState();
  const [passwordConfirm, setPasswordConfirm] = React.useState();
  const [errorTxt, setErrorTxt] = React.useState(null);

  const [signErr, setSignErr] = React.useState(null);

  const moveTo = () => {
    handleMove("loginForm");
    logout();
  };

  const change = (name, e) => {
    e.persist();
    if (name == "firstName") {
      setFirstName(e.target.value);
    }
    if (name == "lastName") {
      setLastName(e.target.value);
    }
    if (name === "password") {
      setPassword(e.target.value);
    }
    if (name === "password_confirmation") {
      setPasswordConfirm(e.target.value);

      if (password !== e.target.value) {
        setErrorTxt("Passwords do not match");
      } else {
        setErrorTxt(null);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!errorTxt) {
      let data = querystring.stringify({
        firstname: firstName,
        lastname: lastName,
        telephone: codeVerify ? codeVerify.data.telephone : "",
        password: password,
        verify_code: codeVerify ? codeVerify.data.verify_code : "",
      });
      //console.log(data)
      signUp(data);
    }
  };

  useEffect(() => {
    if (signData) {
      handleMove("loginForm");
      logout();
    }
    if (signDataErr) {
      setSignErr(signDataErr.message);
    }
  }, [signData, signDataErr]);

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div className={classes.bigText}>Create your account</div>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="firstName"
                onChange={change.bind(null, "firstName")}
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={change.bind(null, "lastName")}
                autoFocus
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                onChange={change.bind(null, "password")}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password_confirmation"
                onChange={change.bind(null, "password_confirmation")}
                label="Confirm Password"
                type="password"
                id="password_confirmation"
                autoComplete="current-password_confirmation"
                error={errorTxt}
                helperText={errorTxt}
              />
            </Grid>
          </Grid>

          {signErr ? (
            signErr.length ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {signErr}
              </div>
            ) : null
          ) : null}

          <div className={classes.lButton}>
            <center style={{ paddingTop: "20px" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.orderBtn}
                style={{ backgroundColor: "#32aeb1", color: "#fff" }}
              >
                Sign Up
              </Button>
            </center>
            <center
              style={{
                paddingTop: "12px",
                color: "#090f47",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Already have an account?
              <Link onClick={moveTo}> &nbsp; Login</Link>
            </center>
          </div>
        </form>

        {signDataLoading === true ? (
          <center>
            <div style={{ marginTop: 20 }}>
              <LinearProgress />
            </div>
          </center>
        ) : null}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    otpData: state.authReducer.otpData,
    codeVerify: state.authReducer.codeVerify,
    signData: state.authReducer.signData,
    signDataLoading: state.authReducer.signDataLoading,
    signDataErr: state.authReducer.signDataErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signUp: (data) => dispatch(signUp(data)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPassword);
