import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Title from '../../../components/Title'
import Divider from '@material-ui/core/Divider';

let OrderList = props => {
    let {orderList, active, setActive, statusCheck, statusCheckOnline} = props

    let orders = orderList ?
        orderList.data ?
            orderList.data.length ?
                orderList.data :
                [] :
            [] :
        []

    let handleActive = id => {
        setActive(id)
    }

    return (
        <StyledList>
            <List className="list" component="nav" aria-label="main mailbox folders">
                {orders ? orders.length ? orders.map((item, key) => {
                    let status = ''
                    if (item.payment_method === 'cash-on-delivery') {
                        status = statusCheck(item.order_status_id)
                    } else {
                        status = statusCheckOnline(item.order_status_id)
                    }

                    return (
                        <>
                            <ListItem
                                button
                                selected={active === key}
                                onClick={() => handleActive(key)}
                                className="list-text"
                            >
                                <Title
                                    text={`Order ID#${item.order_id}`}
                                    color={'#313132'}
                                    fontSize={16}
                                    lineHeight={24}
                                    fontWeight={'500'}
                                    textAlign={'left'}
                                />
                                <Title
                                    text={status.name}
                                    color={'#313132'}
                                    fontSize={10}
                                    lineHeight={14}
                                    fontWeight={'500'}
                                    textAlign={'left'}
                                />
                            </ListItem>
                            <Divider />
                        </>
                    )
                }) : null : null}
            </List>
        </StyledList>
    )
}

let StyledList = styled.div`
  .list-text {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 63px;
  }
  .list {
    width: 100%;
  }
  @media(max-width: 1280px){
    .title {
      font-size: 10px;
    }
  }
`

function mapStateToProps(state) {
    return {
        orderList: state.oderReducer.orderList,
    }
}

export default connect(mapStateToProps)(OrderList)
