export const BLOG = {
    BLOG_LIST: {
        MAIN: 'BLOG_LIST',
        SUCCESS: 'BLOG_LIST_SUCCESS',
        FAILURE: 'BLOG_LIST_FAILURE'
    },
    BLOG_LIST_DETAIL: {
        MAIN: 'BLOG_LIST_DETAIL',
        SUCCESS: 'BLOG_LIST_DETAIL_SUCCESS',
        FAILURE: 'BLOG_LIST_DETAIL_FAILURE'
    }
}