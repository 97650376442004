import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosServices';
import {ApiServices} from '../../../network/ApiServices';
import {AUTH} from "../../constants/auth";
import {DETAILS} from "../../constants/details";

function* sendOTP(actions) {
    try {
        let result = yield call(
            AxiosServices.postForm,
            ApiServices.SEND_OTP,
            actions.data
        )

        yield put({
            type: AUTH.SET_OTP.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: AUTH.SET_OTP.FAILURE,
            result: err.response.data
        })
    }
}

function* verifyOTP(actions) {
    try {
        let result = yield call(
            AxiosServices.postForm,
            ApiServices.CODE_VERIFY,
            actions.data
        )

        yield put({
            type: AUTH.VERIFY_CODE.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: AUTH.VERIFY_CODE.FAILURE,
            result: err.response.data
        })
    }
}

function* login(actions) {
    try {
        let result = yield call(
            AxiosServices.postForm,
            ApiServices.USER_LOGIN,
            actions.data
        )

        yield put({
            type: AUTH.SET_LOGIN.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: AUTH.SET_LOGIN.FAILURE,
            result: err.response.data
        })
    }
}

function* signUp(actions) {
    try {
        let result = yield call(
            AxiosServices.postForm,
            ApiServices.USER_SIGN_UP,
            actions.data
        )

        yield put({
            type: AUTH.SET_SIGN_UP.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: AUTH.SET_SIGN_UP.FAILURE,
            result: err.response.data
        })
    }
}

function* fetchAllAddressData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.SAVE_ADDRESS,
            actions.params, actions.token
        ); //true when Json Server
        yield put({
            type: AUTH.ALL_ADDRESS.SUCCESS,
            result: result.data,
            message: 'Data Fetched'
        })
    } catch (err) {
        Logger(err);
        yield put({
            type: AUTH.ALL_ADDRESS.FAILURE,
            result: err.response.data
        })
    }
}

function* forgetOTPSend(actions) {
    try {
        let result = yield call(
            AxiosServices.putEncoded,
            ApiServices.FORGET_OTP,
            actions.data
        )

        yield put({
            type: AUTH.FORGET_CODE.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: AUTH.FORGET_CODE.FAILURE,
            result: err.response.data
        })
    }
}

function* reqPasswordChange(actions) {
    try {
        let result = yield call(
            AxiosServices.putEncoded,
            ApiServices.FORGET_VERIFY,
            actions.data
        )

        yield put({
            type: AUTH.FORGET_PASS_REQ.SUCCESS,
            result: result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: AUTH.FORGET_PASS_REQ.FAILURE,
            result: err.response.data
        })
    }
}

function* addressUpdate(actions) {
    try {
        let result = yield call(
            AxiosServices.putEncoded,
            ApiServices.UPDATE_ADDRESS,
            actions.data, actions.token
        )

        yield put({
            type: AUTH.UPDATE_ADDRESS.SUCCESS,
            result: result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: AUTH.UPDATE_ADDRESS.FAILURE,
            result: err.response.data
        })
    }
}

function* profileUpdate(actions) {
    try {
        let result = yield call(
            AxiosServices.putEncoded,
            ApiServices.UPDATE_PROFILE,
            actions.data, actions.token
        )

        yield put({
            type: AUTH.UPDATE_PROFILE.SUCCESS,
            result: result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: AUTH.UPDATE_PROFILE.FAILURE,
            result: err.response.data
        })
    }
}

function queryStringToJSON(queryString) {
    if(queryString.indexOf('?') > -1){
        queryString = queryString.split('?')[1];
    }
    let pairs = queryString.split('&');
    let result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
}

function* addressDelete(actions) {
    try {
        let result = yield call(
            AxiosServices.removeEncoded,
            ApiServices.DELETE_ADDRESS,
            actions.data, actions.token
        )

        yield put({
            type: AUTH.DELETE_ADDRESS.SUCCESS,
            result: queryStringToJSON(actions.data)

        })
    } catch(err) {
        Logger(err)
        yield put({
            type: AUTH.DELETE_ADDRESS.FAILURE,
            result: err.response.data
        })
    }
}

function* logout() {
    yield put({
        type: AUTH.LOGOUT_USER.SUCCESS
    })
}

export default function* authSagas() {
    yield takeLatest(AUTH.SET_OTP.MAIN, sendOTP)
    yield takeLatest(AUTH.VERIFY_CODE.MAIN, verifyOTP)
    yield takeLatest(AUTH.SET_LOGIN.MAIN, login)
    yield takeLatest(AUTH.SET_SIGN_UP.MAIN, signUp)
    yield takeLatest(AUTH.ALL_ADDRESS.MAIN, fetchAllAddressData)
    yield takeLatest(AUTH.FORGET_CODE.MAIN, forgetOTPSend)
    yield takeLatest(AUTH.FORGET_PASS_REQ.MAIN, reqPasswordChange)
    yield takeLatest(AUTH.UPDATE_ADDRESS.MAIN, addressUpdate)
    yield takeLatest(AUTH.UPDATE_PROFILE.MAIN, profileUpdate)
    yield takeLatest(AUTH.DELETE_ADDRESS.MAIN, addressDelete)
    yield takeLatest(AUTH.LOGOUT_USER.MAIN, logout)
}
