import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import Categories from "./Categories";
import FilterByPrice from "./FilterByPrice";
import Pharmacutical from "./Pharmacutical";
import FilterListIcon from '@material-ui/icons/FilterList';

const MobileFilter = ({marginBottom, products, childCategoryData, setCategoryID,
                          setStart,setEnd,finalManufact,setManufacture
}) => {
    const [priceView, setPrice] = useState(false);
    const [categoryView, setCategory] = useState(false);
    const [pharmaceuticalView, setPharmaceutical] = useState(false);

    let CategoryDescriptions = products ? products.CategoryDescriptions : [];
    let catagory = CategoryDescriptions ? CategoryDescriptions.length ? CategoryDescriptions[0].name : '' : ''

    let handleViewChange = (name, e) => {
        if (name === "Category") {
            setCategory(!categoryView)
            setPrice(false)
            setPharmaceutical(false)
        }
        if (name === "Price") {
            setCategory(false)
            setPrice(!priceView)
            setPharmaceutical(false)
        }
        if (name === "Pharmaceutical") {
            setCategory(false)
            setPrice(false)
            setPharmaceutical(!pharmaceuticalView)
        }
    }

    return (
        <StyledFilter>
            <Container className="mobile-view" style={{marginBottom: `${marginBottom}`}}>
                <Row>
                    <Col>
                        <div
                            style={{ display: "flex", marginTop: 30, alignItems: "center" }}
                        >
                            <div className="text-filter">Shop for {catagory}</div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: 30,
                                alignItems: "center",
                                overflow: "auto",
                            }}
                        >
                            <div
                                className="text-filter-menu"
                                onClick={handleViewChange.bind(null, "Category")}
                            >
                              <FilterListIcon style={{height: 14, width: 14, marginRight: 5}}/>Category
                            </div>
                            <div
                                className="text-filter-menu"
                                onClick={handleViewChange.bind(null, "Price")}
                            >
                                <FilterListIcon style={{height: 14, width: 14, marginRight: 5}}/>Price
                            </div>
                            <div
                                className="text-filter-menu"
                                onClick={handleViewChange.bind(null, "Pharmaceutical")}
                            >
                                <FilterListIcon style={{height: 14, width: 14, marginRight: 5}}/>Pharmaceutical
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        categoryView === true ? (
                            <Col xs={12}>
                                <div style={{ marginTop: 24 }}>
                                    <Categories childCategoryData={childCategoryData} setCategoryID={setCategoryID}/>
                                </div>
                            </Col>
                        ) : null
                    }
                    {
                        priceView === true ? (
                            <Col xs={12}>
                                <div style={{ marginTop: 24 }}>
                                    <FilterByPrice setStart={setStart} setEnd={setEnd}/>
                                </div>
                            </Col>
                        ) : null
                    }
                    {
                        pharmaceuticalView === true ? (
                            <Col xs={12}>
                                <div style={{ marginTop: 24 }}>
                                    <Pharmacutical finalManufact={finalManufact} setManufacture={setManufacture}/>
                                </div>
                            </Col>
                        ) : null
                    }
                </Row>
            </Container>
        </StyledFilter>
    );
};

const StyledFilter = styled.div`
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .MuiInputBase-root {
    color: #313132;
  }

  .MuiInputBase-input {
    font-family: Poppins, sans-serif;
    font-size: 8px;
  }

  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }

  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    pointer-events: none;
  }

  .column-cl {
    margin-bottom: 30px;
  }
  .text-filter {
    font-weight: 600;
    font-size: 14px;
    color: #313132;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
  }

  .text-filter-menu {
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 16px 8px 16px;
    background-color: #d8d8d8;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 12px;
  }
`;

export default MobileFilter;
