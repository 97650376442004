import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../../components/Title";
import ContactField from "./ContactField";
import InformationField from "./InformationField";
import CardContent from "@material-ui/core/CardContent";
import {Card} from "@material-ui/core";
import querystring from "querystring";

const UpdateAccount =(props) =>{
    const classes = useStyles();
    const { profileData } = props;

    const [personal, setPersonal] = useState(false);
    const [contact, setContact] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (profileData) {
            if (profileData.data.firstname) setFirstName(profileData.data.firstname);
            if (profileData.data.lastname) setLastName(profileData.data.lastname);
            if (profileData.data.telephone) setPhone(profileData.data.telephone);
        }
    }, []);

    const handleForgetPassword = () => {
        props.moveToRecoverPhone();
    };

    const handlePersonal = () => {
        setPersonal(!personal);
    };

    const handleContact = () => {
        setContact(!contact);
    };

    const handleSubmit = () => {
        // console.log(firstName)
        // console.log(lastName)
        //console.log(props.token)

        let data = querystring.stringify({
            firstname: firstName,
            lastname: lastName
        })

        props.updateProfile(data, props.token)
    };

    const handleFirstName = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastName = (e) => {
        setLastName(e.target.value);
    };

    const handlePhone = (e) => {
        setPhone(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    return(
        <div className={classes.main}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <div className="edit-address">
                        <Title
                            text={"Personal Information"}
                            color={"#313132"}
                            fontSize={16}
                            lineHeight={24}
                            fontWeight={"500"}
                            textTransform={"uppercase"}
                            fontFamily={"Poppins"}
                        />
                        {personal ? (
                            <div
                                onClick={handlePersonal}
                                style={{
                                    marginLeft: "auto",
                                    backgroundColor: "#313132",
                                    padding: "5px 18px 5px 18px",
                                    borderRadius: "50px",
                                    cursor: "pointer",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#fff",
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Close
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={handlePersonal}
                                style={{
                                    marginLeft: "auto",
                                    backgroundColor: "#EFEFEF",
                                    padding: "5px 18px 5px 18px",
                                    borderRadius: "50px",
                                    cursor: "pointer",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#313132",
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: "Poppins",
                                    }}
                                >
                                    Edit
                                </div>
                            </div>
                        )}
                    </div>
                    {personal ? (
                        <InformationField
                            firstName={firstName}
                            lastName={lastName}
                            handleFirstName={handleFirstName}
                            handleLastName={handleLastName}
                            handleCancel={handlePersonal}
                            handleSubmit={handleSubmit}
                        />
                    ) : (
                        <>
                            <Title
                                text={firstName + " " + lastName}
                                color={"#313132"}
                                fontSize={14}
                                lineHeight={22}
                                fontWeight={"400"}
                                margin={"30px 0 10px 0"}
                                fontFamily={"Poppins"}
                            />
                            {/*<Title*/}
                            {/*    text={"01-06-1995"}*/}
                            {/*    color={"#313132"}*/}
                            {/*    fontSize={14}*/}
                            {/*    lineHeight={22}*/}
                            {/*    fontWeight={"400"}*/}
                            {/*    margin={"10px 0"}*/}
                            {/*    fontFamily={"Poppins"}*/}
                            {/*/>*/}
                            {/*<Title*/}
                            {/*    text={"Male"}*/}
                            {/*    color={"#313132"}*/}
                            {/*    fontSize={14}*/}
                            {/*    lineHeight={22}*/}
                            {/*    fontWeight={"400"}*/}
                            {/*    margin={"10px 0 30px 0"}*/}
                            {/*    fontFamily={"Poppins"}*/}
                            {/*/>*/}
                        </>
                    )}
                    <div className="divider" />

                    <div className="edit-address">
                        <Title
                            text={"Contact Information"}
                            color={"#313132"}
                            fontSize={16}
                            lineHeight={24}
                            fontWeight={"500"}
                            textTransform={"uppercase"}
                            fontFamily={"Poppins"}
                        />
                        {/*{contact ? (*/}
                        {/*    <div*/}
                        {/*        onClick={handleContact}*/}
                        {/*        style={{*/}
                        {/*            marginLeft: "auto",*/}
                        {/*            backgroundColor: "#313132",*/}
                        {/*            padding: "5px 18px 5px 18px",*/}
                        {/*            borderRadius: "50px",*/}
                        {/*            cursor: "pointer",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                color: "#fff",*/}
                        {/*                fontSize: 12,*/}
                        {/*                fontWeight: 400,*/}
                        {/*                fontFamily: "Poppins",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Close*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*) : (*/}
                        {/*    <div*/}
                        {/*        onClick={handleContact}*/}
                        {/*        style={{*/}
                        {/*            marginLeft: "auto",*/}
                        {/*            backgroundColor: "#EFEFEF",*/}
                        {/*            padding: "5px 18px 5px 18px",*/}
                        {/*            borderRadius: "50px",*/}
                        {/*            cursor: "pointer",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                color: "#313132",*/}
                        {/*                fontSize: 12,*/}
                        {/*                fontWeight: 400,*/}
                        {/*                fontFamily: "Poppins",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            Edit*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    {contact ? (
                        <ContactField
                            phone={phone}
                            email={email}
                            handlePhone={handlePhone}
                            handleEmail={handleEmail}
                            handleCancel={handleContact}
                        />
                    ) : (
                        <>
                            <Title
                                text={"+88" + phone}
                                color={"#313132"}
                                fontSize={14}
                                lineHeight={22}
                                fontWeight={"400"}
                                margin={"30px 0 10px 0"}
                                fontFamily={"Poppins"}
                            />
                            {/*<Title*/}
                            {/*    text={email.length ? email : "No Emails available"}*/}
                            {/*    color={"#313132"}*/}
                            {/*    fontSize={14}*/}
                            {/*    lineHeight={22}*/}
                            {/*    fontWeight={"400"}*/}
                            {/*    margin={"10px 0 30px 0"}*/}
                            {/*    fontFamily={"Poppins"}*/}
                            {/*/>*/}
                        </>
                    )}
                    <div className="divider" />

                    <div
                        onClick={handleForgetPassword}
                        style={{
                            marginLeft: "auto",
                            backgroundColor: "#EFEFEF",
                            padding: "5px 18px 5px 18px",
                            borderRadius: "50px",
                            cursor: "pointer",
                            marginTop: "30px",
                            width: "160px",
                            textAlign: 'center'
                        }}
                    >
                        <div
                            style={{
                                color: "#313132",
                                fontSize: 12,
                                fontWeight: 400,
                                fontFamily: "Poppins",
                            }}
                        >
                            Change password
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        margin: "10px 17px",
        [theme.breakpoints.down(767)]: {
            margin: "10px 0",
        },
    },
    root: {
        minWidth: 275,
        marginBottom: 17,
        boxShadow: "0px 0px 10px #0000000D",
    },
    cardContent: {
        padding: "30.5px",
        minHeight: '40vh',
        [theme.breakpoints.down(767)]: {
            paddin: 10,
        },
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

export default UpdateAccount

