import React from "react";
import Logo from "../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ThankLogo from "../../localization/images/thanks.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
    padding: "3px 30px 10px 30px",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  input: {
    fontSize: "12px",
    "&:invalid": {
      border: "red solid 2px",
    },
  },
}));

const VerifySuccess = ({ moveToSignUpPass }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div style={{ padding: "5px 0 10px 0" }}>
          <center>
            <img src={ThankLogo} className={classes.bigImg} />
          </center>
        </div>

        <center>
          <Typography className={classes.bigText}>
            Phone Number Verified
          </Typography>
          <Typography className={classes.smallText}>
            Congretulations, Your phone number has been verified. You can start
            using the app
          </Typography>
        </center>

        <div className={classes.lButton}>
          <center style={{ paddingTop: "20px" }}>
            <Button
              className={classes.orderBtn}
              style={{ backgroundColor: "#32aeb1", color: "#fff" }}
              onClick={moveToSignUpPass}
            >
              CONTINUE
            </Button>
          </center>
        </div>
      </div>
    </div>
  );
};

export default VerifySuccess;
