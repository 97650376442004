import React, {useEffect} from 'react';
import {Card, Container} from "react-bootstrap";
import Story1img from '../../../localization/images/achievements.jpg';

import styled from 'styled-components';
import {darkGreen, lightGreen} from "../../../globalStyleVars";
import {Grid} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {fetchSpecialData} from "../../../redux/actions/home";
import {connect} from "react-redux";
import {setBlogList} from "../../../redux/actions/blog";
import {ApiParams} from "../../../constants/apiParams";

const StoryCards = (props) => {

    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        let params = {
            [ApiParams.page_id]: "1",
        };
        props.setBlogList(params)
    }, []);

    const cardData = [
        {id: 1, imgURL: Story1img, date: "01.02.2021", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
        {id: 2, imgURL: Story1img, date: "01.02.2021", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
        {id: 3, imgURL: Story1img, date: "01.02.2021", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
        {id: 4, imgURL: Story1img, date: "01.02.2021", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
        {id: 5, imgURL: Story1img, date: "01.02.2021", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."},
    ]


    return (
        <StyledDiv>
            <Container>
                <Grid container spacing={3}>
                    {
                        props.blogList && props.blogList.length && props.blogList.map((item, key) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={key} className="card-col">
                                    <Card className="card" onClick={() => {
                                        history.push(`blog-detail/${item?.PostDescription.post_id}`)
                                    }}>
                                        <Card.Img variant="top" className="card-image" src={Story1img}
                                                  alt="Blog Story Image"/>
                                        <Card.Body>
                                            {/*<h4 className="date">{date}</h4>*/}
                                            <h3 className="content">{item?.PostDescription.title}</h3>
                                            <Link className="link">Read More</Link>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </StyledDiv>
    )
};

const StyledDiv = styled.div`
  padding: 60px 0px;
  background: #F5F5F5;

  .container {
    max-width: 95%;
  }

  .card-col {
    .card {
      border-radius: 0;
      border: none;
      cursor: pointer;
      box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.06);

      .card-image {
        width: 100%;

        img {
          padding-top: calc(200 / 370 * 100%);
        }
      }

      .date {
        color: #000000;
        font-size: 12px;
        line-height: 20px;
      }

      .content {
        margin: 10px 0 17px 0;
        font-size: 16px;
        line-height: 22px;
        color: #313132;
      }

      .link {
        color: ${darkGreen};
        transition: 0.5s;
        font-size: 14px;
      }

      &:hover {
        .link {
          color: ${lightGreen};
          text-decoration: underline;
        }
      }
    }

  }
`;

function mapStateToProps(state) {
    return {
        blogList: state.blogReducer.blogList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBlogList: (params) => dispatch(setBlogList(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryCards);
