import { Button, Dialog, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import {
  clearCart,
  clearCheckout,
  setCheckout,
} from "../../../redux/actions/cart";
import { connect } from "react-redux";
import ThankYou from "./ThankYou";
import { createOrder, fetchPayCreate } from "../../../redux/actions/order";
import LoginForm from "../../Login/LoginForm";
import SignUpMobile from "../../Login/SignUpMobile";
import SignUpPassword from "../../Login/SignUpPassword";
import OtpVarificationForm from "../../Login/OtpVarificationForm";
import VerifySuccess from "../../Login/VerifySuccess";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MultiPrescription from "../../MultiPrescription/MultiPrescription";
import querystring from "querystring";
import config from "../../../config";
import RecoverPhone from "../../Login/RecoverPhone";
import ResetPasswordForm from "../../Login/ResetPasswordForm";
import { logout } from "../../../redux/actions/auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0 15%",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  inputRoot: {
    color: "#27ae60",
    fontSize: 10,
    width: "100%",
    backgroundColor: "white",
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "1px solid #f1f1f1",
    borderLeft: "none",
  },
  title: {
    fontSize: 16,
    color: "#32aeb1",
    padding: "6px 0",
  },
  subName: {
    color: "#7d94a6",
    fontSize: 14,
    textAlign: "left",
    padding: "6px 0",
  },
  subValue: {
    color: "#32aeb1",
    fontSize: 14,
    textAlign: "right",
    padding: "6px 0",
  },
  redText: {
    color: "#df1a33",
    fontSize: 14,
    padding: "7px 0",
  },
  orderBtn: {
    backgroundColor: "#32aeb1",
    fontSize: 14,
    color: "#fff",
    textTransform: "none",
    borderRadius: "5vw",
    padding: ".7vw 4.5vw",
    "&:hover": {
      backgroundColor: "rgb(30 195 170)",
    },
    // boxShadow: "5px 10px 18px #dfe3ff",
  },
  applyBtn: {
    backgroundColor: "#32aeb1",
    fontSize: 14,
    color: "#fff",
    textTransform: "none",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    // padding: ".7vw 4.5vw",
  },
}));

let calculatePrice = (carts) => {
  let price = 0;

  if (carts.length) {
    let len = carts.length;
    for (let i = 0; i < len; i++) {
      let calc =
        carts[i].count *
        parseFloat(
          carts[i].cartItem.price
            ? carts[i].cartItem.price
            : carts[i].cartItem.Product[0].price
        );

      price += calc;
    }
    return price;
  } else {
    return 0;
  }
};

const PaymentSummary = ({
  addressLine1,
  addressLine2,
  paymentOption1,
  cart,
  authData,
  token,
  clearCart,
  setCheckout,
  orderData,
  orderDataLoading,
  orderDataErr,
  location,
  checkout,
  logout,
  createOrder,
  paymentCreate,
  paySuccessData,
  paySuccessLoading,
  paySuccessErr,
}) => {
  const classes = useStyles();
  const history = useHistory();

  let price = calculatePrice(cart);

  // Thank You dailog
  const [openThankYou, setOpenThankYou] = React.useState(false);
  const [dailogName, setDailogName] = React.useState();
  const [otpFor, setOtpFor] = React.useState();
  const [addressErr, setAddressErr] = React.useState("");

  let orderItem = [];
  let orderOTC = [];
  cart.map((item) => {
    let cartItem = item ? (item.cartItem ? item.cartItem : "") : "";
    let count = item ? (item.count ? item.count : "") : "";
    let productData = cartItem
      ? cartItem.Product
        ? cartItem.Product
        : []
      : [];
    let product = productData ? (productData[0] ? productData[0] : "") : "";
    let ProductDescriptions = product
      ? product.ProductDescriptions
        ? product.ProductDescriptions
        : ""
      : "";

    let ProductAttributes1 = product
      ? product.ProductAttributes
        ? product.ProductAttributes
        : []
      : [];
    const pOtc1 = ProductAttributes1.find((p) => p.attribute_id === 7);

    //part2
    let detailProductDescriptions = cartItem
      ? cartItem.ProductDescriptions
        ? cartItem.ProductDescriptions
        : []
      : [];
    let description = detailProductDescriptions
      ? detailProductDescriptions
      : "";

    let ProductAttributes2 = cartItem
      ? cartItem.ProductAttributes
        ? cartItem.ProductAttributes
        : []
      : [];
    const pOtc2 = ProductAttributes2.find((p) => p.attribute_id === 7);

    const pOtc = pOtc1?.text ? pOtc1?.text : pOtc2?.text;

    let data = {
      product_id: cartItem.product_id,
      name: ProductDescriptions.name
        ? ProductDescriptions.name
        : description.name,
      model: product.model ? product.model : cartItem.model,
      quantity: count,
      price: product.price ? product.price : cartItem.price,
      total: (count * (product.price ? product.price : cartItem.price)).toFixed(
        2
      ),
      tax: "",
      reward: "",
    };
    orderOTC.push(pOtc);
    orderItem.push(data);
  });

  let otc = orderOTC.find((p) => p === "no");
  //Dailog part
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xl");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const thankYouOpen = (event) => {
    event.preventDefault();
    if (authData) {
      if (price >= config.PRICE_LIMIT) {
        if (addressLine1 || addressLine2) {
          if (otc === "no") {
            handleClickOpen();
            setAddressErr("");
          } else {
            setCheckout(cart);
            let data = {
              customer_id: authData.data.userId,
              customer_auth: authData?.data?.token,
              firstname: authData.data.firstname,
              lastname: authData.data.lastname,
              email: authData.data.telephone + "@email.com",
              telephone: authData.data.telephone,
              payment_firstname: paymentOption1,
              payment_lastname: paymentOption1,
              payment_company: "Netmed24",
              payment_address_1: addressLine1
                ? addressLine1.length
                  ? addressLine1
                  : ""
                : "",
              payment_address_2: "",
              payment_city: "Dhaka",
              payment_postcode: "1200",
              payment_method: paymentOption1,
              payment_code: "1200",

              shipping_firstname: authData.data.firstname,
              shipping_lastname: authData.data.lastname,
              shipping_company: "",
              shipping_address_1: addressLine1
                ? addressLine1.length
                  ? addressLine1
                  : ""
                : "",
              shipping_address_2: addressLine2
                ? addressLine2.length
                  ? addressLine2
                  : ""
                : "",
              shipping_city: "Dhaka",
              shipping_postcode: "1200",

              comment: paymentOption1,
              shipping: 0,
              sub_total: price,
              total: price,
              orderItem: orderItem,
            };
            createOrder(data, authData?.data?.token);
            setAddressErr("");
          }
        } else {
          setAddressErr("Please update shipping address");
        }
      } else {
        setAddressErr(`Please order more than ${config.PRICE_LIMIT} TK`);
      }
    } else {
      setOpenThankYou(true);
      setDailogName("loginForm");
    }
  };

  useEffect(() => {
    if (orderData) {
      if (orderData.status === 200) {
        if (
          orderData.data.orderData.payment_method === "online-payment" &&
          otc !== "no"
        ) {
          const data = querystring.stringify({
            amount: parseFloat(orderData.data.orderData.total),
            customer_id: orderData.data.orderData.customer_id,
            order_id: orderData.data.orderData.order_id,
            cust_name:
              orderData.data.orderData.firstname +
              " " +
              orderData.data.orderData.lastname,
            cust_email: orderData.data.orderData.email,
            cust_add1: orderData.data.orderData.payment_address_1,
            cust_add2: orderData.data.orderData.payment_address_2,
            cust_city: orderData.data.orderData.payment_city,
            cust_state: orderData.data.orderData.payment_city,
            cust_postcode: orderData.data.orderData.payment_postcode,
            cust_phone: orderData.data.orderData.telephone,
            ship_name:
              orderData.data.orderData.shipping_firstname +
              " " +
              orderData.data.orderData.shipping_lastname,
            ship_add1: orderData.data.orderData.shipping_address_1,
            ship_add2: orderData.data.orderData.shipping_address_2,
            ship_city: orderData.data.orderData.shipping_city,
            ship_state: orderData.data.orderData.shipping_city,
            ship_postcode: orderData.data.orderData.shipping_postcode,
          });
          paymentCreate(data, authData?.data?.token);
        } else {
          setOpenThankYou(true);
          setDailogName("thankYou");
          clearCart();
        }
      }
    }
    if (orderDataErr) {
      logout();
      setAddressErr(orderDataErr.message);
    }
  }, [orderData, orderDataErr]);

  useEffect(() => {
    if (paySuccessData) {
      if (paySuccessData?.data?.redirectUrl) {
        if (paySuccessData?.data?.redirectUrl.length) {
          window.location.href = paySuccessData?.data?.redirectUrl;
          clearCart();
        }
      }
    }

    if (paySuccessErr) {
      setAddressErr(paySuccessErr.message);
    }
  }, [paySuccessData, paySuccessErr]);

  const thankYouClose = () => {
    setOpenThankYou(false);
  };

  return (
    <div className={classes.paper}>
      <div style={{ padding: "20px 0", display: "flex" }}>
        {/* <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #f1f1f1",
            borderRight: "none",
            color: "#27ae60",
            fontSize: "10px",
            display: "flex",
            alignItems: "center",
            padding: "0 5px",
          }}
        >
          <LocalOfferOutlinedIcon style={{ fontSize: "1.4vw" }} />
        </div>
        <InputBase
          placeholder={`Apply Coupon Code`}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
        <Button className={classes.applyBtn}>Apply</Button>*/}
      </div>

      <Typography className={classes.title}>Order Summary</Typography>
      <Typography style={{ color: "#7d94a6", fontSize: 12 }}>
        {cart ? (cart.length ? cart.length : "0") : "0"} items on your cart
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Typography className={classes.subName}>Order Total</Typography>
          <Typography className={classes.subName}>Items Discount</Typography>
          <Typography className={classes.subName}>Coupon Discount</Typography>
          <Typography className={classes.subName}>Shipping</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.subValue}>
            TK {price.toFixed(2)}
          </Typography>
          <Typography className={classes.subValue}>TK 00</Typography>
          <Typography className={classes.subValue}>TK 00</Typography>
          <Typography className={classes.subValue}>Free</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Typography className={classes.redText} style={{ textAlign: "left" }}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            className={classes.redText}
            style={{ textAlign: "right" }}
          >
            TK {price.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      <Dialog
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
      >
        <MultiPrescription
          handleClose={handleClose}
          fromOrder={true}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          paymentOption1={paymentOption1}
        />
      </Dialog>

      <center>
        <div
          style={{
            //display: "flex",
            justifyContent: "center",
            paddingTop: "5vw",
            paddingBottom: "2vw",
          }}
        >
          {addressErr ? (
            addressErr.length ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 14,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {addressErr}
              </div>
            ) : null
          ) : null}

          {otc === "no" ? (
            <center>
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                Please upload prescription
              </div>
            </center>
          ) : null}
          {location === "/checkout" ? (
            <div>
              <Button
                onClick={thankYouOpen}
                className={classes.orderBtn}
                disabled={cart ? (cart.length ? false : true) : true}
              >
                {otc === "no" ? "Upload Prescription" : "Confirm Order"}
              </Button>
            </div>
          ) : (
            <div>
              {price >= config.PRICE_LIMIT ? (
                <Link to="/checkout">
                  <Button
                    className={classes.orderBtn}
                    disabled={cart ? (cart.length ? false : true) : true}
                  >
                    {`Place order at TK ${price.toFixed(2)}`}
                  </Button>
                </Link>
              ) : (
                <Button
                  className={classes.orderBtn}
                  disabled={cart ? (cart.length ? false : true) : true}
                >
                  {`Please order more than ${config.PRICE_LIMIT} TK`}
                </Button>
              )}
            </div>
          )}
        </div>
      </center>
      <Dialog
        open={openThankYou}
        //onClose={thankYouClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dailogName === "thankYou" && <ThankYou handleClose={thankYouClose} />}

        {dailogName === "loginForm" && (
          <LoginForm
            handleClose={thankYouClose}
            moveToSignUp={() => setDailogName("signUpMobile")}
            moveToRecoverPhone={() => setDailogName("recoverPhone")}
          />
        )}
        {dailogName === "recoverPhone" && (
          <RecoverPhone
            handleMove={() => setDailogName("otpInput")}
            otpFor={() => setOtpFor("resetPassword")}
          />
        )}
        {dailogName === "signUpMobile" && (
          <SignUpMobile
            handleMove={() => setDailogName("otpInput")}
            otpFor={() => setOtpFor("signUpPassword")}
            moveToLogin={() => setDailogName("loginForm")}
          />
        )}

        {dailogName === "signUpPassword" && (
          <SignUpPassword handleMove={() => setDailogName("loginForm")} />
        )}
        {dailogName === "resetPassword" && (
          <ResetPasswordForm handleMove={() => setDailogName("loginForm")} />
        )}
        {dailogName === "otpInput" && (
          <OtpVarificationForm
            handleMove={(e) => setDailogName(e)}
            otpFor={() => setOtpFor("")}
            pageName={otpFor}
          />
        )}

        {dailogName === "successVerify" && (
          <VerifySuccess
            moveToSignUpPass={() => setDailogName("signUpPassword")}
          />
        )}
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.cartReducer.cart,
    authData: state.authReducer.authData,
    token: state.authReducer.token,
    checkout: state.cartReducer.checkout,
    orderData: state.oderReducer.orderData,
    orderDataLoading: state.oderReducer.orderDataLoading,
    orderDataErr: state.oderReducer.orderDataErr,
    paySuccessData: state.oderReducer.paySuccessData,
    paySuccessLoading: state.oderReducer.paySuccessLoading,
    paySuccessErr: state.oderReducer.paySuccessErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCart: () => dispatch(clearCart()),
    setCheckout: (data) => dispatch(setCheckout(data)),
    createOrder: (data, token) => dispatch(createOrder(data, token)),
    paymentCreate: (data, token) => dispatch(fetchPayCreate(data, token)),
    clearCheckout: () => dispatch(clearCheckout()),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSummary);
