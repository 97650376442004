import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Card, Divider} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import OrderStatus from "./OrderStatus";
import Title from "../../../components/Title";
import PaymentStatus from "./PaymentStatus";
import moment from "moment";
import OrderItems from "./OrderItems";

const OrderDetailsMobile = (props) => {
    const classes = useStyles();
    let {statusCheck,statusCheckOnline, active, onPrint} = props;

    return (
        <div className={classes.main}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <OrderStatus activeOrder={active} statusCheck={statusCheck} statusCheckOnline={statusCheckOnline} onPrint={onPrint}/>
                </CardContent>
            </Card>
            {
                active.order_status_id === 2 ? (
                    <div>
                        {
                            active ? active.payment_method === 'online-payment' ?
                                (
                                    <Card className={classes.root}>
                                        <CardContent className={classes.cardContent}>
                                            <PaymentStatus classes={classes} active={active}/>
                                        </CardContent>
                                    </Card>
                                ) : null : null
                        }
                    </div>
                ) : null
            }
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.header}>
                        <Title
                            text={'Shipping Details:'}
                            fontSize={16}
                            lineHeight={24}
                            fontWeight={'700'}
                            color={'#313132'}
                            margin={'0 0 10px 0'}
                        />
                        <Title
                            text={'Date: ' + moment(active.date_modified).format('DD-MMM-YYYY')}
                            fontSize={14}
                            lineHeight={24}
                            fontWeight={'400'}
                            color={'#313132'}
                            margin={'0 0 10px 0'}
                        />
                    </div>
                    <Divider/>
                    <Title
                        text={'Address:'}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'700'}
                        color={'#313132'}
                        margin={'15px 0 0 0'}
                    />
                    <Title
                        text={`${active.shipping_address_1 ?
                            active.shipping_address_1.length ?
                                active.shipping_address_1 :
                                active.shipping_address_2 :
                            active.shipping_address_2}`}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'400'}
                        color={'#313132'}
                        margin={'0 0 20px 0'}
                    />
                    {/*<Title*/}
                    {/*    text={'Time: 12pm-1pm'}*/}
                    {/*    fontSize={14}*/}
                    {/*    lineHeight={24}*/}
                    {/*    fontWeight={'400'}*/}
                    {/*    color={'#313132'}*/}
                    {/*    margin={'0 0 20px 0'}*/}
                    {/*/>*/}
                    <Title
                        text={'Contact Details:'}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'700'}
                        color={'#313132'}
                        margin={'15px 0 0 0'}
                    />
                    <Title
                        text={`${active.telephone}`}
                        fontSize={14}
                        lineHeight={24}
                        fontWeight={'400'}
                        color={'#313132'}
                        margin={'0 0 20px 0'}
                    />
                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <OrderItems classes={classes} active={active}/>
                </CardContent>
            </Card>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    main: {
        margin: "0px",
        [theme.breakpoints.down(767)]: {
            margin: 0,
            width: '100%'
        }
    },
    root: {
        padding: 15,
        boxShadow: "0px 0px 0px #0000000D",
        minWidth: 200,
        marginBottom: 17,
    },
    cardContent: {
        paddingBottom: "0px !important",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

export default OrderDetailsMobile;
