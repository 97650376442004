import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {
    addItemToCart,
    addItemToCartServer,
    clearCart,
    removeItemFromCart,
    setCheckout,
    updateItemToCartServer,
} from "../../../redux/actions/cart";
import config from "../../../config";
import Counter from "./Counter";
import {Link} from "react-router-dom";
import {Img} from "react-image";
import placeholder from "../../../localization/images/product-placeholder.jpeg";
import querystring from "querystring";

const Placeholder = () => (
    <img
        src={placeholder}
        alt="product img loader"
        className="place-holder"
    />
);

const useStyles = makeStyles((theme) => ({
    paper: {
        "& .MuiTypography-body1": {
            fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
        },
        padding: "0 6%",
        "& .MuiButton-root": {
            minWidth: "1.62vw",
            padding: "0",
        },
    },
    imgBg: {
        backgroundColor: "#e8e8e8",
        padding: 5,
        width: 130,
        height: 130,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    productImg: {
        width: 120,
        height: 120,
    },
    info: {
        padding: "1vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    counter: {
        padding: "2.5vw 3vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    title: {
        fontSize: 16,
        fontWeight: "600",
        color: "#313139",
    },
    subTitle: {
        fontSize: 14,
        color: "#7d94a6",
    },
    price: {
        fontSize: 15,
        color: "#df1a33",
        marginTop: "1.7vw",
    },
    prescription: {
        fontSize: "12px",
        color: "#df1a33",
        marginTop: "3px",
    },
    roundBtn: {
        borderRadius: "50%",
        width: 18,
        height: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid #e8e8e8",
        color: "#e8e8e8",
        fontSize: 12,
        marginBottom: '5px',
        fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
}));

const CartList = ({
                      cart,
                      setCheckout,
                      clearCart,
                      addItemToCart,
                      cus_cart,
                      authData,token,
                      updateItemToCartServer,
                      addItemToCartServer,
                      removeItemFromCart,
                  }) => {
    const classes = useStyles();

    function getCurrent(cart, product_id) {
        let found = cart.length
            ? cart.find((item) => item.cartItem.product_id === product_id)
            : null;

        return found;
    }

    let addToCart = (item) => {
        let currentItem = getCurrent(cart, item.product_id);
        let count = currentItem ? currentItem?.count ? currentItem?.count : 0 : 0
        if (parseInt(count) < parseInt(item?.quantity)){
            addItemToCart(item);
            if (authData) {
                if (cus_cart) {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        cart_id: cus_cart?.data?.cart_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    updateItemToCartServer(data, authData?.data?.token)
                } else {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        product_id: currentItem?.cartItem?.product_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    addItemToCartServer(data, authData?.data?.token)
                }
            }
        }

    };

    let updateRemoveCardItem = (item) => {
        removeItemFromCart(item)
        if (authData) {
            let currentItem = getCurrent(cart, item.product_id);
            let data = querystring.stringify({
                customer_id: authData?.data?.userId,
                customer_auth: 'customer_auth',
                cart_id: cus_cart?.data?.cart_id,
                quantity: currentItem?.count,
                option: 'text me'
            })
            updateItemToCartServer(data, authData?.data?.token)
        }
    }

    return (
        <div className={classes.paper}>
            <Grid container>
                {/* <Grid item xs={6}>
          <Typography style={{ color: "#7d94a6", fontSize: ".9vw" }}>
            4 items on your cart
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            style={{
              color: "#32aeb1",
              fontSize: ".9vw",
              textAlign: "right",
              paddingRight: "12.7vw",
            }}
          >
            +Add More
          </Typography>
        </Grid> */}
                {cart
                    ? cart.length
                        ? cart.map((item) => {
                            let cartItem = item ? (item.cartItem ? item.cartItem : "") : "";
                            let count = item ? (item.count ? item.count : "") : "";

                            let productData = cartItem
                                ? cartItem.Product
                                    ? cartItem.Product
                                    : []
                                : [];
                            let product = productData
                                ? productData[0]
                                    ? productData[0]
                                    : ""
                                : "";
                            let ProductDescriptions = product
                                ? product.ProductDescriptions
                                    ? product.ProductDescriptions
                                    : ""
                                : "";
                            let ProductAttributes1 = product
                                ? product.ProductAttributes
                                    ? product.ProductAttributes
                                    : []
                                : [];
                            const pOtc1 = ProductAttributes1.find((p) => p.attribute_id === 7);

                            //part2
                            let detailProductDescriptions = cartItem
                                ? cartItem.ProductDescriptions
                                    ? cartItem.ProductDescriptions
                                    : []
                                : [];
                            let description = detailProductDescriptions
                                ? detailProductDescriptions
                                : "";
                            let ProductAttributes2 = cartItem
                                ? cartItem.ProductAttributes
                                    ? cartItem.ProductAttributes
                                    : []
                                : [];
                            const pOtc2 = ProductAttributes2.find((p) => p.attribute_id === 7);

                            const pOtc = pOtc1?.text ? pOtc1?.text : pOtc2?.text

                            let currentItem = getCurrent(cart, cartItem.product_id);

                            let handleRemove = () => {
                                let data = {
                                    count: item.count,
                                    product_id: item.cartItem.product_id,
                                };
                                removeItemFromCart(data);

                            };

                            //console.log('cartItem', description)

                            return (
                                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 10}}>
                                    <div style={{marginRight: '1%'}}>
                                        <Link to={`/details/${cartItem.product_id}`}>
                                            <Box className={classes.imgBg}>
                                                <Img
                                                    src={
                                                        cartItem.image
                                                            ? config.IMG_BASE + cartItem.image
                                                            : config.IMG_BASE + product.image
                                                    }
                                                    className={classes.productImg}
                                                    loader={<Placeholder/>}
                                                    unloader={<Placeholder/>}
                                                />
                                            </Box>
                                        </Link>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <div className={classes.info}>
                                            <Link to={`/details/${cartItem.product_id}`}>
                                                <Box>
                                                    <Typography className={classes.title}>
                                                        {ProductDescriptions.name
                                                            ? ProductDescriptions.name
                                                            : description.name}
                                                    </Typography>
                                                    <Typography className={classes.subTitle}>
                                                        {product.model ? product.model : cartItem.model}
                                                    </Typography>
                                                    <Typography className={classes.price}>
                                                        Tk{" "}
                                                        {(
                                                            currentItem.count *
                                                            (product.price ? product.price : cartItem.price)
                                                        ).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </Link>
                                        </div>
                                        <div className={classes.counter}>
                                            <Button onClick={handleRemove}>
                                                <span className={classes.roundBtn}>X</span>
                                            </Button>
                                            {/*<GroupedButtons*/}
                                            {/*    count={1}*/}
                                            {/*    incrementCount={() => 1 + 1}*/}
                                            {/*    decrimentCount={() => 1 - 1}*/}
                                            {/*/>*/}
                                            <Counter
                                                item={cartItem}
                                                variant="horizontal"
                                                value={currentItem.count}
                                                onIncrement={addToCart}
                                                onDecrement={updateRemoveCardItem}
                                            />
                                            <Typography className={classes.prescription}>
                                                {
                                                    pOtc === 'no' ? 'Required prescription' : null
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        : null
                    : null}
            </Grid>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        cart: state.cartReducer.cart,
        cus_cart: state.cartReducer.cus_cart,
        authData: state.authReducer.authData,
        token: state.authReducer.token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearCart: () => dispatch(clearCart()),
        setCheckout: (data) => dispatch(setCheckout(data)),
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addItemToCartServer: (data, token) => dispatch(addItemToCartServer(data, token)),
        updateItemToCartServer: (data, token) => dispatch(updateItemToCartServer(data, token)),
        removeItemFromCart: (data) => dispatch(removeItemFromCart(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
