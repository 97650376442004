import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const NewButton = (props) => {
  let { classes, loading, handleSubmit, text } = props;
  return (
    <div className={classes.booking} onClick={handleSubmit}>
      <IconButton className={classes.iconText} aria-label="directions">
        {text}
      </IconButton>
      {loading ? <CircularProgress className={classes.loader} /> : null}
    </div>
  );
};

const bookingStyle = (theme) => ({
  iconText: {
    color: "#fff",
    fontSize: 14,
  },
  booking: {
    backgroundColor: "#313132",
    margin: "0 0 0 0",
    borderRadius: "25px",
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
  },
  buttonIcon: {
    position: "absolute",
    right: 36,
    [theme.breakpoints.down("sm")]: {
      right: 24,
    },
  },
  loader: {
    color: "white",
    position: "absolute",
    marginTop: "0.75%",
  },
});

let style = (theme) => bookingStyle(theme);

export default withStyles(style)(NewButton);
