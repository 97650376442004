import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "96%",
  },
  paper: {
    // marginTop: "122px",
    backgroundColor: "white",
    height: "40px",
    borderBottom: "3px solid #32AEB0",
    // position: "fixed",
    width: "100%",
    zIndex: 999,
    padding: "6px 0px",
    color: "#E44155",
    fontSize: "0px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  oneLine: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  linkList: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: "auto",
    paddingRight: "auto",
    maxWidth: "170px",
    color: "#E44155",
    fontSize: "14px",
    fontWeight: "bold",
    "&:hover": {
      //   color: "black",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));

export default function ThirdMenuBar() {
  const classes = useStyles();

  //   Sub Menu section
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Submenu 1</MenuItem>
      <MenuItem onClick={handleMenuClose}>Submenu - 2</MenuItem>
    </Menu>
  );

  // Sub Menu section

  return (
    <div className={classes.paper}>
      <Container className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${13}`}
                color="inherit"
                className={classes.linkList}
              >
                Toiletries
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${9}`}
                color="inherit"
                className={classes.linkList}
              >
                Parlour
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${39}`}
                color="inherit"
                className={classes.linkList}
              >
                Cosmetics
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${40}`}
                color="inherit"
                className={classes.linkList}
              >
                Jewellery
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${41}`}
                color="inherit"
                className={classes.linkList}
              >
                Khusboo
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${12}`}
                color="inherit"
                className={classes.linkList}
              >
                Food
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${10}`}
                color="inherit"
                className={classes.linkList}
              >
                Sports
              </Link>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Link
                to={`/products/cat=${42}`}
                color="inherit"
                className={classes.linkList}
              >
                Others
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      {renderMenu}
    </div>
  );
}
