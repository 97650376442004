import React, {Component, useLayoutEffect} from 'react';
import Error from './components/404';
import Menu from '../../components/Menu';
import logoBlack from '../../localization/images/logo-black.svg';
import hamburgerBlack from '../../localization/images/hamburger-black.svg';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const ErrorPage =()=>{
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(
    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
      <Menu color={'#000'} logo={logoBlack} img={hamburgerBlack} />
      <Error />
    </motion.div>
  )
};

export default ErrorPage;
