import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
const Title = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin}) => {
  return (

    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledTitle  className={`${isVisible ? 'anim-active' : ''} fade-up title`}
                      fontSize={fontSize}
                      fontWeight={fontWeight}
                      color={color}
                      lineHeight={lineHeight}
                      letterSpacing={letterSpacing}
                      textTransform={textTransform}
                      margin={margin}>{text}
        </StyledTitle>
      }
    </VisibilitySensor>
  )
};


const StyledTitle = styled.h2`
  font-size: ${props => props.fontSize || 42}px;
  font-weight:${props => props.fontWeight || 'bold'};
  margin: ${props => props.margin || '0px'};;
  line-height:${props => props.lineHeight || 42}px;
  color:${props => props.color || '#000000'};
  text-transform: ${props => props.textTransform || 'unset'} ;


  @media(max-width:767px){
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
`;


export default Title;
