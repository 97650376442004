export const authStyle = (theme) => ({
    main: {
        padding: "36px !important",
        maxWidth: 450,
    },
    formRow: {
        width: "90%",
    },
    formLabel: {
        fontSize: 14,
        fontWeight: "bold",
    },
    formField: {
        marginTop: 20,
    },
    linkText: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    divider: {
        borderBottom: "1px solid #E0E0E0",
        marginTop: 20,
    },
    drawerHeader: {
        height: 200,
        padding: 10,
        backgroundColor: "#313132",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    drawerHeaderText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "15%",
        width: "100%",
    },
    crossIcon: {
        cursor: "pointer",
        position: "absolute",
        top: 16,
        right: 16,
    },
    drawerBody: {
        padding: 15,
    },
    listDiv: {
        cursor: "pointer",
    },
    formControl: {
        fontSize: 14,
    },
});
