import React, {useEffect} from 'react';
import Title from '../../../components/Title'
import {clearCart} from "../../../redux/actions/cart";
import {fetchPayCreate} from "../../../redux/actions/order";
import {connect} from "react-redux";
import querystring from "querystring";
import Divider from "@material-ui/core/Divider";

function formatMoney(n) {
    return "BDT " + (Math.round(n * 100) / 100).toLocaleString();
}

let PaymentStatus = props => {
    let {classes, active, authData, paymentCreate, paySuccessData, paySuccessLoading, paySuccessErr} = props
    let payment = active ? active.payment_method === 'online-payment' ? 'Online Payment' : 'Cash on Delivery' : null
    const [addressErr, setAddressErr] = React.useState("");

    let handlePayment = () =>{
        const data = querystring.stringify({
            amount: parseFloat(active.total),
            customer_id: active.customer_id,
            order_id: active.order_id,
            cust_name: active.firstname + " " + active.lastname,
            cust_email: active.email,
            cust_add1: active.payment_address_1,
            cust_add2: active.payment_address_2,
            cust_city: active.payment_city,
            cust_state: active.payment_city,
            cust_postcode: active.payment_postcode,
            cust_phone: active.telephone,
            ship_name: active.shipping_firstname + ' ' + active.shipping_lastname,
            ship_add1: active.shipping_address_1,
            ship_add2: active.shipping_address_2,
            ship_city: active.shipping_city,
            ship_state: active.shipping_city,
            ship_postcode: active.shipping_postcode,
        });

        paymentCreate(data, authData?.data?.token);
    }

    useEffect(() => {
        if (paySuccessData) {
            if (paySuccessData?.data?.redirectUrl) {
                if (paySuccessData?.data?.redirectUrl.length) {
                    window.location.href = paySuccessData?.data?.redirectUrl;
                    clearCart();
                }
            }
        }

        if (paySuccessErr) {
            setAddressErr(paySuccessErr.message);
        }
    },[paySuccessData, paySuccessErr])

    return(
        <>
            <div className={classes.header}>
                <Title
                    text={'Payment Status:'}
                    fontSize={16}
                    lineHeight={24}
                    fontWeight={'700'}
                    color={'#313132'}
                    margin={'0 0 10px 0'}
                />
            </div>
            <div>
                <div style={{width: '100%'}}>
                    <Divider/>
                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                        <Title
                            text={'Your payment is pending'}
                            fontSize={14}
                            lineHeight={24}
                            fontWeight={'700'}
                            color={'#32aeb1'}
                            margin={'20px 0 20px 0'}
                        />
                        <div style={{cursor: 'pointer'}} onClick={handlePayment}>
                            <Title
                                text={'Pay now'}
                                fontSize={14}
                                lineHeight={24}
                                fontWeight={'700'}
                                color={'#ff0000'}
                                margin={'20px 0 20px 0'}
                            />
                        </div>
                    </div>
                </div>
                {addressErr ? (
                    addressErr.length ? (
                        <div
                            style={{
                                color: "#ff0000",
                                fontFamily: "Poppins",
                                fontSize: 12,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            {addressErr}
                        </div>
                    ) : null
                ) : null}
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        paySuccessData: state.oderReducer.paySuccessData,
        paySuccessLoading: state.oderReducer.paySuccessLoading,
        paySuccessErr: state.oderReducer.paySuccessErr,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        paymentCreate: (data, token) => dispatch(fetchPayCreate(data, token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatus);
