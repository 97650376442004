import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";
import { BLOG } from "../../constants/blog/index";

function* fetchBlogListData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.BLOG_POST_LIST,
            actions.params
        );
        yield put({
            type: BLOG.BLOG_LIST.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: BLOG.BLOG_LIST.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchBlogDetailData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.BLOG_POST_DETAILS,
            actions.params
        );
        yield put({
            type: BLOG.BLOG_LIST_DETAIL.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: BLOG.BLOG_LIST_DETAIL.FAILURE,
            result: err.response.data,
        });
    }
}

export default function* blogSagas() {
    yield takeLatest(BLOG.BLOG_LIST.MAIN, fetchBlogListData);
    yield takeLatest(BLOG.BLOG_LIST_DETAIL.MAIN, fetchBlogDetailData);
}