import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
      color: "#797979",
    },

    padding: "5%",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: "30px", textAlign: "center", color: "#505050" }}
      >
        Privacy Policy
      </Typography>

      <div style={{ color: "#797979", fontSize: "16px", padding: "2% 0" }}>
        You are unauthorized to use any of our content like image, text, or logo
        for commercial purposes unless you have taken the consent form to do so.
        <br />
        <br />
        The information we provide at Netmed24.com.bd is intended to replace
        consultation with a qualified medical professional. Seek the advice of
        your physician or another qualified health provider with any questions
        you may have regarding a medical condition. The content we provide is
        intended to be relied upon for medical diagnosis or treatment. Let
        inform your physician of any kind changes you may make to your lifestyle
        and discuss these with him or her. Do disregard medical advice or delay
        visiting a medical professional or expertise and also something you read
        on our site, app, or on our other communication channels or media.
        <br />
        <br />
        You should not use our recipes or membership content unless you have
        obtained consent to do so from us.
      </div>

      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Netmed24 Privacy Policy
        </Typography>
        <br />
        The Netmed24.com.bd stores and uses personal information to provide you
        with good service. We will never sell your information, and will only
        share it with third parties or for a survey (like service providers)
        when needed to operate and improve our services better.
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          What Information Netmed24 Collects and Why Information from Website
          Browsers
        </Typography>
        <br />
        We maintain standard access logs of all visits to the site. This
        includes access time, page visited browser information, and internet
        address, which can potentially be personally identifying. We may also
        keep a record of how the user interacts with the site, including clicks,
        scrolling, etc.
        <br />
        <br />
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Why Do We Collect This?
        </Typography>
        <div style={{ paddingTop: "1%", color: "#797979", fontSize: "16px" }}>
          We collect this information to better understand how our website
          visitors use Netmed24 and to monitor and protect the security of our
          services.
          <br />
          <br />
          We may keep information about how our apps are being used. This can
          include access time, page visited, browser information, internet
          address, button, and link clicks, scrolling, and all other ways in
          which someone interacts with the app, which can potentially be
          personally identified User-Provided Information When you submit
          information to Netmed24, via our site, apps, etc., to comment on posts
          and articles, rate a recipe or video, ask or answer a question,
          customize a meal plan, mark content as you like or to watch later, or
          in other ways, the content submitted is processed and recorded. If you
          are logged in it may be linked to your account or profile. It will
          also in some cases include your internet address, which is potentially
          personally identifying. <br />
          <br />
          Please be careful and conscious with any personal or sensitive health
          information you submit or share. Comments, success stories, and
          similar content can be visible to anyone visiting the site! We do not
          filter user-submitted content for personal information or sensitive
          information, and by submitting it you permission to publish the
          content and to store and process it as needed for that purpose The
          information may be transmitted, processed, and recorded by third-party
          service providers to detect spam and to ensure that the service works
          as intended.
        </div>
      </div>
      <div style={{ paddingBottom: "1%" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Why Do We Collect This?
        </Typography>
        <div style={{ paddingTop: "1%", color: "#797979", fontSize: "16px" }}>
          We need to process and store the information as necessary to publish
          it. We need to detect spam and other forms of invalid use of our
          services. We need to monitor usage and ensure that the service works
          as intended. We need to understand how our services are used, and we
          want to periodically publish reports on this. For those purposes, the
          information may be aggregated, analyzed, and published (with
          personally identifying information removed). We need to improve the
          service you receive and we may use the information you supply, like
          your ratings, to improve the services we offer you. We need to monitor
          and protect the security of our services and your information.
        </div>
        <br />
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Member Information
        </Typography>

        <div style={{ paddingTop: "1%", color: "#797979", fontSize: "16px" }}>
          If you sign up or log in for the membership we collect your name,
          email address, and payment information. We collect information about
          all subscription payments and data and statistics on how you use the
          site including video views and when you last visited the site. We also
          store your answers to surveys you may reply to via email or on
          Netmed24.com.bd. If membership of Netmed24 is to be considered
          sensitive personal information (health information) by signing up you
          permit us, to handle your information. Payment details are processed
          and stored by our third-party payment service providers. We do not
          keep a record of your actual payment details, like full credit card
          information. That information is only handled by our payment service
          providers. Your stored basic personal information and order
          information is displayed on your account page once you have become a
          member.
        </div>
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Why Do We Collect This?
        </Typography>
        <br />
        We need personal and payment information to set up and charge for the
        membership. We need to use your email address to communicate with you if
        needed for the membership (for example if a payment fails). We need to
        collect and store information as reasonably needed to respond to support
        requests. We need to collect and store information regarding payments as
        mandated by law (for example for accounting and VAT reporting). We need
        to monitor and protect the security of our services and your
        information. We need to keep improving our services, and for that
        purpose may contact some members to ask you to help us with this, as
        well as collect and store information about the usage of our services.
        Permissions we need from Mobile App Currently Netmed24 Mobile app need
        below listed permissions: "android.permission.INTERNET"
        "android.permission.ACCESS_NETWORK_STATE"
        "android.permission.WRITE_EXTERNAL_STORAGE"
        "android.permission.READ_EXTERNAL_STORAGE" "android.permission.CAMERA"
      </div>
      <div style={{ paddingBottom: "1%", color: "#797979", fontSize: "16px" }}>
        <Typography style={{ color: "#616161", fontSize: "22px" }}>
          Why We Need these?
        </Typography>
        <br />
        As it is an e-commerce solution of pharmacy business, so Internet
        permission is must When customer buying a non-OTC item (for pharma
        product), as medical guideline and government regulation, customer is
        bound to upload prescription to verify the buying rights of that
        medicine. So, this app needs External Storage permission and Camera use
        permission, so that user can upload the prescription from phone storage
        or capture it via Phone mobile.
        <br />
        <br />
        Sharing Of Information with Third Parties Netmed24 will not share your
        personal information with any third party unless required to do so by
        law, or unless required for those service providers we use to operate
        and improve Netmed24. Service providers handle services such as payment
        processing, spam detection, network data transmission, and other similar
        services. We remain responsible for your information when shared with
        our service providers, and it will only be used and stored for the
        purposes detailed in this document. Changes to Terms or Privacy Policy
        Netmed24 will not change the core philosophy of our privacy policy and
        terms, but may otherwise update them from time to time. If there are
        significant changes we will make reasonable efforts to notify you before
        they take effect. For minor changes that do not affect your rights, we
        encourage you to check this page frequently.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
