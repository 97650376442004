import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";

const useStyles = makeStyles((theme) => ({
  paper: {
    // textAlign: "center",
    padding: "5% 15%",
    // fontWeight: 600,
    // paddingBottom: "7.3vw",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  boxItem: {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textStyle: {
    fontSize: ".9vw",
    color: "#7b7b7b",
    margin: "5px 0",
    "&:hover":{
      color: "#32aeb1",
    }
  },
}));

const FilterByGroup = ({groups, setGroupName}) => {
  const classes = useStyles();
  //console.log(groups)

  let handleFilterGroup = (name) =>{
    setGroupName(name)

  }

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: "1.1vw", color: "#1b1d1e", fontWeight: "500" }}
      >
        FILTER BY GROUP
      </Typography>

      {groups.map((item) => (
        <Box className={classes.boxItem} onClick={()=>handleFilterGroup(item.name)}>
          <Typography className={classes.textStyle}>
            {" "}
            <FiberManualRecordSharpIcon style={{ color: item.color }} /> &nbsp;
            {item.name}
          </Typography>
          {/*<Typography className={classes.textStyle}>({item.qty})</Typography>*/}
        </Box>
      ))}
    </div>
  );
};

export default FilterByGroup;
