import {Grid, LinearProgress, Typography} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Product from "./Product";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    margin: "10px 0",
    minHeight: 800
  },
  textStyle: {
    fontSize: "1.2vw",
    color: "#1b1d1e",
    fontWeight: "500",
  },
}));

const Products = ({ products, filterProduct, handleLoadMore }) => {
  let ProductToCategories = products ? products.ProductToCategories : [];
  let CategoryDescriptions = products ? products.CategoryDescriptions : [];
  let catagory = CategoryDescriptions ? CategoryDescriptions.length ? CategoryDescriptions[0].name : '' : ''

  let mainProduct = filterProduct ? filterProduct.length ? filterProduct : ProductToCategories : []

  // let catagoryList = products.ProductCategoryList
  //   ? products.ProductCategoryList
  //   : [];
  // let isPharma = catagoryList.find((item) => item.category_id == 1)
  //   ? "pharma"
  //   : "noPharma";
  let isPharma = "pharma";

  const classes = useStyles();

  return (
    <Main className={classes.root}>
      <Grid container>
        {mainProduct ? mainProduct.length ? (
            mainProduct.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ padding: "1%" }}>
                <Product item={item} isPharma={isPharma} />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography
              style={{
                color: "f0f0f0",
                fontSize: 14,
                textAlign: "center",
              }}
            >
              No products were found matching your selection
            </Typography>
          </Grid>
        ) : (
            <Grid item xs={12}>
              <Typography
                  style={{
                    color: "f0f0f0",
                    fontSize: 14,
                    textAlign: "center",
                  }}
              >
                No products were found matching your selection
              </Typography>
            </Grid>
        )}
        {
          mainProduct ? mainProduct.length ? mainProduct.length > 19 ? (
              <Grid item xs={12}>
                <center>
                  <div className="load-more"
                       onClick={handleLoadMore}
                  >
                    Load More
                  </div>
                  {/*<LinearProgress/>*/}
                </center>
              </Grid>
          ) : null : null : null
        }

      </Grid>
    </Main>
  );
};

let Main = styled.div`
  .load-more{
    cursor: pointer;
    border: 1px solid #32aeb1;
    border-radius: 50px;
    width: 145px;
    padding: 10px;
    margin-bottom: 50px;
    margin-top: 50px;
    color: #32aeb1;
    &:hover{
      background-color: #32aeb1;
      color: #ffffff;
    }
  }
`;

export default Products;
