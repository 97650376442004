import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import RightIcon from "../../../../localization/images/navRightBlack.svg";
import LeftIcon from "../../../../localization/images/navLeftBlack.svg";
import productImage from "../../../../localization/images/livecare.png";
import { featureData } from "../../../../localization/data/products";
import { Button } from "@material-ui/core";
import { Img } from "react-image";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { fetchExpConcernData } from "../../../../redux/actions/home";
import ReactHtmlParser from "react-html-parser";
import {
  addItemToCart,
  addItemToWishlist,
} from "../../../../redux/actions/cart";
import { connect } from "react-redux";
import { ApiParams } from "../../../../constants/apiParams";
import config from "../../../../config";
import placeholder from "../../../../localization/images/product-placeholder.jpeg";
import { Link } from "react-router-dom";

const Placeholder = () => (
  <img
    src={placeholder}
    alt="product img loader"
    style={{ width: "100%", height: "100%" }}
  />
);

let checkCartAvailable = (cart, id) => {
  if (cart) {
    if (cart.length) {
      let found = cart.find((item) => item.cartItem.product_id === id);
      if (found) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
};

let checkWishlistAvailable = (wishlist, id) => {
  if (wishlist) {
    if (wishlist.length) {
      let found = wishlist.find((item) => item.product_id === id);

      if (found) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
};

const ExpHealthConcern = ({
  getFetchExpConcernData,
  expConcern,
  addItemToCart,
  addItemToWishlist,
  cart,
  wishlist,
}) => {
  useEffect(() => {
    let params = {
      [ApiParams.parent_id]: "18",
      // [ApiParams.limit]: "6",
      // [ApiParams.offset]: "0",
    };
    getFetchExpConcernData(params);
  }, []);

  let data = expConcern ? expConcern.data : null;
  let ProductToCategories = data ? data.ProductToCategories : [];
  let size = 8;
  let ProductData = []; //ProductToCategories.slice(0, size);

  const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      960: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1501: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  return (
    <StyledToday>
      <Container className="news-events-slider">
        <Row>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 24,
                marginTop: 32,
              }}
            >
              <div className="top-title">Explore by Health Concerns</div>
              <div
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400 }}
                >
                  View all
                </div>
                <NavigateNextIcon style={{ color: "#1a1a1a" }} /> */}
              </div>
            </div>
            {data ? (
              <Swiper {...params}>
                {data.map((item) => {
                  // let productData = item
                  //   ? item.Product
                  //     ? item.Product
                  //     : []
                  //   : [];
                  // let product = productData
                  //   ? productData[0]
                  //     ? productData[0]
                  //     : ""
                  //   : "";
                  // let ProductDescriptions = product
                  //   ? product.ProductDescriptions[0]
                  //     ? product.ProductDescriptions[0]
                  //     : ""
                  //   : "";

                  // let ProductImages = product
                  //   ? product.ProductImages
                  //     ? product.ProductImages
                  //     : []
                  //   : [];
                  // let images = ProductImages
                  //   ? ProductImages[0]
                  //     ? ProductImages[0]
                  //     : ""
                  //   : "";

                  // let addToCart = (item) => {
                  //   addItemToCart(item);
                  // };

                  // let addToWishlist = (item) => {
                  //   addItemToWishlist(item);
                  // };

                  // let isCart = checkCartAvailable(cart, item.product_id);
                  // let isWish = checkWishlistAvailable(
                  //   wishlist,
                  //   item.product_id
                  // );

                  return (
                    <div className="news-events-slider__single">
                      <div className="containerImg">
                        <div className="cardTitle">
                          {ReactHtmlParser(item.CategoryDescriptions[0].name)}
                        </div>
                        <Img
                          src={config.IMG_BASE + item.image}
                          alt=""
                          className="imageImg"
                          loader={<Placeholder />}
                          unloader={<Placeholder />}
                        />

                        <div className="overlay">
                          <div className="text">
                            <div className="overTitle">
                              {ReactHtmlParser(
                                item.CategoryDescriptions[0].name
                              )}
                            </div>
                            {ReactHtmlParser(
                              ReactHtmlParser(
                                item.CategoryDescriptions[0].description
                              )
                            )}

                            <div style={{ paddingTop: "40%" }}>
                              <Link
                                to={`/products/cat=${item.CategoryDescriptions[0].category_id}`}
                                className="detailsBtn"
                              >
                                View Products
                              </Link>
                            </div>

                            {/* {item.CategoryDescriptions[0].description} */}
                          </div>
                          {/* {isCart ? (
                            <div id="wrapper" className="is-cart">
                              <button
                                type="button"
                                onClick={() => addToCart(item)}
                              >
                                <ShoppingCartIcon /> &nbsp; &nbsp;ADD TO CART
                              </button>
                            </div>
                          ) : (
                            <div id="wrapper">
                              <button
                                type="button"
                                onClick={() => addToCart(item)}
                              >
                                <ShoppingCartIcon /> &nbsp; &nbsp;ADD TO CART
                              </button>
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div
                        className="news-events-slider__single__content"
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  );
                })}
              </Swiper>
            ) : null}
          </Col>
        </Row>
      </Container>
    </StyledToday>
  );
};

const StyledToday = styled.div`
  background-color: #fff;
  position: relative;

  // m
  .containerImg {
    position: relative;
    width: 100%;
    min-height: 350px;
    background-color: #f0f0f0;
  }

  .imageImg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .cardTitle {
    padding-left: 15px;
    padding-top: 20px;
    font-family: "Poppins", sans-serif;
  }

  .containerImg:hover .cardTitle {
    color: #f0f0f0;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .containerImg:hover .overlay {
    opacity: 1;
  }
  .overTitle {
    color: #fff;
    padding-bottom: 20px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }

  .detailsBtn {
    color: #fff;
    font-size: 12px;
  }

  .detailsBtn:hover {
    color: #1ec3aa;
  }

  .text p {
    color: #fff;
  }

  .text {
    color: #fff;
    font-size: 14px;
    position: absolute;
    width: 100%;
    top: 10%;
    left: 0;
    text-align: center;
    padding: 0 5%;
    // -webkit-transform: translate(-20%, -20%);
    // -ms-transform: translate(-20%, -20%);
    // transform: translate(-20%, -20%);
  }

  #wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  button {
    height: 2.9vw;
    position: relative;
    width: 9.5vw;
    top: 80%;
    border-radius: 4px;
    font-size: 0.87vw;
    background: linear-gradient(90deg, #1f8184 30%, rgb(30 195 170) 30% 100%);
    color: #fff;
    border: none;
  }
  .is-cart {
    button {
      height: 40px;
      position: relative;
      width: 130px;
      border-radius: 4px;
      font-size: 12px;
      background: linear-gradient(90deg, #dc3545 30%, rgb(30 195 170) 30% 100%);
      color: #fff;
      border: none;
    }
  }

  .container {
    max-width: 95%;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }

  .top-title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    // padding-left: 8px;
    padding-bottom: 16px;
    color: #2fa5aa;
  }

  .news-events-slider {
    &__single {
      &__content {
        background-color: #f0f0f0;
        padding: 20px;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.5);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
        }

        .line {
          margin-top: 4px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
      }

      &__img {
        padding-top: 71%;
        position: relative;
        overflow: hidden;
        z-index: 3;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;
        }

        // &:hover {
        //   img {
        //     transform: scale(1.05);
        //     filter: contrast(140%);
        //   }
        // }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    //margin-left: -50px;
    //margin-right: -50px;
    //padding-right: 50px;
    //padding-left: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      right: 0;
      top: 0;
      z-index: 2;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }

  @media (max-width: 1024px) {
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
    min-height: 123px;
  }

  .product-title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    //margin: -10px 0 7px 0;
    width: 100%;
    text-align: center;
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  .product-weight {
    text-align: center;
    margin-top: 14px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #898686;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .product-meta {
    margin-top: 8px;
    //display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-height: 32px;
    }

    .productPriceWrapper {
      position: relative;
      margin-bottom: 8px;
      .product-price {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #b61026;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #d98b99;
        padding: 0 5px;
        margin-right: 15px;
        text-decoration: line-through;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .cart-button {
      border: 1px solid #fff;
      border-radius: 4px;
      height: 24px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      font-weight: 700;
      color: #000;
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8%;
      }

      .btn-text {
        @media (max-width: 767px) {
          display: block;
        }
      }

      &:hover {
        color: #000;
        background-color: #009e7f;
        border-color: #009e7f;
      }

      svg {
        fill: currentColor;
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }

      button {
        width: 100%;
        height: 27px;
      }

      .incBtn {
        top: 0;
        justify-content: center;
      }

      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }

      input[type="number"] {
        left: 0;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: block;
  }
`;

function mapStateToProps(state) {
  return {
    expConcern: state.homeReducer.expConcern,
    // cart: state.cartReducer.cart,
    // wishlist: state.cartReducer.wishlist,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchExpConcernData: (params) => dispatch(fetchExpConcernData(params)),
    // addItemToCart: (data) => dispatch(addItemToCart(data)),
    // addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpHealthConcern);
