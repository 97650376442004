import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Medicon from "../../assets/images/medicon.png";
import Avatar from "@material-ui/core/Avatar";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "96%",
  },
  paper: {
    // marginTop: "62px",
    backgroundColor: "#32AEB0",
    height: "60px",
    borderTop: "3px solid #1F8184",
    // position: "fixed",
    width: "100%",
    zIndex: 999,
    padding: "6px 0px",
    color: "white",
    fontSize: "0px",
    display: "none",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  logo: {
    height: "30px",
    width: "30px",
  },
  oneLine: {
    display: "flex",
    flexWrap: "wrap",
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  smallText: {
    fontSize: "10px",
  },
  linkList: {
    color: "white",
    fontSize: "20px",
    "&:hover": {
      color: "black",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));

export default function TopMenuBar() {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Container className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {/* previous style -> xs={12} sm={2} */}
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                    to={`/products/cat=${20}`}
                    color="inherit"
                    className={classes.linkList}
                >
                  COVID-19
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${19}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Diabetic Care
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${22}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Kidney Care
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${5}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Vitamins
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${24}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Gastro
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${10}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Fitness
                </Link>
                {/* <span className={classes.smallText}>
                  <br />
                  loren Ipsum
                </span> */}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.oneLine}>
              <Avatar src={Medicon} className={classes.logo}></Avatar>
              <div>
                <Link
                  to={`/products/cat=${15}`}
                  color="inherit"
                  className={classes.linkList}
                >
                  Sergicals
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
