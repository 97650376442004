import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../components/Title";

const Details = ({blogDetail}) =>{
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <BlogDetailDiv>
        <Container>
            {/*<Row><Col><h2 className="date">01.02.2021</h2></Col></Row>*/}
            <Row><Col>
                <Title
                    text={blogDetail?.PostDescription?.title}
                    fontSize={30}
                    fontWeight={700}
                    lineHeight={50}
                    color={'#32aeb0'}
                />
            </Col></Row>
            <Row>
                <Col>
                <div className="line-div">
                    <div className="square-div"><div className="inner-square"></div></div>
                    <div className="line"></div>
                </div>
                </Col>
            </Row>
            <Row className="content">
                {/*<Col className="content" lg={12}>*/}
                {/*    <h2>How the Story Began</h2>*/}
                {/*    <h2 style={{marginBottom:"30px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h2>*/}
                {/*</Col>*/}
                {/*<Col className="content" lg={12}>*/}
                {/*    <h2>To A Happily Ever After</h2>*/}
                {/*    <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h2>*/}
                {/*</Col>*/}
                {
                    ReactHtmlParser(ReactHtmlParser(blogDetail?.PostDescription?.description))
                }
            </Row>
        </Container>
        </BlogDetailDiv>
    )
};
const BlogDetailDiv = styled.div`
  padding-top: 80px;
  background: #F5F5F5;
  
  .date{
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 6px;
  }
  .content{
    b{
      color: #32aeb0;
    }
    h2{
      font-size: 14px;
    }
    h2:nth-child(1){
      color: #32aeb0;
      margin-bottom: 30px;
    }
    h2:nth-child(2){
      line-height: 20px;
      margin: 0;
    }
  }
  .line-div{
    margin: 23px 0 28px 3px;
    display: flex;
    width: 50%;
    height: 25px;
    align-items: center;
  }
  .line-div .square-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border: 2px solid #32aeb0;
    transform: rotate(45deg);
  }
  .line-div .square-div .inner-square{
    width: 80%;
    height: 80%;
    background: #32aeb0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .line-div .line{
    height: 1px;
    width: 150px;
    background: #32aeb0;
  }
  @media(max-width: 768px){
    .title{
      line-height: 25px;
    }
  }
`
export default Details;