import {CircularProgress, Grid, LinearProgress, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import TopRatedProducts from "../productDetails/components/TopRatedProducts";
import Categories from "./components/Categories";
import FilterByGroup from "./components/FilterByGroup";
import FilterByPrice from "./components/FilterByPrice";
import FilterByType from "./components/FilterByType";
import Pharmacutical from "./components/Pharmacutical";
import RightSection from "./components/RightSection/RightSection";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  clearSearchProduct,
  fetchAllProductsData,
  fetchChildCategory,
  fetchFilterProductsData
} from "../../redux/actions/allProducts";
import { ApiParams } from "../../constants/apiParams";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MobileFilter from "./components/MobileFilter";
import MyProgressBar from "../../components/MyProgressBar";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  textStyle: {
    fontSize: ".9vw",
    color: "#7b7b7b",
    margin: "15px 0",
    textAlign: "center",
  },
}));

function getUnique(arr, index) {

  const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

  return unique;
}

function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const AllProducts = ({
                       getFetchAllProductsData,
                       allProducts,
                       allProductsLoading,
                       filterProducts,
                       filterProductsLoading,
                       fetchFilterProductsData,
                       clearSearchProduct,
                       fetchChildCategory,
                       childCategory
                     }) => {
  let { id } = useParams();
  const classes = useStyles();
  const [limit, setLimit] = useState(20)
  const [price_start, setStart] = useState(0)
  const [price_end, setEnd] = useState(0)
  const [categoryID, setCategoryID] = useState('')
  const [groupName, setGroupName] = useState('')
  const [manufacture, setManufacture] = useState('')

  useEffect(() => {
    let params = {
      [ApiParams.parent_id]: '18',
      [ApiParams.limit]: 10,
    };
    clearSearchProduct();
    fetchChildCategory(params);
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
    let params = {
      [ApiParams.category_id]: id,
      [ApiParams.limit]: limit,
      [ApiParams.offset]: "0",
    };
    getFetchAllProductsData(params);
  }, [limit]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      [ApiParams.filter_category]: categoryID,
      [ApiParams.price_start]: price_start,
      [ApiParams.price_end]: price_end,
      [ApiParams.limit]: limit,
      [ApiParams.offset]: "0",
    };
    if (price_end > 1) {
      fetchFilterProductsData(params);
    }
  }, [price_start, price_start, categoryID]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      [ApiParams.filter_category]: categoryID,
      [ApiParams.filter_manufacture]: parseInt(manufacture),
      [ApiParams.group_name]: groupName,
      [ApiParams.limit]: limit,
      [ApiParams.offset]: "0",
    };
    fetchFilterProductsData(params);
  }, [categoryID, groupName, manufacture]);

  let filter = filterProducts ?
      filterProducts.data ?
          filterProducts.data.length ?
              filterProducts.data :
              [] : [] : []

  let handleLoadMore = () =>{
    setLimit(limit+20)
  }

  let products = allProducts ? allProducts.data : [];
  let ProductToCategories = products ? products.ProductToCategories ? products.ProductToCategories.length ? products.ProductToCategories
      : [] : [] : []
  let childCategoryData = childCategory ? childCategory.data : [];
  let CategoryDescriptions = products ? products.CategoryDescriptions : [];
  let catagory = CategoryDescriptions ? CategoryDescriptions.length ? CategoryDescriptions[0].name : '' : ''

  let group = []
  let manufacturer = []

  ProductToCategories.map(item =>{
    let product = item.Product[0]
    let ProductAttributes = product.ProductAttributes
    let ProductManufacturer = product.ProductManufacturer
    let pGroup = ProductAttributes.find((p) => p.attribute_id === 4);
    let data = {
      color: getRandomColor(),
      name: pGroup?.text
    }
    let manufact = {
      id: product.manufacturer_id,
      name: ProductManufacturer ? ProductManufacturer.name : '',
      color: getRandomColor(),
      checked: false
    }

    group.push(data)
    manufacturer.push(manufact)
  })
  let final = group.filter(x => x.name)
  let finalManufact = manufacturer.filter(x => x.id)

  return (
      <Main>
        {
          allProductsLoading === true || filterProductsLoading === true ? (
              <div className="loader">
                <LinearProgress style={{marginTop: '0.1%'}}/>
                <MyProgressBar/>
              </div>
          ) : (
              <div>
                <div className="desktop-view">
                  <Grid container className={classes.paper}>
                    <Grid item xs={12}
                          container style={{ backgroundColor: "#f9f9f9" }}>
                      <Grid item xs={3}>
                        <Typography className={classes.textStyle}>
                          Home/ {catagory}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} style={{ backgroundColor: "#f9f9f9" }}>
                      <Categories childCategoryData={childCategoryData} setCategoryID={setCategoryID}/>
                      <FilterByPrice setStart={setStart} setEnd={setEnd}/>
                      {/*<FilterByGroup groups={getUnique(final, 'name')} setGroupName={setGroupName}/>*/}
                      {/*<FilterByType />*/}
                      <Pharmacutical finalManufact={getUnique(finalManufact, 'id')} setManufacture={setManufacture}/>
                      {/*<TopRatedProducts group_name={''} height={'100px'}/>*/}
                    </Grid>
                    <Grid itme xs={9} style={{ backgroundColor: "#fff" }}>
                      <RightSection products={products} filter={filter} handleLoadMore={handleLoadMore}/>
                    </Grid>
                  </Grid>
                </div>
                <div className="mobile-view">
                  <Grid container className={classes.paper}>
                    <Grid itme xs={12} style={{ backgroundColor: "#fff" }}>
                      <MobileFilter
                          products={products}
                          marginBottom={'30px'}
                          childCategoryData={childCategoryData}
                          setCategoryID={setCategoryID}
                          setStart={setStart}
                          setEnd={setEnd}
                          finalManufact={getUnique(finalManufact, 'id')}
                          setManufacture={setManufacture}
                      />
                      <RightSection products={products} filter={filter} handleLoadMore={handleLoadMore}/>
                    </Grid>
                  </Grid>
                </div>
              </div>
          )
        }
      </Main>
  );
};

let Main = styled.div`
  .desktop-view{
    display: block;
  }
  .mobile-view{
    display: none;
  }
  @media(max-width: 1279px){
    margin-top: 100px;
  }
  @media(max-width: 959px){
    margin-top: 0px;
    .desktop-view{
      display: none;
    }
    .mobile-view{
      display: block;
    }
  }
  .loader {
    height: 100vh;
    text-align: center;
    //background-color: #61dafb;
  }
`;

function mapStateToProps(state) {
  return {
    allProducts: state.allProductsReducer.allProducts,
    allProductsLoading: state.allProductsReducer.allProductsLoading,
    filterProducts: state.allProductsReducer.filterProducts,
    filterProductsLoading: state.allProductsReducer.filterProductsLoading,
    childCategory: state.allProductsReducer.childCategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchAllProductsData: (params) => dispatch(fetchAllProductsData(params)),
    fetchFilterProductsData: (params) => dispatch(fetchFilterProductsData(params)),
    fetchChildCategory: (params) => dispatch(fetchChildCategory(params)),
    clearSearchProduct: () => dispatch(clearSearchProduct())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts);
