import { ALL_PRODUCTS } from "../../constants/allProducts";

const initialState = {
  allProducts: "",
  allProductsLoading: false,
  allProductsErr: null,
  searchProducts: "",
  searchProductsLoading: false,
  searchProductsErr: null,
  relatedProducts: "",
  relatedProductsLoading: false,
  relatedProductsErr: null,
  filterProducts: "",
  filterProductsLoading: false,
  filterProductsErr: null,
  childCategory: "",
  childCategoryLoading: false,
  childCategoryErr: null,
};

export default function allProductsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_PRODUCTS.ALL_PRODUCTS_DATA.MAIN:
      return {
        ...state,
        allProducts: "",
        allProductsLoading: true,
        allProductsErr: null,
      };

    case ALL_PRODUCTS.ALL_PRODUCTS_DATA.SUCCESS:
      return {
        ...state,
        allProducts: action.result,
        allProductsLoading: false,
        allProductsErr: null,
      };

    case ALL_PRODUCTS.ALL_PRODUCTS_DATA.FAILURE:
      return {
        ...state,
        allProducts: "",
        allProductsLoading: false,
        allProductsErr: action.result,
      };

    case ALL_PRODUCTS.FILTER_PRODUCTS.MAIN:
      return {
        ...state,
        filterProducts: "",
        filterProductsLoading: true,
        filterProductsErr: null,
      };

    case ALL_PRODUCTS.FILTER_PRODUCTS.SUCCESS:
      return {
        ...state,
        filterProducts: action.result,
        filterProductsLoading: false,
        filterProductsErr: null,
      };

    case ALL_PRODUCTS.FILTER_PRODUCTS.FAILURE:
      return {
        ...state,
        filterProducts: "",
        filterProductsLoading: false,
        filterProductsErr: action.result,
      };

    case ALL_PRODUCTS.RELATED_PRODUCTS.MAIN:
      return {
        ...state,
        relatedProducts: "",
        relatedProductsLoading: true,
        relatedProductsErr: null,
      };

    case ALL_PRODUCTS.RELATED_PRODUCTS.SUCCESS:
      return {
        ...state,
        relatedProducts: action.result,
        relatedProductsLoading: false,
        relatedProductsErr: null,
      };

    case ALL_PRODUCTS.RELATED_PRODUCTS.FAILURE:
      return {
        ...state,
        relatedProducts: "",
        relatedProductsLoading: false,
        relatedProductsErr: action.result,
      };

    case ALL_PRODUCTS.SEARCH_PRODUCTS.MAIN:
      return {
        ...state,
        searchProducts: "",
        searchProductsLoading: true,
        searchProductsErr: null,
      };

    case ALL_PRODUCTS.SEARCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        searchProducts: action.result,
        searchProductsLoading: false,
        searchProductsErr: null,
      };

    case ALL_PRODUCTS.SEARCH_PRODUCTS.FAILURE:
      return {
        ...state,
        searchProducts: "",
        searchProductsLoading: false,
        searchProductsErr: action.result,
      };
    case ALL_PRODUCTS.CHILD_CATEGORY.MAIN:
      return {
        ...state,
        childCategory: "",
        childCategoryLoading: true,
        childCategoryErr: null,
      };

    case ALL_PRODUCTS.CHILD_CATEGORY.SUCCESS:
      return {
        ...state,
        childCategory: action.result,
        childCategoryLoading: false,
        childCategoryErr: null,
      };

    case ALL_PRODUCTS.CHILD_CATEGORY.FAILURE:
      return {
        ...state,
        childCategory: "",
        childCategoryLoading: false,
        childCategoryErr: action.result,
      };
    case ALL_PRODUCTS.CLEAR_SEARCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        searchProducts: '',
        searchProductsLoading: false,
        searchProductsErr: null,
        relatedProducts: "",
        relatedProductsLoading: false,
        relatedProductsErr: null,
        filterProducts: "",
        filterProductsLoading: false,
        filterProductsErr: null,
        childCategory: "",
        childCategoryLoading: false,
        childCategoryErr: null
      };

    default:
      return state;
  }
}
