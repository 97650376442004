import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";
import { ALL_PRODUCTS } from "../../constants/allProducts";

function* fetchAllProductsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.FEATURE_PRODUCT,
      actions.params
    ); //true when Json Server
    yield put({
      type: ALL_PRODUCTS.ALL_PRODUCTS_DATA.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ALL_PRODUCTS.ALL_PRODUCTS_DATA.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchSearchProducts(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.SEARCH_PRODUCT,
        actions.params
    ); //true when Json Server
    yield put({
      type: ALL_PRODUCTS.SEARCH_PRODUCTS.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ALL_PRODUCTS.SEARCH_PRODUCTS.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchRelatedProducts(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.RELATED_PRODUCT,
        actions.params
    ); //true when Json Server
    yield put({
      type: ALL_PRODUCTS.RELATED_PRODUCTS.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ALL_PRODUCTS.RELATED_PRODUCTS.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchFilterData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.FILTER_PRODUCT,
        actions.params
    ); //true when Json Server
    yield put({
      type: ALL_PRODUCTS.FILTER_PRODUCTS.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ALL_PRODUCTS.FILTER_PRODUCTS.FAILURE,
      result: err.response.data,
    });
  }
}

function* fetchChildCategoryData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.FETCH_CHILD_CATEGORIES,
        actions.params
    ); //true when Json Server
    yield put({
      type: ALL_PRODUCTS.CHILD_CATEGORY.SUCCESS,
      result: result.data,
      message: "Data Fetched",
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ALL_PRODUCTS.CHILD_CATEGORY.FAILURE,
      result: err.response.data,
    });
  }
}

export default function* allProductsSagas() {
  yield takeLatest(ALL_PRODUCTS.ALL_PRODUCTS_DATA.MAIN, fetchAllProductsData);
  yield takeLatest(ALL_PRODUCTS.SEARCH_PRODUCTS.MAIN, fetchSearchProducts);
  yield takeLatest(ALL_PRODUCTS.RELATED_PRODUCTS.MAIN, fetchRelatedProducts);
  yield takeLatest(ALL_PRODUCTS.FILTER_PRODUCTS.MAIN, fetchFilterData);
  yield takeLatest(ALL_PRODUCTS.CHILD_CATEGORY.MAIN, fetchChildCategoryData);
}
