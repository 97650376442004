import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "15px 20px",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
  boxItem: {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textStyle: {
    fontSize: 14,
    color: "#7b7b7b",
    margin: "5px 0",
    "&:hover":{
      color: "#32aeb1",
    }
  },
}));

const Categories = ({childCategoryData, setCategoryID}) => {
  const classes = useStyles();
  const history = useHistory()
  const categories = childCategoryData ? childCategoryData.length ? childCategoryData : [] : []

  let handleFilterCategory = (id) =>{
    //setCategoryID(id)
    history.push(`/products/cat=${id}`)
  }

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: 16, color: "#1b1d1e", fontWeight: "500" }}
      >
        PRODUCT CATEGORIES
      </Typography>

      {categories.map((item) => {
        let CategoryDescriptions = item ? item.CategoryDescriptions ? item.CategoryDescriptions.length ?
            item.CategoryDescriptions[0] : '' : '' : ''
        return(
            <Box className={classes.boxItem} onClick={()=>handleFilterCategory(CategoryDescriptions.category_id)}>
              <Typography className={classes.textStyle}>{CategoryDescriptions.name}</Typography>
              {/*<Typography className={classes.textStyle}>({item.qty})</Typography>*/}
            </Box>
        )
      })}
    </div>
  );
};

export default Categories;
