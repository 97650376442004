import { ALL_PRODUCTS } from "../../constants/allProducts";

export function fetchAllProductsData(params) {
  return {
    type: ALL_PRODUCTS.ALL_PRODUCTS_DATA.MAIN,
    params: params,
  };
}

export function fetchFilterProductsData(params) {
  return {
    type: ALL_PRODUCTS.FILTER_PRODUCTS.MAIN,
    params: params,
  };
}

export function fetchSearchProduct(params) {
  return {
    type: ALL_PRODUCTS.SEARCH_PRODUCTS.MAIN,
    params: params,
  };
}

export function fetchChildCategory(params) {
  return {
    type: ALL_PRODUCTS.CHILD_CATEGORY.MAIN,
    params: params,
  };
}

export function fetchRelatedProduct(params) {
  return {
    type: ALL_PRODUCTS.RELATED_PRODUCTS.MAIN,
    params: params,
  };
}

export function clearSearchProduct() {
  return {
    type: ALL_PRODUCTS.CLEAR_SEARCH_PRODUCTS.SUCCESS
  };
}
