import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Divider, Grid, Typography,} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import styled from "styled-components";
import Counter from "../../addToCart/components/Counter";
import {Link, useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    addItemToCart,
    addItemToCartServer,
    addItemToWishlist, clearCart,
    clearCheckout,
    removeItemFromCart, updateItemToCartServer,
} from "../../../redux/actions/cart";
import {clearOrder} from "../../../redux/actions/order";
import querystring from "querystring";

let checkCartAvailable = (cart, id) => {
    if (cart) {
        if (cart.length) {
            let found = cart.find((item) => item.cartItem.product_id === id);
            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

let checkWishlistAvailable = (wishlist, id) => {
    if (wishlist) {
        if (wishlist.length) {
            let found = wishlist.find((item) => item.product_id === id);

            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "0 1.5%",
        "& .MuiTypography-body1": {
            fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
        },
    },
    mainPrice: {
        color: "#c91f28",
        fontWeight: "600",
        fontSize: 14,
    },
    prePrice: {
        color: "#7b7b7b",
        fontWeight: "300",
        fontSize: 14,
        textDecoration: "line-through",
        paddingLeft: "10%",
    },
    secondary: {
        fontSize: 12,
        fontWeight: "500",
        color: "#293341",
    },
    subTitle: {
        fontSize: 14,
        color: "#293341",
        fontWeight: "500",
    },
    subValue: {
        fontSize: 14,
        color: "#7b7b7b",
    },
    subValueActive: {
        fontSize: 14,
        color: "#27ae5f",
    },
    button: {
        height: "40px",
        position: "relative",
        width: "130px",
        borderRadius: "4px",
        fontSize: "12px",
        background: "linear-gradient(90deg, #1f8184 30%, rgb(30 195 170) 30% 100%)",
        color: "#fff",
        border: "none",
    },
    buttonOnCart: {
        height: "40px",
        position: "relative",
        width: "130px",
        borderRadius: "4px",
        fontSize: "12px",
        background: "linear-gradient(90deg, #dc3545 30%, rgb(30 195 170) 30% 100%)",
        color: "#fff",
        border: "none",
    },
    divider: {
        backgroundColor: "#f4f4f4",
        marginTop: "30px",
        marginBottom: "30px",
        height: "2px",
    },
    buyButton: {
        borderRadius: "0",
        border: "2px solid black",
        maxHeight: "40px",
        width: "100%",
        color: "#23232c",
        fontSize: 10,
        fontWeight: "500",
        fontFamily: `"Poppins", "Roboto"`,
    },
}));

function getCurrent(cart, product_id) {
    let found = cart.length
        ? cart.find((item) => item.cartItem.product_id === product_id)
        : null;

    return found;
}

const ProductInfo = ({
                         data,
                         description,
                         ProductManufacturer,
                         ProductAttributes,
                         authData,token,
                         addItemToCart,
                         addItemToCartServer,
                         addItemToWishlist,
                         removeItemFromCart,
                         cart,cus_cart,updateItemToCartServer,
                         wishlist, clearCheckout, clearOrder,clearCart
                     }) => {
    const classes = useStyles();
    const history = useHistory();
    let isCart = checkCartAvailable(cart, data.product_id);

    let addToCart = (item) => {
        let currentItem = getCurrent(cart, item.product_id);
        let count = currentItem ? currentItem?.count ? currentItem?.count : 0 : 0
        if (parseInt(count) < parseInt(data?.quantity)){
            addItemToCart(item);
            if (authData) {
                if (cus_cart) {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        cart_id : cus_cart?.data?.cart_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    updateItemToCartServer(data,authData?.data?.token)
                } else {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        product_id: currentItem?.cartItem?.product_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    addItemToCartServer(data,authData?.data?.token)
                }
            }
        }
    };

    let updateRemoveCardItem = (item) =>{
        removeItemFromCart(item)
        //clearCart()
        if (authData) {
            if (cart.length > 0) {
                if (isCart){
                    let currentItem = getCurrent(cart, item.product_id);
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        cart_id : cus_cart?.data?.cart_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    updateItemToCartServer(data,authData?.data?.token)
                }
            } else {
                clearCart()
            }
        }
    }

    let addToCartBuyNow = (item) => {
        let currentItem = getCurrent(cart, item.product_id);
        let count = currentItem ? currentItem?.count ? currentItem?.count : 0 : 0
        if (parseInt(count) < parseInt(data?.quantity)){
            addItemToCart(item);
            if (authData) {
                if (cus_cart) {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        cart_id : cus_cart?.data?.cart_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    updateItemToCartServer(data,authData?.data?.token)
                } else {
                    let data = querystring.stringify({
                        customer_id: authData?.data?.userId,
                        customer_auth: 'customer_auth',
                        product_id: currentItem?.cartItem?.product_id,
                        quantity: currentItem?.count,
                        option: 'text me'
                    })
                    addItemToCartServer(data,authData?.data?.token)
                }
            }
            clearCheckout()
            clearOrder()
            history.push('/your-cart')
        } else {
            clearCheckout()
            clearOrder()
            history.push('/your-cart')
        }

    };

    let addToWishlist = (item) => {
        addItemToWishlist(item);
    };

    let isWish = checkWishlistAvailable(wishlist, data.product_id);

    let quantity = data.quantity ? data.quantity : "";
    let productPrice = data.price ? data.price : "";
    let ProductDescriptions = data.ProductDescriptions
        ? data.ProductDescriptions
        : "";

    let price = Number(productPrice);
    //const [qty, setQty] = useState(1);

    let currentItem = getCurrent(cart, data.product_id);
    let qty = currentItem ? currentItem.count : 1;

    let catagoryList = data.ProductCategoryList ? data.ProductCategoryList : [];
    let isPharma = catagoryList.find((item) => item.category_id == 1)
        ? true
        : false;

    let category = [];
    catagoryList.map((item) => {
        let data = item.CategoryDescData?.name;
        category.push(data);
    });
    const stringData = category.reduce((result, item) => {
        return `${result}${item},`;
    }, "");

    const pType = ProductAttributes.find((p) => p.attribute_id === 1);
    const pStrength = ProductAttributes.find((p) => p.attribute_id === 2);
    const pUnit = ProductAttributes.find((p) => p.attribute_id === 3);
    const pGroup = ProductAttributes.find((p) => p.attribute_id === 4);
    const pOtc = ProductAttributes.find((p) => p.attribute_id === 7);

    console.log(data)

    return (
        <div className={classes.paper}>
            <Typography
                style={{color: "#293341", fontSize: 18, fontWeight: "500"}}
            >
                {ProductDescriptions.name}
            </Typography>

            {/* <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Rating size=".61vw" name="read-only" value={3} readOnly />
        <span style={{ color: "#7b7b7b", fontSize: ".61vw" }}>
          (15 reviews)
        </span>
      </Box> */}

            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{padding: "10px 0 0 0"}}
            >
                <Typography className={classes.mainPrice}>
                    TK {parseFloat(productPrice).toFixed(2)} /PCS
                </Typography>
                {/*<Typography className={classes.prePrice}>TK {productPrice}</Typography>*/}
            </Box>

            {/*<Box style={{ padding: "10px 0 0 0" }}>*/}
            {/*  <CountdownOffer />*/}
            {/*</Box>*/}

            {/*<Box style={{ padding: "10px 0 0 0" }}>*/}
            {/*  <Typography style={{ color: "#7b7b7b", fontSize: ".81vw" }}>*/}
            {/*    {ReactHtmlParser(ReactHtmlParser(description.description))}*/}
            {/*    /!*<p>{ReactHtmlParser(description.description)}</p>*!/*/}
            {/*  </Typography>*/}
            {/*</Box>*/}

            {/* <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: "10px 0 0 0" }}
      >
        <Typography className={classes.secondary}>Quantity:</Typography>

        <input
          type="number"
          value={quantity}
          style={{
            width: "3.39vw",
            backgroundColor: "#f9f9f9",
            border: "none",
            fontSize: ".74vw",
            padding: "1%",
          }}
        />
      </Box> */}

            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                style={{padding: "10px 0 0 0"}}
            >
                {
                    isCart ? (
                        <>
                            <Typography className={classes.secondary}>Price:</Typography>&nbsp;
                            <Typography
                                style={{
                                    backgroundColor: "#f9f9f9",
                                    border: "1x solid #f6f6f6",
                                    fontSize: 12,
                                    color: "#DF1A33",
                                }}
                            >
                                &nbsp;{qty > 0 ? parseFloat(price * qty).toFixed(1) : price}&nbsp;
                            </Typography>
                            <Typography className={classes.secondary}>TK</Typography>
                        </>
                    ) : null
                }
            </Box>

            {
                parseInt(data?.quantity) !== 0 ?  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{padding: "10px 0 0 0", maxWidth: "18.75rem"}}
                >
                    {/*<GroupedButtons*/}
                    {/*  count={qty}*/}
                    {/*  incrementCount={() => setQty(qty + 1)}*/}
                    {/*  decrimentCount={() => setQty(qty - 1)}*/}
                    {/*/>*/}
                    {isCart ? (
                        <>
                            <Counter
                                item={data}
                                variant="lightHorizontal"
                                value={currentItem.count}
                                onIncrement={addToCart}
                                onDecrement={updateRemoveCardItem}
                            />
                            <span style={{padding: "0 5px", fontSize: 10}}>
              {pUnit?.text}
            </span>
                        </>
                    ) : null}
                    {isCart ? (
                        <button
                            type="button"
                            className={classes.buttonOnCart}
                            onClick={() => addToCart(data)}
                        >
                            <ShoppingCartIcon/>
                            &nbsp; &nbsp;ADD TO CART
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={classes.button}
                            onClick={() => addToCart(data)}
                        >
                            <ShoppingCartIcon/>
                            &nbsp; &nbsp;ADD TO CART
                        </button>
                    )}
                </Box> : null
            }

            {
                parseInt(currentItem?.count) === parseInt(data?.quantity) ?
                    <Typography className={classes.secondary}>You reached end of limit.</Typography> : null
            }

            {
                parseInt(data?.quantity) !== 0 ?
                    <Box style={{padding: "10px 0 0 0", maxWidth: "18.75rem"}}>
                        <Link>
                            <Button
                                variant="outlined"
                                className={classes.buyButton}
                                onClick={() => addToCartBuyNow(data)}
                            >
                                BUY NOW
                            </Button>
                        </Link>
                        <Divider classes={{root: classes.divider}}/>
                    </Box> : null
            }


            {isPharma && (
                <Grid container>
                    <Grid item xs={5}>
                        <Typography className={classes.subTitle}>Strength:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography className={classes.subValue}>
                            {pStrength?.text}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <Grid container>
                <Grid item xs={5}>
                    <Typography className={classes.subTitle}>SKU:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.subValue}>{data.sku}</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={5}>
                    <Typography className={classes.subTitle}>Availability:</Typography>
                </Grid>
                <Grid item xs={7}>
                    {
                        parseInt(data?.quantity) === 0 ?
                            <Typography className={classes.subValueActive} style={{color: '#c91f28'}}>Out of Stock</Typography> :  <Typography className={classes.subValueActive}>In Stack</Typography>
                    }

                </Grid>
            </Grid>

            {isPharma && (
                <Grid container>
                    <Grid item xs={5}>
                        <Typography className={classes.subTitle}>Group:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography className={classes.subValue}>{pGroup?.text}</Typography>
                    </Grid>
                </Grid>
            )}

            <Grid container>
                <Grid item xs={5}>
                    <Typography className={classes.subTitle}>Categories:</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography className={classes.subValue}>{stringData}</Typography>
                </Grid>
            </Grid>

            {isPharma && (
                <>
                    <Grid container>
                        <Grid item xs={5}>
                            <Typography className={classes.subTitle}>Company:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography className={classes.subValue}>
                                {ProductManufacturer.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={5}>
                            <Typography className={classes.subTitle}>Type:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography className={classes.subValue}>
                                {pType?.text}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={5}>
                            <Typography className={classes.subTitle}>Is OTC:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography className={classes.subValue}>{pOtc?.text}</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

const InputSpin = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    margin: 0;
  }
`;

function mapStateToProps(state) {
    return {
        cart: state.cartReducer.cart,
        cus_cart: state.cartReducer.cus_cart,
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        wishlist: state.cartReducer.wishlist,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addItemToCartServer: (data,token) => dispatch(addItemToCartServer(data,token)),
        updateItemToCartServer: (data,token) => dispatch(updateItemToCartServer(data,token)),
        addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
        removeItemFromCart: (data) => dispatch(removeItemFromCart(data)),
        clearCheckout: () => dispatch(clearCheckout()),
        clearOrder: () => dispatch(clearOrder()),
        clearCart: () => dispatch(clearCart())
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductInfo)
);