import React from "react";
import WishlistButton from "../../../components/WishlistButton";
import {Col, Form, Row} from "react-bootstrap";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import {withStyles} from "@material-ui/core/styles";
import { authStyle } from "./authStyle";

let InformationField = (props) => {
    let {
        classes,
        firstName,
        lastName,
        handleFirstName,
        handleLastName,
        handleCancel,
        handleSubmit,
    } = props;
    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={firstName}
                            onChange={handleFirstName}
                            placeholder={"Your First Name"}
                        />
                    </Col>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={lastName}
                            placeholder={"Your Last Name"}
                            onChange={handleLastName}
                        />
                    </Col>
                    <Col className="button-field" md={6} sm={12}>
                        <Row>
                            <Col md={12} sm={12}>
                                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
                            </Col>
                            <Col md={12} sm={12}>
                                <NewButtonSubmit
                                    handleSubmit={handleSubmit}
                                    text={"Save Changes"} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
};

let styles = (theme) => authStyle(theme);

export default withStyles(styles)(InformationField);
