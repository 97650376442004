import React, { useEffect, useRef, useState } from "react";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import CompareArrowsOutlinedIcon from "@material-ui/icons/CompareArrowsOutlined";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { makeStyles } from "@material-ui/core/styles";
import placeholder from "../../../../localization/images/product-placeholder.jpeg";
import placeholderOld from "../../../../localization/images/nonPharma.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiSvgIcon-root": {
      width: "1.9vw",
      height: "1.9vw",
      color: "#747474",
    },
  },
}));

const ImageCarousel = ({ images, isPharma, imgName }) => {
  const classes = useStyles();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  useEffect(() => {
    defImage();
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      );

      setSelectedImageIndex(0);
      if (imgName != null && imgName.length > 0) {
        let newImg = images[0].url?.split(" ").join("%20");
        setSelectedImage({ id: images[0].id, url: newImg });
      }
    }
  }, [images]);

  const defImage = () => {
    if (isPharma === "pharma") {
      setSelectedImage({ url: placeholder });
    }
    if (isPharma === "nonPharma") {
      setSelectedImage({ url: placeholderOld });
    }
  };

  let m = imgName?.split(" ").join("%20");

  const convertImg = (oldUrl) => {
    return oldUrl?.split(" ").join("%20");
  };

  const handleSelectedImageChange = (newIdx) => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        // carouselItemsRef?.current[newIdx]?.scrollIntoView({
        //   inline: "center",
        //   behavior: "smooth",
        // });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="selected-image"
        style={{ backgroundImage: `url(${selectedImage?.url})` }}
      >
        {/* <button className="saveBtn">SAVE</button>
        <button className="newBtn">NEW</button>
        <div className={classes.paper}>
          <div className="rightIcon">
            <span style={{ padding: ".73vw 0" }}>
              <FullscreenIcon />
            </span>
            <span style={{ padding: ".73vw 0" }}>
              <FavoriteBorderOutlinedIcon />
            </span>
            <span style={{ padding: ".73vw 0" }}>
              <CompareArrowsOutlinedIcon />
            </span>
          </div>
        </div> */}
      </div>
      {imgName && (
        <div className="carousel">
          <div className="carousel__images">
            {images &&
              images?.map((image, idx) => (
                <div
                  onClick={() => handleSelectedImageChange(idx)}
                  style={{ backgroundImage: `url(${convertImg(image.url)})` }}
                  key={image.id}
                  className={`carousel__image ${
                    selectedImageIndex === idx && "carousel__image-selected"
                  }`}
                  ref={(el) => (carouselItemsRef.current[idx] = el)}
                />
              ))}
          </div>
          <button
            className="carousel__button carousel__button-left"
            onClick={handleLeftClick}
          >
            &lt;
          </button>
          <button
            className="carousel__button carousel__button-right"
            onClick={handleRightClick}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
