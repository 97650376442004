import React from 'react';
import styled from "styled-components";
import BannerBg from '../../../localization/images/achievements.jpg';
import {Col, Container, Row} from "react-bootstrap";


const Banner = ({text}) => {
    return (
        <StyledSlide>
            <div className="banner-inner ">
                <div className="banner-inner__inner">
                    <img src={BannerBg} alt="banner"/>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="banner-text-center">
                                    <h4>{text}</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </StyledSlide>
    )
}

const StyledSlide = styled.section`
  position: relative;

  .banner-inner {
    width: 100%;

    &__inner {
      padding-top: 21.96%;
      position: relative;
      width: 100%;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.16);
        // background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.469625350140056) 54%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
      }

      a {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        position: absolute;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .banner-text-center {
      position: absolute;
      bottom: 114px;
      z-index: 2;
      left: 50%;
      transform: translate(-50%, -50%);

      h4 {
        font-size: 44px;
        font-weight: 500;
        font-family: Cormorant Garamond, serif;
        margin: 0;
        color: #ffffff;

      }

      h3 {
        color: #ffffff;
        margin: 0;
        font-family: Cormorant Garamond, serif;
        font-size: 44px;
        line-height: 60px;
        font-weight: bold;
      }

      p {
        font-size: 24px;
        color: #ffffff;
        margin: 0;
      }

    }

  }


  .radius-text {
    position: absolute;
    left: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left center;
    color: rgba(233, 233, 233, 0.80);
    height: fit-content;
    width: fit-content;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.5px;
  }

  @media (max-width: 1366px) {
    .banner-inner .banner-text-center {
      bottom: 65px;
    }
  }

  @media (max-width: 991px) {
    .banner-text h1 {
      font-size: 45px;
      line-height: 50px;
    }

    .banner-inner .banner-text-center {
      bottom: 45px;
    }
  }

  @media (max-width: 768px) {
    .container {
      overflow: visible;
    }
    .banner-inner {
      margin-top: 45px;
    }
    .banner-inner__inner {
      padding-top: 48.26%;

      .banner-text-center {
        bottom: 150px;
        left: 363px;

        h4 {
          font-size: 17px;

        }

        h3 {
          font-size: 26px;
          line-height: 34px;
        }

        p {
          font-size: 17px;
        }
      }

      .banner-button {
        bottom: 30px;
        right: 15px;

        .dc-btn {
          height: 26px !important;
          width: 74px;

          a {
            font-size: 12px !important;
          }
        }
      }

    }

    .radius-text {
      display: none;
    }

    .banner-text {
      text-align: left;
      left: 15px;
      right: 15px;
      margin: auto 0;
      bottom: 50px;
      top: auto;

      &:before, &:after {
        display: none;
      }

      h4 {
        font-size: 12px;
        line-height: 15px;
        margin: 0 0 12px 0;
      }

      h1 {
        font-size: 34px;
        line-height: 40px;
      }
    }

  }

  @media (max-width: 426px) {
    .banner-inner__inner {
      padding-top: 48.26%;

      .banner-text-center {
        bottom: 74px;
        left: 192px;
      }

    }

  }

  @media (max-width: 322px) {
    .banner-inner__inner {
      padding-top: 48.26%;

      .banner-text-center {
        bottom: 60px;
        left: 160px;
      }

    }

  }

`;


export default Banner;