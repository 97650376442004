import React, {useEffect} from 'react';
import StoryCards from "./components/BlogCard";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import {ParallaxProvider} from "react-scroll-parallax";
import Banner from "./components/Banner";

const Blog = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <ParallaxProvider>
                <Banner text={'Blogs'}/>
                <StoryCards/>
            </ParallaxProvider>
        </motion.div>
    );
};

export default Blog;