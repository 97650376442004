import React, {useEffect, useRef, useState} from "react";
import {fetchOrderList} from "../../redux/actions/order";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import styled from "styled-components";
import ReactToPrint from 'react-to-print';
import Title from "../../components/Title";
import {CircularProgress} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import OrderList from "./components/OrderList";
import OrderDetails from "./components/OrderDetails";
import MobileOrderHistory from "./components/MobileOrderHistory";

let statusCheck = current => {
    if(current === 1) return { name: 'Order Pending', id: 0 }
    else if(current === 2) return {name: 'Order Processing', id: 1}
    else if(current === 3) return {name: 'Order Picked Up For Delivery', id: 2 }
    else if(current === 18) return {name: 'Payment Complete', id: 3}
    else if(current === 5) return {name: 'Delivered', id: 4}
    else if(current === 7) return {name: 'Canceled', id: 5}
    else if(current === 10) return {name: 'Failed', id: 6}
    else return { name: '', id: null}
}

let statusCheckOnline = current => {
    if(current === 1) return { name: 'Order Pending', id: 0 }
    else if(current === 2) return {name: 'Order Processing', id: 1}
    else if(current === 18) return {name: 'Payment Complete', id: 2}
    else if(current === 3) return {name: 'Order Picked Up For Delivery', id: 3 }
    else if(current === 5) return {name: 'Delivered', id: 4}
    else if(current === 7) return {name: 'Canceled', id: 5}
    else if(current === 10) return {name: 'Failed', id: 6}
    else return { name: '', id: null}
}

const Order = ({authData,token,fetchOrderList, orderList, orderListLoading, orderListErr}) =>{
    const componentRef = useRef();
    useEffect(() => {
        let params = {
            [ApiParams.customer_id]: authData.data.userId,
            [ApiParams.customer_auth]: authData?.data?.token,
            [ApiParams.limit]: "100",
        };
        fetchOrderList(params,authData?.data?.token);
    }, []);

    let orders = orderList ?
        orderList.data ?
            orderList.data.length ?
                orderList.data :
                [] :
            [] :
        []

    let [active, setActive] = useState(0)

    return(
        <StyledOrderMain orders={orders.length}>
            <Title
                text={'Track Order'}
                color={'#32AEB0'}
                fontSize={12}
                lineHeight={18}
                fontWeight={'500'}
                textAlign={'left'}
            />
            <Title
                text={'Order history'}
                color={'#313132'}
                fontSize={24}
                lineHeight={40}
                fontWeight={'500'}
                textAlign={'left'}
                margin={'0 0 30px 0'}
            />
            {orders ? orders.length ? (
                <>
                    <Row>
                        <Col lg={3} md={12} className="desktop-view">
                            <OrderList
                                active={active}
                                setActive={setActive}
                                statusCheck={statusCheck}
                                statusCheckOnline={statusCheckOnline}
                            />
                        </Col>
                        <Col lg={9} md={12} className="desktop-view">
                            <OrderDetails
                                componentRef={componentRef}
                                active={orders[active]}
                                statusCheck={statusCheck}
                                statusCheckOnline={statusCheckOnline}
                                onPrint={componentRef}
                            />
                        </Col>
                        <Col md={12} className="mobile-view">
                            <MobileOrderHistory
                                active={active}
                                setActive={setActive}
                                orders={orders}
                                statusCheck={statusCheck}
                                statusCheckOnline={statusCheckOnline}
                                onPrint={componentRef}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <div>
                    {
                        orderListLoading === true ? <div className="loader">
                            <CircularProgress />
                        </div> : null
                    }
                </div>
            ) : null}
        </StyledOrderMain>
    )
}

let StyledOrderMain = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0 55px;
  height: ${props => props.orders ? 'auto' : '120vh'};

  .loader {
    margin-top: 15%;
    text-align: center;
  }
  h2 {
    font-size: 12px !important;
  }

  .desktop-view{
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
  
  @media(max-width: 1280px){
    padding: 55px 55px;
    .title {
      font-size: 12px;
    }
  }
  @media(max-width: 991px){
    .mobile-view{
      margin-top: 24px;
    }
  }
  @media(max-width: 959px){
    padding: 0px 55px;
  }
  @media(max-width: 767px){
    .mobile-view{
      margin-top: 24px;
    }
    .title {
      font-size: 12px;
    }
  }
`

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        orderList: state.oderReducer.orderList,
        orderListLoading: state.oderReducer.orderListLoading,
        orderListErr: state.oderReducer.orderListErr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchOrderList: (params,token) => dispatch(fetchOrderList(params,token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
