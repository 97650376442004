import { HOME } from "../../constants/home";

const initialState = {
  brand: "",
  brandLoading: false,
  brandErr: null,

  todayProduct: "",
  todayProductLoading: false,
  todayProductErr: null,

  essentialProduct: "",
  essentialProductLoading: false,
  essentialProductErr: null,

  featureProduct: "",
  featureProductLoading: false,
  featureProductErr: null,

  featureBrand: "",
  featureBrandLoading: false,
  featureBrandErr: null,

  concernProduct: "",
  concernProductLoading: false,
  concernProductErr: null,

  expConcern: "",
  expConcernLoading: false,
  expConcernErr: null,

  specialData: "",
  specialDataLoading: false,
  specialDataErr: null,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.BRAND_DATA.MAIN:
      return {
        ...state,
        brand: "",
        brandLoading: true,
        brandErr: null,
      };

    case HOME.BRAND_DATA.SUCCESS:
      return {
        ...state,
        brand: action.result,
        brandLoading: false,
        brandErr: null,
      };

    case HOME.BRAND_DATA.FAILURE:
      return {
        ...state,
        brand: "",
        brandLoading: false,
        brandErr: action.result,
      };
    case HOME.TODAY_PRODUCT_DATA.MAIN:
      return {
        ...state,
        todayProduct: "",
        todayProductLoading: true,
        todayProductErr: null,
      };

    case HOME.TODAY_PRODUCT_DATA.SUCCESS:
      return {
        ...state,
        todayProduct: action.result,
        todayProductLoading: false,
        todayProductErr: null,
      };

    case HOME.TODAY_PRODUCT_DATA.FAILURE:
      return {
        ...state,
        todayProduct: "",
        todayProductLoading: false,
        todayProductErr: action.result,
      };
    case HOME.ESSENTIAL_PRODUCT_DATA.MAIN:
      return {
        ...state,
        essentialProduct: "",
        essentialProductLoading: true,
        essentialProductErr: null,
      };

    case HOME.ESSENTIAL_PRODUCT_DATA.SUCCESS:
      return {
        ...state,
        essentialProduct: action.result,
        essentialProductLoading: false,
        essentialProductErr: null,
      };

    case HOME.ESSENTIAL_PRODUCT_DATA.FAILURE:
      return {
        ...state,
        essentialProduct: "",
        essentialProductLoading: false,
        essentialProductErr: action.result,
      };

    case HOME.FEATURE_PRODUCT_DATA.MAIN:
      return {
        ...state,
        featureProduct: "",
        featureProductLoading: true,
        featureProductErr: null,
      };

    case HOME.FEATURE_PRODUCT_DATA.SUCCESS:
      return {
        ...state,
        featureProduct: action.result,
        featureProductLoading: false,
        featureProductErr: null,
      };

    case HOME.FEATURE_PRODUCT_DATA.FAILURE:
      return {
        ...state,
        featureProduct: "",
        featureProductLoading: false,
        featureProductErr: action.result,
      };

    case HOME.FEATURE_BRAND_DATA.MAIN:
      return {
        ...state,
        featureBrand: "",
        featureBrandLoading: true,
        featureBrandErr: null,
      };

    case HOME.FEATURE_BRAND_DATA.SUCCESS:
      return {
        ...state,
        featureBrand: action.result,
        featureBrandLoading: false,
        featureBrandErr: null,
      };

    case HOME.FEATURE_BRAND_DATA.FAILURE:
      return {
        ...state,
        featureBrand: "",
        featureBrandLoading: false,
        featureBrandErr: action.result,
      };

    case HOME.CONCERN_PRODUCT_DATA.MAIN:
      return {
        ...state,
        concernProduct: "",
        concernProductLoading: true,
        concernProductErr: null,
      };

    case HOME.CONCERN_PRODUCT_DATA.SUCCESS:
      return {
        ...state,
        concernProduct: action.result,
        concernProductLoading: false,
        concernProductErr: null,
      };

    case HOME.CONCERN_PRODUCT_DATA.FAILURE:
      return {
        ...state,
        concernProduct: "",
        concernProductLoading: false,
        concernProductErr: action.result,
      };

    case HOME.EXP_CONCERN_DATA.MAIN:
      return {
        ...state,
        expConcern: "",
        expConcernLoading: true,
        expConcernErr: null,
      };

    case HOME.EXP_CONCERN_DATA.SUCCESS:
      return {
        ...state,
        expConcern: action.result,
        expConcernLoading: false,
        expConcernErr: null,
      };

    case HOME.EXP_CONCERN_DATA.FAILURE:
      return {
        ...state,
        expConcern: "",
        expConcernLoading: false,
        expConcernErr: action.result,
      };

    case HOME.SPECIAL_DATA.MAIN:
      return {
        ...state,
        specialData: "",
        specialDataLoading: true,
        specialDataErr: null,
      };

    case HOME.SPECIAL_DATA.SUCCESS:
      return {
        ...state,
        specialData: action.result,
        specialDataLoading: false,
        specialDataErr: null,
      };

    case HOME.SPECIAL_DATA.FAILURE:
      return {
        ...state,
        specialData: "",
        specialDataLoading: false,
        specialDataErr: action.result,
      };

    default:
      return state;
  }
}
