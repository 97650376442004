import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import {authStyle} from "./authStyle";
import {withStyles} from "@material-ui/core/styles";
import {updateAddress} from "../../../redux/actions/auth";
import {connect} from "react-redux";
import querystring from "querystring";

let AddressField = (props) => {
    let { classes, address, updateAddress,  authData,token,
        addressData, addressDataLoading, addressDataErr
    } = props;

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("Bangladesh");

    const [err, setErr] = useState("");

    useEffect(() => {
        if (address) {
            if (address.address_1) setAddress1(address.address_1);
            if (address.address_2) setAddress2(address.address_2);
            if (address.city) setCity(address.city);
        }
    }, [address]);

    const handleAddress1 = (e) => {
        setAddress1(e.target.value);
    };

    const handleAddress2 = (e) => {
        setAddress2(e.target.value);
    };

    const handleCity = (e) => {
        setCity(e.target.value);
    };

    const handleCountry = (e) => {
        setCountry(e.target.value);
    };

    const handleSubmit = () => {
        if (address1.length && city.length) {
            // call update api
            let data = querystring.stringify({
                customer_id: address.customer_id,
                customer_auth: 'authData.data.token',
                address_id: address.address_id,
                firstname: address.firstname,
                lastname: address.lastname,
                company: '',
                address_1: address1,
                address_2: address2,
                city: city,
                postcode: '1200'
            })
            updateAddress(data,authData?.data?.token)
        } else {
            setErr("One or more input fields are empty");
        }
    };

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>
                            Address Line 1
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={address1}
                            onChange={handleAddress1}
                            placeholder={"Your Address"}
                        />
                    </Col>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>
                            Address Line 2
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={address2}
                            placeholder={"Your Address"}
                            onChange={handleAddress2}
                        />
                    </Col>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>City</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={city}
                            onChange={handleCity}
                            placeholder={"Your City"}
                        />
                    </Col>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>Country</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={country}
                            placeholder={"Your Country"}
                            onChange={handleCountry}
                        />
                    </Col>
                    <Col className="button-field" md={6} sm={12}/>
                    <Col className="button-field" md={6} sm={12}>
                        <Row>
                            <Col md={12} sm={12}>
                                <NewButtonSubmit
                                    handleSubmit={handleSubmit}
                                    text={"Save Changes"}
                                />
                            </Col>
                        </Row>
                        {err.length ? (
                            <Title
                                text={err}
                                color={"red"}
                                margin={"10px 0 0 0"}
                                fontSize={14}
                                lineHeight={20}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
};

let styles = (theme) => authStyle(theme);

function mapStateToProps(state) {
    return {
        authData: state.authReducer.authData,
        token: state.authReducer.token,
        addressData: state.authReducer.addressData,
        addressDataLoading: state.authReducer.addressDataLoading,
        addressDataErr: state.authReducer.addressDataErr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateAddress: (data,token) => dispatch(updateAddress(data,token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddressField));
