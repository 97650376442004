import React, { useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Product from "./Product";
import { featureData } from "../../../localization/data/products";
import { connect } from "react-redux";
import { fetchFeatureProductData } from "../../../redux/actions/home";
import { ApiParams } from "../../../constants/apiParams";
import { Link } from "react-router-dom";
import RightIcon from "../../../localization/images/navRightBlack.svg";
import LeftIcon from "../../../localization/images/navLeftBlack.svg";
import Swiper from "react-id-swiper";

const FeatureProduct = ({
  title,
  category,
  getFetchFeatureProductData,
  featureProductData,
}) => {
  useEffect(() => {
    let params = {
      [ApiParams.category_id]: "17",
      [ApiParams.limit]: "6",
      [ApiParams.offset]: "0",
    };
    getFetchFeatureProductData(params);
  }, []);

  let data = featureProductData ? featureProductData.data : null;
  let ProductToCategories = data ? data.ProductToCategories : [];
  let size = 4;
  let featureProduct = ProductToCategories.slice(0, size);

  // let catagoryList = data.ProductCategoryList ? data.ProductCategoryList : [];
  // let isPharma = catagoryList.find((item) => item.category_id == 1)
  //   ? "pharma"
  //   : "nonPharma";
  let isPharma = "pharma";

  const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      960: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1501: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  return (
    <>
      {featureProduct.length ? (
        <StyledFeature>
          <Container className="container">
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: 24,
                    paddingTop: 24,
                  }}
                >
                  <div className="title">{title}</div>
                  <div
                    style={{
                      marginLeft: "auto",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="viewBtn"
                  >
                    <Link
                      to={`/products/cat=${17}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: 12, fontWeight: 400 }}>
                        View all
                      </div>
                      <NavigateNextIcon />
                    </Link>
                  </div>
                </div>
                {featureProduct ? (
                  <Swiper {...params}>
                    {featureProduct.map((item) => {
                      return (
                        <div className="news-events-slider__single">
                          <Product item={item} isPharma={isPharma} />
                        </div>
                      );
                    })}
                  </Swiper>
                ) : null}
              </Col>
            </Row>
          </Container>
        </StyledFeature>
      ) : null}
    </>
  );
};

const StyledFeature = styled.div`
  padding-bottom: 32px;
  .container {
    max-width: 95%;
  }
  .viewBtn {
    color: #313132;
  }
  .viewBtn:hover {
    color: #1ec3aa;
  }
  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }
  .title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    color: #2fa5aa;
  }

  .column-cl {
    margin-bottom: 30px;
  }
`;
function mapStateToProps(state) {
  return {
    featureProductData: state.homeReducer.featureProduct,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchFeatureProductData: (params) =>
      dispatch(fetchFeatureProductData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureProduct);
