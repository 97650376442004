import React, { useEffect } from "react";
import Logo from "../../localization/images/brandlogo.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { login, logout, sendOTP } from "../../redux/actions/auth";
import { connect } from "react-redux";
import querystring from "querystring";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "20px 10px",
    maxWidth: "340px",
  },
  bigImg: {
    height: "100px",
    width: "100px",
  },
  btnIcon: {
    height: "13px",
    width: "13px",
  },
  bigText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#32aeb1",
    paddingBottom: "20px",
  },
  smallText: {
    fontSize: "12px",
    color: "#090f47",
  },
  orderBtn: {
    border: "1px solid #32aeb1",
    fontSize: "12px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: "50px",
    padding: "10px 0",
    width: "250px",
  },
  resize: {
    fontSize: "12px",
  },
}));

const LoginForm = ({
  handleClose,
  moveToSignUp,
  moveToRecoverPhone,
  authData,
  authDataLoading,
  authDataErr,
  login,
  logout,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = React.useState("");
  const [signErr, setSignErr] = React.useState(null);

  const moveTo = () => {
    moveToSignUp();
    logout();
  };

  const handleForgetPassword = () => {
    moveToRecoverPhone();
  };

  const handleChangeUser = (e) => {
    setUsername(e.target.value);
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChangePass = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAction = (event) => {
    event.preventDefault();
    if (username.length && values.password.length) {
      let data = querystring.stringify({
        phone_email: username,
        password: values.password,
      });
      login(data);
    }
  };

  useEffect(() => {
    if (authData) {
      if (authData.status === 200) {
        handleClose(false);
      } else {
        setSignErr(authData.message);
      }
    }
    if (authDataErr) {
      setSignErr("An error occurred when signing up");
    }
  }, [authData, authDataErr]);

  return (
    <div className={classes.paper}>
      <div style={{ paddingBottom: "5px" }}>
        <center>
          <img
            src={Logo}
            style={{ height: "40px", width: "40px", marginRight: "10px" }}
          />{" "}
          <span className={classes.bigText}>Netmed24</span>
        </center>
      </div>

      <div
        style={{
          border: "4px solid #e8e8e8",
          borderRadius: "5px",
          marginTop: "10px",
          padding: "30px 20px",
        }}
      >
        <div className={classes.bigText}>Welcome Back</div>

        <form onSubmit={handleAction}>
          <div>
            <TextField
              fullWidth="true"
              id="standard-search"
              label="Username"
              required
              type="search"
              onChange={handleChangeUser}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
            />
            <FormControl fullWidth="true" style={{ marginTop: "10px" }}>
              <InputLabel
                htmlFor="standard-adornment-password"
                // style={{ fontSize: "12px" }}
              >
                Password
              </InputLabel>
              <Input
                required
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChangePass("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div
              style={{
                color: "#090f47",
                fontSize: "12px",
                textAlign: "right",
              }}
            >
              <Link onClick={handleForgetPassword}>
                {" "}
                &nbsp; Forget Password?
              </Link>
            </div>
          </div>

          {authDataErr ? (
            authDataErr.message ? (
              <div
                style={{
                  color: "#ff0000",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {authDataErr.message}
              </div>
            ) : null
          ) : null}

          {authDataLoading === true ? (
            <center>
              <div style={{ marginTop: 20 }}>
                <LinearProgress />
              </div>
            </center>
          ) : null}

          <div className={classes.lButton}>
            <center style={{ paddingTop: "20px" }}>
              <Button
                className={classes.orderBtn}
                style={{ backgroundColor: "#32aeb1", color: "#fff" }}
                type="submit"
              >
                LOGIN
              </Button>
            </center>
            {/* <center>
            <Link
              onClick={() => handleAction("/")}
              style={{
                color: "#090f47",
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              Forgot?
            </Link>
          </center> */}

            <center
              style={{
                paddingTop: "7vw",
                color: "#090f47",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Don't have an account?
              <Link onClick={moveTo}> &nbsp; Sign Up</Link>
            </center>
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
    authDataLoading: state.authReducer.authDataLoading,
    authDataErr: state.authReducer.authDataErr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (data) => dispatch(login(data)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
