import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import React, {useEffect} from "react";
import productImage from "../../../localization/images/product.jpg";
import { makeStyles } from "@material-ui/core/styles";
import {clearSearchProduct, fetchAllProductsData, fetchRelatedProduct} from "../../../redux/actions/allProducts";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import placeholder from "../../../localization/images/product-placeholder.jpeg";
import placeholderOld from "../../../localization/images/nonPharma.png";
import config from "../../../config";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    padding: "0 15%",
    fontWeight: 600,
    paddingBottom: "7.3vw",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
  },
}));

const TopRatedProducts = ({fetchRelatedProduct, relatedProducts, group_name, clearSearchProduct, height}) => {
  const classes = useStyles();

    useEffect(() => {
        let params = {
            [ApiParams.group_name]: group_name,
            [ApiParams.limit]: '8'
        };
        clearSearchProduct()
        if(group_name!== undefined || group_name !== ''){
            fetchRelatedProduct(params);
        }
    }, [group_name]);

    let relatedProductList = relatedProducts ?
        relatedProducts.data ?
            relatedProducts.data.length ?
                relatedProducts.data :
                [] : [] : []

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: 14, color: "#1b1d1e", fontWeight: "500", marginBottom: 20, marginTop: 30 }}
      >
        TOP RATED PRODUCTS
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        // alignItems="center"
        spacing={3}
      >
        {relatedProductList.map(product => {
            let ProductDescriptions = product
                ? product.ProductDescriptions
                    ? product.ProductDescriptions
                    : ""
                : "";

            let ProductImages = product
                ? product.ProductImages
                    ? product.ProductImages
                    : []
                : [];

            let isPharma = product.is_pharma_product === 1 ? 'pharma' : 'noPharma'

            return(
                <Grid item xs={6}>
                    <Link to={`/details/${product.product_id}`}>
                        <div>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    backgroundColor: "#e8e8e8",
                                    padding: "0.73vw",
                                }}
                            >
                                {
                                    product.image ? (
                                        <Avatar
                                            src={config.IMG_BASE + product.image}
                                            variant="square"
                                            style={{ width: "100%", height: `${height}` }}
                                        />
                                    ) : <img src={isPharma ? placeholder : placeholderOld} style={{ width: "100%", height: `${height}` }}/>
                                }
                            </Box>
                            <Typography
                                style={{
                                    color: "#545C67",
                                    fontSize: 12,
                                    fontWeight: "500",
                                }}
                            >
                                {ProductDescriptions.name}
                            </Typography>
                            <Typography style={{ color: "#7B7B7B", fontSize: 12 }}>
                                TK {parseFloat(product.price)}
                            </Typography>
                        </div>
                    </Link>
                </Grid>

            )
        })}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
    return {
        relatedProducts: state.allProductsReducer.relatedProducts,
        relatedProductsLoading: state.allProductsReducer.relatedProductsLoading,
        relatedProductsErr: state.allProductsReducer.relatedProductsErr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRelatedProduct: (params) => dispatch(fetchRelatedProduct(params)),
        clearSearchProduct: () => dispatch(clearSearchProduct())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopRatedProducts);
