import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ReactHtmlParser from "react-html-parser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
      color: "#293341",
      fontSize: 14,
    },
    flexGrow: 1,
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#293341",
    },
    "& .MuiTab-textColorPrimary": {
      color: "#7b7b7b",
      fontSize: 16,
    },
    "& .MuiTab-root": {
      textTransform: "none",
    },

    "& .MuiBox-root-37": {
      padding: "4% 6%",
    },
  },
}));

export default function Description({description}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: "#F9F9F9", padding: "7% 0" }}
    >
      <div style={{ padding: "0 10%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#DF1A33",
              height: "0.23vw",
            },
          }}
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Additional Information" {...a11yProps(1)} />
          <Tab label="Reviews" {...a11yProps(2)} />
        </Tabs>
      </div>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ textAlign: "left", padding: "0 4%", color: "red" }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {ReactHtmlParser(ReactHtmlParser(description.description))}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {ReactHtmlParser(ReactHtmlParser(''))}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {ReactHtmlParser(ReactHtmlParser(''))}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
