import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    color: "red",
    padding: "5%",
  },
}));

const About = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.paper}>
      {/* <div class="contact-inner container"> */}
      <Grid container>
        {/* <div class="col-sm-3"> */}
        <Grid item xs={12} md={3} style={{ borderRight: "2px solid #e7e7e7" }}>
          <h1 class="contact-h1 t_align_l" style={{ color: "#333333" }}>
            About Us
          </h1>
        </Grid>

        {/* <div class="col-sm-9 contact-right-section about-cont"> */}
        <Grid item xs={12} md={9}>
          <div class="contact-right-upper" style={{ paddingLeft: "3%" }}>
            <div class="contact-upp-inn">
              <p> </p>
              <h1 style={{ color: "#333333" }}>About Us</h1>

              <p style={{ color: "#797979" }}>
                Netmed24 is a Registered in Bangladesh as Online Pharmacy. We
                have been online since 2018, and always aim to make your online
                pharmacy experience as simple and cheap as possible.
              </p>

              <h3 style={{ color: "#333333" }}>Private Prescriptions</h3>

              <p style={{ color: "#797979" }}>
                Send us your private prescription, and we can help you save
                money on your medication
              </p>

              <h3 style={{ color: "#333333" }}>Complaints</h3>

              <p style={{ color: "#797979" }}>
                We do our best to always give each patient a&nbsp;great online
                experience. If something does go wrong, however, we do our very
                best to put things right for you. Please use our&nbsp;
                <strong>contact us</strong> if you have any complaints about the
                service you have received.&nbsp;
              </p>

              <h2>&nbsp;</h2>

              <h2 style={{ color: "#333333" }}>Pharmacy features in the app</h2>

              <p>&nbsp;</p>

              <p>
                <strong style={{ color: "#797979" }}>Free Notification</strong>
              </p>

              <p style={{ color: "#797979" }}>
                Free reminders before your medication
              </p>

              <p>&nbsp;</p>

              <p style={{ color: "#797979" }}>
                <strong>Easy refills &amp; transfers</strong>
              </p>

              <p style={{ color: "#797979" }}>
                Refill &amp; transfer prescriptions in just a few taps. No
                online account needed.
              </p>

              <p>&nbsp;</p>

              <p style={{ color: "#797979" }}>
                <strong>Time Savings</strong>
              </p>

              <p>&nbsp;</p>

              <p style={{ color: "#797979" }}>
                <strong>Rx management for you </strong>
              </p>

              <p style={{ color: "#797979" }}>
                Manage &amp; refill prescriptions, see order history &amp; keep
                track of refills remaining.
              </p>

              <p>&nbsp;</p>

              <p style={{ color: "#797979" }}>
                <strong>Mobile Express Pickup</strong>
              </p>

              <p style={{ color: "#797979" }}>
                Use the app to save time picking up prescriptions at our
                Selected
              </p>

              <p style={{ color: "#797979" }}>Lazz Pharma Ltd. Store</p>
              <p></p>
            </div>

            {/* <!--<button type="button" class="btn btn-primary save-btn ripple" data-color="#40E0BC" style="margin-top:30px;">Read More</button>--> */}
          </div>
        </Grid>
        <div class="clear"></div>
      </Grid>
    </div>
  );
};

export default About;
