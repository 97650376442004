import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosServices';
import {ApiServices} from '../../../network/ApiServices';

import {ORDER} from "../../constants/order";

function* createOrder(actions) {
    try {
        let result = yield call(
            AxiosServices.post,
            ApiServices.CHECKOUT_ORDER,
            actions.data, actions.token
        )

        yield put({
            type: ORDER.PLACE_ORDER.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: ORDER.PLACE_ORDER.FAILURE,
            result: err.response.data
        })
    }
}

function* uploadFile(actions) {
    try {
        let result = yield call(
            AxiosServices.postFormData,
            ApiServices.UPLOAD_FILE,
            actions.data, actions.token
        )

        yield put({
            type: ORDER.UPLOAD_FILE_SET.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)

        yield put({
            type: ORDER.UPLOAD_FILE_SET.FAILURE,
            result: err.response.data
        })
    }
}

function* removeOrderData() {
    yield put({
        type: ORDER.PLACE_ORDER_CLEAR.SUCCESS
    })
}

function* removeOrderList() {
    yield put({
        type: ORDER.ORDER_CLEAR.SUCCESS
    })
}

function* paymentCreate(actions) {
    try {
        const result = yield call(
            AxiosServices.postForm,
            ApiServices.PAYMENT_SUCCESS,
            actions.data, actions.token
        );
        yield put({
            type: ORDER.ONLINE_PAYMENT_OK.SUCCESS,
            result: result.data,
            message: 'Data Fetched'
        })
    } catch (err) {
        Logger(err);
        yield put({
            type: ORDER.ONLINE_PAYMENT_OK.FAILURE,
            result: err.response.data
        })
    }
}

function* orderList(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.ORDER_LIST,
            actions.params, actions.token
        );
        yield put({
            type: ORDER.ORDER_LIST_OK.SUCCESS,
            result: result.data,
            message: 'Data Fetched'
        })
    } catch (err) {
        Logger(err);
        yield put({
            type: ORDER.ORDER_LIST_OK.FAILURE,
            result: err.response.data
        })
    }
}

export default function* orderSagas() {
    yield takeLatest(ORDER.PLACE_ORDER.MAIN, createOrder)
    yield takeLatest(ORDER.PLACE_ORDER_CLEAR.MAIN, removeOrderData)
    yield takeLatest(ORDER.ORDER_CLEAR.MAIN, removeOrderList)
    yield takeLatest(ORDER.UPLOAD_FILE_SET.MAIN, uploadFile)
    yield takeLatest(ORDER.ONLINE_PAYMENT_OK.MAIN, paymentCreate)
    yield takeLatest(ORDER.ORDER_LIST_OK.MAIN, orderList)
}
