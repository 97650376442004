import { HOME } from "../../constants/home";

export function fetchCategoryData() {
  return {
    type: HOME.BRAND_DATA.MAIN,
    //params: params
  };
}

export function fetchTodayProductData(params) {
  return {
    type: HOME.TODAY_PRODUCT_DATA.MAIN,
    params: params,
  };
}

export function fetchEssentialProductData(params) {
  return {
    type: HOME.ESSENTIAL_PRODUCT_DATA.MAIN,
    params: params,
  };
}

export function fetchFeatureProductData(params) {
  return {
    type: HOME.FEATURE_PRODUCT_DATA.MAIN,
    params: params,
  };
}

export function fetchFeatureBrandData(params) {
  return {
    type: HOME.FEATURE_BRAND_DATA.MAIN,
    params: params,
  };
}

export function fetchConcernProductData(params) {
  return {
    type: HOME.CONCERN_PRODUCT_DATA.MAIN,
    params: params,
  };
}

export function fetchExpConcernData(params) {
  return {
    type: HOME.EXP_CONCERN_DATA.MAIN,
    params: params,
  };
}

export function fetchSpecialData(params) {
  return {
    type: HOME.SPECIAL_DATA.MAIN,
    params: params,
  };
}
