import { all } from "redux-saga/effects";
import homeSagas from "./home";
import detailSagas from "./details";
import cartSagas from "./cart";
import authSagas from "./auth";
import orderSagas from "./order";
import allProductsSagas from "./allProducts";
import blogSagas from "./blog";

function* rootSaga() {
  yield all([
    homeSagas(),
    detailSagas(),
    cartSagas(),
    authSagas(),
    orderSagas(),
    allProductsSagas(),
    blogSagas()
  ]);
}

export default rootSaga;
