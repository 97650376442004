import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import TelegramIcon from "@material-ui/icons/Telegram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Logo from "../localization/images/brandlogo.png";
import GooglePlay from "../localization/images/google-play-download.png";
import AppStore from "../localization/images/app-store-download.png";
import { Link } from "react-router-dom";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 2,
        height: 40,
        width: 260,
        borderColor: "#B3B6B7",
      },
      color: "white",
      fontSize: 13,
      "& $input": {
        padding: 10,
        width: 200,
      },
    },
  },
})(TextField);

function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      style={{ color: "#B3B6B7", textAlign: "left", display: "flex" }}
    >
      {"© "}
      {new Date().getFullYear()}
      <div color="inherit" style={{ color: "#b3b6b7" }}>
        &nbsp;Netmed24. All Rights Reserved. Powered by{" "}
        <a
          className={classes.linkList}
          target="_blank"
          href="https://tajtechltd.com/"
        >
          Taj Tech Ltd
        </a>
        .
      </div>
    </Typography>
  );
}

function ListLinkText({ listArray, title }) {
  const classes = useStyles();
  return (
    <List dense={false}>
      <Typography
        variant="subtitle2"
        style={{
          paddingLeft: "15px",
          color: "white",
          textWeight: "bold",
        }}
      >
        {title}
      </Typography>
      {listArray.map((secItem) => (
        <ListItem>
          <Typography variant="body2" style={{ color: "#B3B6B7" }}>
            <Link
              color="inherit"
              to={secItem.link}
              className={classes.linkList}
            >
              {secItem.title}
            </Link>
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    maxWidth: "95%",
  },
  footer: {
    padding: theme.spacing(3, 0),
    // marginTop: "150px",
    backgroundColor: "#495057",
  },
  linkList: {
    color: "#b3b6b7",
    "&:hover": {
      color: "#00f121",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  iconButton: {
    paddingTop: 7,
    color: "#B3B6B7",
  },
  divider: {
    backgroundColor: "#B3B6B7",
    marginTop: "10px",
    marginBottom: "25px",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  const secondColItems = [
    { title: "> Blog", link: "/blog" },
    { title: "> Contact", link: "/contact" },
    { title: "> About us", link: "/about" },
    { title: "> Privacy Policy", link: "/privacy-policy" },
  ];

  const thirdColItems = [
    { title: "> Help Center", link: "#" },
    { title: "> Payment", link: "#" },
    { title: "> How to Buy", link: "#" },
    { title: "> Terms & Conditions", link: "/terms-conditions" },
  ];

  return (
    <footer className={classes.footer}>
      <Container className={classes.containerRoot}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            {/* <div
              style={{ width: "100px", height: "70px", marginBottom: "15px" }}
            /> */}
            {/*<Avatar*/}
            {/*  variant="square"*/}
            {/*  src={Logo}*/}
            {/*  style={{ width: "100px", height: "70px", marginBottom: "15px" }}*/}
            {/*/>*/}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                variant="square"
                src={GooglePlay}
                style={{ width: "100px", height: "70px", marginBottom: "15px" }}
              ></Avatar>
              <Avatar
                variant="square"
                src={AppStore}
                style={{
                  width: "100px",
                  height: "70px",
                  marginBottom: "15px",
                  marginLeft: "10px",
                }}
              ></Avatar>
            </div>
            <Typography variant="body2" style={{ color: "#B3B6B7" }}>
              Netmed24 - the ultimate online pharmacy that helps you buy
              anything.
            </Typography>
            {/* <Typography variant="body2" style={{ color: "#B3B6B7" }}>
              The best choice for your next personal or client's e-shop.
            </Typography> */}
            <Typography
              variant="subtitle2"
              style={{
                margin: "30px 0px 12px 0px",
                color: "white",
                textWeight: "bold",
              }}
            >
              License:
            </Typography>
            {/* <CustomTextField*/}
            {/*   id="outlined-secondary"*/}
            {/*   placeholder="Enter your email address"*/}
            {/*   variant="outlined"*/}
            {/* />*/}
            {/*<IconButton className={classes.iconButton} aria-label="search">*/}
            {/*   <TelegramIcon fontSize="small" />*/}
            {/* </IconButton>*/}
            <Typography
              variant="body2"
              style={{ color: "#b3b6b7", textAlign: "left", marginTop: 12 }}
            >
              Drug License: DC-17298, Trade license - 047354
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ListLinkText listArray={secondColItems} title={"ABOUT US"} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ListLinkText listArray={thirdColItems} title={"CUSTOMER CARE"} />
          </Grid>
        </Grid>
        <Divider classes={{ root: classes.divider }} />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Copyright />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body2"
              style={{ color: "#B3B6B7", textAlign: "right" }}
            >
              {/* <a
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  marginRight: 10,
                }}
                href="https://www.youtube.com/channel/UCYHZsADcUKSIk5YjY56hK3w"
                target="_blank"
              >
                <YouTubeIcon />
              </a>
              <a
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  marginRight: 10,
                }}
                href="https://twitter.com/net_med24"
                target="_blank"
              >
                <TwitterIcon />
              </a>
              <a
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  marginRight: 10,
                }}
                href="https://www.linkedin.com/company/netmed-24/about/?viewAsMember=true"
                target="_blank"
              >
                <LinkedinIcon />
              </a> */}
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="https://web.facebook.com/epharmacybd"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
