import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import BackupIcon from "@material-ui/icons/Backup";
import { Dialog } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MultiPrescription from "../container/MultiPrescription/MultiPrescription";
import {connect} from "react-redux";
import LoginForm from "../container/Login/LoginForm";
import SignUpMobile from "../container/Login/SignUpMobile";
import SignUpPassword from "../container/Login/SignUpPassword";
import OtpVarificationForm from "../container/Login/OtpVarificationForm";
import VerifySuccess from "../container/Login/VerifySuccess";
import RecoverPhone from "../container/Login/RecoverPhone";
import ResetPasswordForm from "../container/Login/ResetPasswordForm";

const useStyles = makeStyles((theme) => ({
  iconButtonUpload: {
    backgroundColor: "#1F8184",
    color: "white",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: "#46bdbf",
    },
  },
  uploadRoot: {
    color: "#D71512",
    fontSize: "12px",
    width: "84px",
    backgroundColor: "white",
    border: "2px solid #1F8184",
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    paddingLeft: 3,
    cursor: "pointer",
  },
  uploadInput: {
    textAlign: "center",
    cursor: "pointer",
  },
}));

const PresciptionInput = ({
  textColor,
  bgColor,
  pWidth,
  pHeight,
  pFontSize, authData
}) => {
  const classes = useStyles();
  const [otpFor, setOtpFor] = React.useState();
  //profile pic upload
  const hiddenFileInput = React.useRef(null);

  const handleFileUpload = (event) => {
    // hiddenFileInput.current.click();
  };

  const uploadFile = (event) => {
    let fileUploaded = event.target.files[0];

    let newName = new Date().getTime() + event.target.files[0].name;
    let new_file = new File([fileUploaded], newName);
    handleClickOpen();
  };
  // File Upload

  //Dailog part
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xl");
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [dailogName, setDailogName] = React.useState();

  const handleClickOpen = (event) => {
    event.preventDefault();
    document.querySelector("body").classList.add("modal-open")
    if (authData){
      if (authData.data){
        setOpen(true);
      } else {
        setOpenSignIn(true)
        setDailogName('loginForm')
      }
    } else {
      setOpenSignIn(true)
      setDailogName('loginForm')
    }

  };
  const handleClose = () => {
    setOpen(false);
    setDailogName('')
    document.querySelector("body").classList.remove("modal-open")
  };
  const signInClose = () => {
    setOpenSignIn(false);
    setDailogName('')
    document.querySelector("body").classList.remove("modal-open")
  }
  //Dailog part

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row" }}
        onClick={handleClickOpen}
      >
        <InputBase
          multiline="true"
          defaultValue="Upload Prescription"
          readOnly="true"
          classes={{
            root: classes.uploadRoot,
            input: classes.uploadInput,
          }}
          inputProps={{ "aria-label": "search" }}
          style={{
            backgroundColor: bgColor,
            color: textColor,
            width: pWidth,
            height: pHeight,
            fontSize: pFontSize,
          }}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          //onChange={uploadFile}
          style={{ display: "none" }}
        />
        <IconButton
          className={classes.iconButtonUpload}
          // onClick={handleFileUpload}
          style={{ height: pHeight }}
        >
          <BackupIcon />
        </IconButton>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
      >
        <MultiPrescription handleClose={handleClose} fromOrder={false}/>
      </Dialog>
      <Dialog
          open={openSignIn}
          onClose={signInClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        {dailogName === "loginForm" && (
            <LoginForm
                handleClose={signInClose}
                moveToSignUp={() => setDailogName("signUpMobile")}
                moveToRecoverPhone={() => setDailogName("recoverPhone")}
            />
        )}
        {dailogName === "recoverPhone" && (
            <RecoverPhone
                handleMove={() => setDailogName("otpInput")}
                otpFor={() => setOtpFor("resetPassword")}
            />
        )}
        {dailogName === "signUpMobile" && (
            <SignUpMobile
                handleMove={() => setDailogName("otpInput")}
                otpFor={() => setOtpFor("signUpPassword")}
                moveToLogin={() => setDailogName("loginForm")}
            />
        )}

        {dailogName === "signUpPassword" && (
            <SignUpPassword handleMove={() => setDailogName("loginForm")} />
        )}

        {dailogName === "resetPassword" && (
            <ResetPasswordForm handleMove={() => setDailogName("loginForm")} />
        )}


        {dailogName === "otpInput" && (
            <OtpVarificationForm
                handleMove={(e) => setDailogName(e)}
                otpFor={() => setOtpFor("")}
                pageName={otpFor}
            />
        )}

        {dailogName === "successVerify" && (
            <VerifySuccess
                moveToSignUpPass={() => setDailogName("signUpPassword")}
            />
        )}
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData
  };
}

export default connect(mapStateToProps, null)(PresciptionInput);
