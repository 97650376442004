import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosServices';
import {ApiServices} from '../../../network/ApiServices';
import {DETAILS} from '../../constants/details/index'

function* fetchProductDetailsData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.PRODUCT_DETAIL,
            actions.params
        ); //true when Json Server
        yield put({
            type: DETAILS.PRODUCT_DETAIL_DATA.SUCCESS,
            result: result.data,
            message: 'Data Fetched'
        })
    } catch (err) {
        Logger(err);
        yield put({
            type: DETAILS.PRODUCT_DETAIL_DATA.FAILURE,
            result: err.response.data
        })
    }
}

export default function* detailSagas() {
    yield takeLatest(DETAILS.PRODUCT_DETAIL_DATA.MAIN, fetchProductDetailsData)
}
