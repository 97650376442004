import React, { useEffect, useState } from "react";
import {
  fade,
  makeStyles,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Grid from "@material-ui/core/Grid";
import RoomIcon from "@material-ui/icons/Room";
import PresciptionInput from "../PresciptionInput";
import Container from "@material-ui/core/Container";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../localization/images/brandlogo.png";
import { Dialog, Divider } from "@material-ui/core";
import LoginForm from "../../container/Login/LoginForm";
import { connect } from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";
import SignUpMobile from "../../container/Login/SignUpMobile";
import OtpVarificationForm from "../../container/Login/OtpVarificationForm";
import VerifySuccess from "../../container/Login/VerifySuccess";
import SignUpPassword from "../../container/Login/SignUpPassword";
import { logout } from "../../redux/actions/auth";
import { clearCheckout } from "../../redux/actions/cart";
import { clearOrder, clearOrderList } from "../../redux/actions/order";
import RecoverPhone from "../../container/Login/RecoverPhone";
import ResetPasswordForm from "../../container/Login/ResetPasswordForm";
import {
  clearSearchProduct,
  fetchSearchProduct,
} from "../../redux/actions/allProducts";
import { ApiParams } from "../../constants/apiParams";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Medicon from "../../assets/images/medicon.png";
import {Scrollbar} from "swiper/js/swiper.esm";

const drawerWidth = "100%";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 17,
    // border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: "white",
    color: "#D71512",
    // padding: "0 4px",
    // fontSize: "8px",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiToolbar-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    maxWidth: "96%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "96%",
    },
  },
  iconButton: {
    color: "#D71512",
  },
  iconButtonSearch: {
    backgroundColor: "#D71512",
    color: "white",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    height: 42,
  },
  searchBeforeButton: {
    backgroundColor: "#f3f3f3",
    color: "#D71512",
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    height: 42,
    width: 120,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo: {
    height: "60px",
    width: "86px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: "72px",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    width: "100%",
    marginLeft: theme.spacing(3),
  },
  searchBreakPoint: {
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#D71512",
    fontSize: "12px",
    width: "100%",
    backgroundColor: "white",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: "10px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txtBtn: {
    cursor: "pointer",
    fontSize: 14,
    fontFamily: 'Poppins',
    "&:hover": {
      color: "black",
    },
  },

  // Style for Drawer
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}%)`,
    width: `calc(100% - ${100}%)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRigh: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperTop: {
    width: drawerWidth,
    height: "112px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerHeaderRight: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  linkList: {
    color: "black",
    fontSize: "14px",
    fontFamily: 'Poppins',
    "&:hover": {
      color: "#32AEB0",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  // Style for Drawer
}));

function SearchBox({ setSearchText, products, clearSearchProduct }) {
  const classes = useStyles();
  const history = useHistory();

  const change = (name, e) => {
    e.persist();
    if (name === "search") {
      setSearchText(e.target.value);
    }
  };
  const goToDetail = (item) => {
    history.push(`/details/${item.product_id}`);
    clearSearchProduct();
  };
  return (
    <>
      <StyledSearch>
        <div className="dropdown">
          <IconButton
            className={`${classes.searchBeforeButton} ${classes.sectionDesktop}`}
          >
            <RoomIcon />{" "}
            <span style={{ fontSize: "10px", color: "#32AEB0" }}>
              Delivering to <br />
              <span style={{ fontSize: "10px", color: "#D71512" }}>
                {/* 11001 <EditIcon fontSize="inherit" style={{ fontSize: "12px" }} /> */}
                Dhaka
              </span>
            </span>
          </IconButton>
          <InputBase
            placeholder="Search for medicine & wellness products..."
            onChange={change.bind(null, "search")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton className={classes.iconButtonSearch}>
            <SearchIcon />
          </IconButton>
        </div>
        <div className="drop">
          {products.length
              ? products.map((item) => {
                return (
                    <div
                        onClick={() => goToDetail(item)}
                    >
                      <div className="dropdown-nested">
                        <div className="med-name">
                          {item?.ProductDescriptions?.name}
                        </div>
                        {/*<div className="manufacturer-name">*/}
                        {/*  {item?.ProductManufacturer?.name}*/}
                        {/*</div>*/}
                      </div>
                      <Divider/>
                    </div>
                );
              })
              : null}
        </div>
      </StyledSearch>
    </>
  );
}

const TopBar = ({
  cart,
  wishlist,
  authData,
  logout,
  clearCheckout,
  clearOrder,
  clearOrderList,
  fetchSearchProduct,
  searchProducts,
  clearSearchProduct,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [dailogName, setDailogName] = React.useState();
  const [otpFor, setOtpFor] = React.useState();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [searchText, setSearchText] = useState("");

  // Menu Drawer open close
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDrawerRight, setOpenDrawerRight] = React.useState(false);
  const [openDrawerTop, setOpenDrawerTop] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
    setOpenDrawerTop(false);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleDrawerRightOpen = () => {
    setOpenDrawerRight(true);
    setOpenDrawerTop(false);
  };

  const handleDrawerRightClose = () => {
    setOpenDrawerRight(false);
  };

  const handleDrawerTop = () => {
    setOpenDrawerTop(!openDrawerTop);
  };
  // Drawer open close

  // Top Menu
  const topMenus = [
    { title: "COVID-19", link: 20 },
    { title: "Diabetic Care", link: 19 },
    { title: "Kidney Care", link: 22 },
    { title: "Vitamins", link: 5 },
    { title: "Gastro", link: 24 },
    { title: "Fitness", link: 10 },
    { title: "Sergicals", link: 15 },
  ];

  const thirdMenus = [
    { title: "Toiletries", link: 13 },
    { title: "Parlour", link: 9 },
    { title: "Cosmetics", link: 39 },
    { title: "Jewellery", link: 40 },
    { title: "Khusboo", link: 41 },
    { title: "Food", link: 12 },
    { title: "Sports", link: 10 },
    { title: "Others", link: 42 },
  ];

  useEffect(() => {
    let params = {
      [ApiParams.search_text]: searchText,
      [ApiParams.limit]: "20",
    };
    if (searchText.length > 0) {
      fetchSearchProduct(params);
    }
  }, [searchText]);

  let products = searchProducts ? searchProducts.data : [];

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuProfile = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push("/profile");
  };

  const handleMenuOder = () => {
    setAnchorEl(null);
    clearOrder();
    handleMobileMenuClose();
    history.push("/order-list");
  };

  const handleLogout = () => {
    logout();
    clearOrderList();
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push("/");
  };

  const handleGotoCart = () => {
    clearCheckout();
    clearOrder();
    history.push("/your-cart");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // Sign In dailog
  const [openLogin, setOpenLogin] = React.useState(false);

  const handleLoginOpen = (name) => {
    setDailogName(name);
    setOpenLogin(true);
    logout();
    document.querySelector("body").classList.add("modal-open")
  };

  const handleLoginClose = (value) => {
    setOpenLogin(value);
    setDailogName("");
    document.querySelector("body").classList.remove("modal-open")
  };

  const handleClose = () => {
    setOpenLogin(false);
    setDailogName("");
    logout();
    document.querySelector("body").classList.remove("modal-open")
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      style={{ zIndex: 99999999 }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuProfile}>Profile</MenuItem>
      <MenuItem onClick={handleMenuOder}>My Order</MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          aria-label="show 11 new notifications"
          color="inherit"
          className={classes.iconButton}
        >
          <StyledBadge badgeContent={1} color="secondary">
            <ShoppingCartOutlinedIcon />
          </StyledBadge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          className={classes.iconButton}
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        elevation={0}
        style={{ backgroundColor: "#32AEB0", zIndex: 999999 }}
        className={clsx(
          classes.appBar,
          {
            [classes.appBarShift]: openDrawer,
          },
          { [classes.appBarShiftRigh]: openDrawerRight }
        )}
        // style={{ background: "transparent", boxShadow: "none" }}
      >
        <Container className={classes.root}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, openDrawer && classes.hide)}
            >
              <MenuIcon style={{ color: "#fff" }} />
            </IconButton>
            <Link to="/">
              <Avatar
                variant="square"
                src={Logo}
                className={`${classes.title} ${classes.logo}`}
              />
            </Link>
            <div className={`${classes.search} ${classes.sectionDesktop}`}>
              <SearchBox
                setSearchText={setSearchText}
                products={products}
                clearSearchProduct={clearSearchProduct}
              />
            </div>

            <div className={classes.grow} />
            <div
              className={`${classes.search} ${classes.searchBreakPoint} ${classes.sectionDesktop}`}
            >
              <PresciptionInput textColor={"#D71512"} bgColor={"white"} />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{marginTop: 6}}>
                <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    className={classes.iconButton}
                    onClick={handleGotoCart}
                >
                  <StyledBadge badgeContent={cart.length} color="secondary">
                    <ShoppingCartOutlinedIcon />
                  </StyledBadge>
                </IconButton>
              </div>
              {authData ? (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className={classes.iconButton}
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
              ) : null}
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="search"
                  color="inherit"
                  onClick={handleDrawerTop}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </div>

            <div className={classes.sectionDesktop}>
              <div
                style={{
                  width: "200px",
                  marginLeft: "10px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    {authData ? null : (
                      <>
                        <span
                          className={classes.txtBtn}
                          onClick={() => handleLoginOpen("loginForm")}
                        >
                          Sign In
                        </span>{" "}
                        |{" "}
                        <span
                          className={classes.txtBtn}
                          onClick={() => handleLoginOpen("signUpMobile")}
                        >
                          Sign Up
                        </span>
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    className={classes.txtBtn}
                    style={{ textAlign: "right" }}
                  >
                    Need Help?
                  </Grid>
                </Grid>
                <div style={{ textAlign: "right" }}>
                  Hot line: +8801316458575
                </div>
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                // onClick={handleMobileMenuOpen}
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerRightOpen}
                edge="start"
                className={clsx(openDrawerRight && classes.hide)}
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <div>
          {topMenus.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 10
              }}
            >
              <Avatar
                src={Medicon}
                style={{
                  height: 48,
                  width: 48,
                  padding: 10,
                }}
              />
              <div style={{ padding: 10 }}>
                <Link
                  to={`/products/cat=${item.link}`}
                  color="inherit"
                  className={classes.linkList}
                  onClick={handleDrawerClose}
                >
                  {item.title}
                </Link>
              </div>
            </div>
          ))}
        </div>

        <Divider />

        {/*<div>*/}
        {/*  {thirdMenus.map((item) => (*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        display: "flex",*/}
        {/*        flexDirection: "row",*/}
        {/*        alignItems: "center",*/}
        {/*        marginLeft: 10*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      /!* <Avatar*/}
        {/*        src={Medicon}*/}
        {/*        style={{ height: "30px", width: "30px", padding: "2%" }}*/}
        {/*      ></Avatar> *!/*/}
        {/*      <div style={{ padding: 10 }}>*/}
        {/*        <Link*/}
        {/*          to={`/products/cat=${item.link}`}*/}
        {/*          color="inherit"*/}
        {/*          className={classes.linkList}*/}
        {/*          onClick={handleDrawerClose}*/}
        {/*        >*/}
        {/*          {item.title}*/}
        {/*        </Link>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openDrawerRight}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeaderRight}>
          <IconButton onClick={handleDrawerRightClose}>
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <div
          style={{ display: "flex", justifyContent: "center", padding: "15px 0" }}
        >
          <PresciptionInput textColor={"#D71512"} bgColor={"white"} />
        </div>
        <Divider />
        <div style={{ padding: "10px 15px" }}>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              {authData ? null : (
                <>
                  <span
                    className={classes.txtBtn}
                    onClick={() => handleLoginOpen("loginForm")}
                  >
                    Sign In
                  </span>{" "}
                  |{" "}
                  <span
                    className={classes.txtBtn}
                    onClick={() => handleLoginOpen("signUpMobile")}
                  >
                    Sign Up
                  </span>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={5}
              className={classes.txtBtn}
              style={{ textAlign: "right" }}
            >
              Need Help?
            </Grid>
          </Grid>
          <div style={{ textAlign: "left", paddingTop: 20, fontSize: 14 }}>
            Hot line: +08801679409972
          </div>
        </div>
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="top"
        open={openDrawerTop}
        classes={{
          paper: classes.drawerPaperTop,
        }}
      >
        <div style={{ height: "60px" }}></div>
        <div style={{ padding: "1% 3%" }}>
          <div>
            <SearchBox
              setSearchText={setSearchText}
              products={products}
              clearSearchProduct={clearSearchProduct}
            />
          </div>
        </div>
      </Drawer>
      <Dialog
        open={openLogin}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <LoginCart handleClose={handleLoginClose} /> */}

        {dailogName === "loginForm" && (
          <LoginForm
            handleClose={handleLoginClose}
            moveToSignUp={() => setDailogName("signUpMobile")}
            moveToRecoverPhone={() => setDailogName("recoverPhone")}
          />
        )}
        {dailogName === "recoverPhone" && (
          <RecoverPhone
            handleMove={() => setDailogName("otpInput")}
            otpFor={() => setOtpFor("resetPassword")}
          />
        )}
        {dailogName === "signUpMobile" && (
          <SignUpMobile
            handleMove={() => setDailogName("otpInput")}
            otpFor={() => setOtpFor("signUpPassword")}
            moveToLogin={() => setDailogName("loginForm")}
          />
        )}

        {dailogName === "signUpPassword" && (
          <SignUpPassword handleMove={() => setDailogName("loginForm")} />
        )}

        {dailogName === "resetPassword" && (
          <ResetPasswordForm handleMove={() => setDailogName("loginForm")} />
        )}

        {dailogName === "otpInput" && (
          <OtpVarificationForm
            handleMove={(e) => setDailogName(e)}
            otpFor={() => setOtpFor("")}
            pageName={otpFor}
          />
        )}

        {dailogName === "successVerify" && (
          <VerifySuccess
            moveToSignUpPass={() => setDailogName("signUpPassword")}
          />
        )}
      </Dialog>
    </div>
  );
};

const StyledSearch = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover {
    .drop {
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }

  .dropdown {
    position: relative;
    display: flex;
  }

  .drop {
    position: absolute;
    top: 42px;
    display: flow-root;
    width: 100%;
    min-width: 190px;
    max-height: 420px;
    overflow: auto;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    transform: translateY(30px);
    box-shadow: 1.5px 1.5px #88888821;
    background-color: #ffffff;
    height: auto;

    li {
      height: auto;

      a {
        height: auto;
      }
    }

    .dropdown-nested {
      padding: 4px 14px 3px 14px;
      cursor: pointer;
      font-size: 15px !important;
      color: #000000;

      a {
        font-size: 15px !important;
        color: #000000;
        font-weight: normal;
      }
      
      .med-name {
        //text-transform: capitalize;
        font-size: 14px;
        color: rgb(0, 0, 0);
        margin-top: 5px;
      }
      
      .manufacturer-name {
        text-transform: capitalize;
        font-size: 10px;
        color: rgb(72, 72, 72);
        margin-bottom: 5px;
      }

      &:hover {
        background-color: #32aeb1;
        color: #fff;
        .med-name{
          color: #fff;
        }
        .manufacturer-name{
          color: #fff;
        }
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    cart: state.cartReducer.cart,
    authData: state.authReducer.authData,
    wishlist: state.cartReducer.wishlist,
    searchProducts: state.allProductsReducer.searchProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    clearCheckout: () => dispatch(clearCheckout()),
    clearOrder: () => dispatch(clearOrder()),
    clearOrderList: () => dispatch(clearOrderList()),
    fetchSearchProduct: (params) => dispatch(fetchSearchProduct(params)),
    clearSearchProduct: () => dispatch(clearSearchProduct()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
