import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import {FiInstagram, FiLink2} from 'react-icons/fi';
import {GrFacebookOption} from 'react-icons/gr';

const ShareOnSocialMedia = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <SocialMediaDiv>
            <Container>
                <Row>
                    <Col lg="6">
                        <div className="contents">
                            <span>Share:</span>
                            <span><a href="#"><FiLink2 className="icon"/></a></span>
                            <span><a href="#"><GrFacebookOption className="icon"/></a></span>
                            <span><a href="#"><FiInstagram className="icon"/></a></span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SocialMediaDiv>
    )
};
const SocialMediaDiv = styled.div`
  margin-top: 31px;
  padding-bottom: 60px;

  .contents {
    display: flex;
    align-items: center;
  }

  .contents span {
    font-size: 14px;
    color: #000000;
    margin-right: 15px;

    &:nth-child(1) {
      margin-right: 9px;
    }
  }

  .contents .icon {
    color: #000000;
  }
`
export default ShareOnSocialMedia;