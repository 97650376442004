import React from "react";
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import Title from "../../components/Title";
import {logout} from "../../redux/actions/auth";
import {clearCheckout} from "../../redux/actions/cart";
import {clearOrder, clearOrderList} from "../../redux/actions/order";
import {connect} from "react-redux";

const Success = ({clearOrder}) =>{
    const history = useHistory();

    const handleToOrder =()=>{
        clearOrder()
        history.push('/')
    }

    return(
        <StyledSuccess>
            <div className="main">
                <Title
                    text={"Your payment was successfully completed!"}
                    fontWeight={"bold"}
                    fontSize={24}
                    lineHeight={32}
                    margin={"30px 0 10px 0"}
                    textAlign={"center"}
                />
                <Title
                    text={"Thanks for stay with us!"}
                    fontWeight={500}
                    fontSize={20}
                    lineHeight={32}
                    margin={"30px 0 10px 0"}
                    textAlign={"center"}
                />
                <div onClick={handleToOrder} style={{cursor: "pointer"}}>
                    <Title
                        text={`For more products visit here`}
                        fontWeight={400}
                        fontSize={18}
                        lineHeight={24}
                        margin={"0 0 10px 0"}
                        textAlign={"center"}
                        color={"#419AE8"}
                    />
                </div>
            </div>
        </StyledSuccess>
    )
}

let StyledSuccess = styled.div`
  background-color: #f8f8f8;
  padding: 120px 40px;
  height: 120vh;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

function mapDispatchToProps(dispatch) {
    return {
        clearOrder: () => dispatch(clearOrder())
    };
}

export default connect(null, mapDispatchToProps)(Success);
