import styled from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';

export const CounterBox = styled.div(
    css({
        display: 'flex',
        backgroundColor: '#f3f1f1',
        color: '#313132',
        fontSize: 'base',
        fontWeight: 'bold',
    }),
    {
        borderRadius: 3,
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
        flexShrink: 0,
        '&:focus': {
            outline: 'none',
        },
    },
    variant({
        variants: {
            horizontal: {
                width: 80,
                height: 25,
            },
            vertical: {
                width: 20,
                height: 40,
                flexDirection: 'column-reverse',
            },
            verticalMobile: {
                width: 30,
                height: 90,
                flexDirection: 'column-reverse',
                '@media (max-width: 580px)': {
                    width: 28,
                    height: 72,
                    position: 'absolute',
                    bottom: '18px',
                    right: '18px'
                }
            },
            lightHorizontal: {
                width: 100,
                height: 40,
                backgroundColor: 'gray.200',
                color: 'text.bold',
            },
            lightVertical: {
                width: 20,
                height: 70,
                flexDirection: 'column-reverse',
                backgroundColor: 'gray.200',
                color: 'text.bold',
            },
        },
    })
);

export const CounterButton = styled.button(
    {
        border: 'none',
        backgroundColor: 'transparent',
        color: '#313132',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
        cursor: 'pointer'
    },
    variant({
        variants: {
            lightHorizontal: {
                color: 'text.regular',
            },
            lightVertical: {
                color: 'text.regular',
            },
        },
    })
);

export const CounterValue = styled.span({
    pointerEvents: 'none',
    fontSize: 10
});

CounterValue.displayName = 'CounterValue';
CounterButton.displayName = 'CounterButton';
CounterBox.displayName = 'CounterBox';

CounterBox.defaultProps = {
    variant: 'horizontal',
};
