import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import productImage from "../../../../localization/images/brandproduct.png";
import brandImage from "../../../../localization/images/brand.png";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../../../config";
import placeholder from "../../../../localization/images/product-placeholder.jpeg";
import { Link } from "react-router-dom";

const Placeholder = () => <img src={placeholder} alt="product img loader" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    // height: 450,
    width: "100%",
    position: "relative",

    [theme.breakpoints.up("xs")]: {
      height: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "240px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "290px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "310px",
    },
  },
  prodStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#32aeb0",
      transition: "all 2s ease",
    },
  },
  logoStyle: {
    // cursor: "pointer",
    "&:hover": {
      transform: "scale(1.2)",
      transition: "all 1s ease",
    },
  },
}));

export default function BrandCard({ item }) {
  const classes = useStyles();

  return (
    <Typography
      component="div"
      variant="body1"
      //   style={{ height: 450, width: "100%", position: "relative" }}
      className={classes.paper}
    >
      <Box
        bgcolor="white"
        color="white"
        // p={1}
        position="absolute"
        top="60%"
        left="25%"
        width="50%"
        height="40%"
        zIndex="tooltip"
        border="2px solid gray"
        borderRadius="5px"
        style={{ display: "flex", alignItems: "center", padding: "5%" }}
      >
        <Avatar
          src={config.IMG_BASE + item.image}
          variant="square"
          style={{ width: "100%", height: "auto" }}
          className={classes.logoStyle}
        />
      </Box>
      <Box
        bgcolor="#F0F0F0"
        color="text.primary"
        p={1}
        position="absolute"
        top={0}
        zIndex="modal"
        width="100%"
        height="90%"
        className={classes.prodStyle}
      >
        <Link to={`/details/${item.Products[0].product_id}`}>
          <Avatar
            src={config.IMG_BASE + item.Products[0].image}
            variant="square"
            style={{ width: "100%", height: "100%" }}
          />
        </Link>
      </Box>
    </Typography>
  );
}
