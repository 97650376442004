import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import SortBox from "./SortBox";
import Banner from "../../../src/localization/images/care-banner.png";
import { makeStyles } from "@material-ui/core/styles";
import Product from "./Product";
import { connect } from "react-redux";
import { fetchAllProductsData } from "../../redux/actions/allProducts";
import { ApiParams } from "../../constants/apiParams";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    padding: "0 4%",
  },
  txtStyle: {
    fontSize: "1.4vw",
    fontWeight: "500",
    padingTop: "5px",
  },
}));

const DiabeticCare = ({ getFetchAllProductsData, allProducts }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      [ApiParams.category_id]: 20,
    };
    getFetchAllProductsData(params);
  }, []);

  let products = allProducts ? allProducts.data : null;

  const allItems = products ? products.ProductToCategories : [];

  const dietItems = allItems.slice(0, 3);

  return (
    <div className={classes.paper}>
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <Typography className={classes.txtStyle}>Diabetic Care</Typography>
        <SortBox />
      </div>

      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <img src={Banner} style={{ width: "100%" }} />
      </div>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{ paddingTop: "14px", paddingLeft: "1%", paddingRight: "1%" }}
        >
          <Typography className={classes.txtStyle}>Diabetic Diet</Typography>
        </Grid>
        {dietItems.map((item) => (
          <Grid item xs={4} style={{ padding: "0 1%" }}>
            <Product item={item} cardHeight={350} />
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        direction="row"
        // justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{ paddingTop: "14px", paddingLeft: "1%", paddingRight: "1%" }}
        >
          <Typography className={classes.txtStyle}>All</Typography>
        </Grid>
        {allItems.map((item) => (
          <Grid item xs={3} style={{ padding: "0 1%" }}>
            <Product item={item} cardHeight={280} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    allProducts: state.allProductsReducer.allProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchAllProductsData: (params) => dispatch(fetchAllProductsData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiabeticCare);
