import { Grid } from "@material-ui/core";
import React, {useEffect} from "react";
import CartList from "./components/CartList";
import PaymentSummary from "./components/PaymentSummary";
import {ApiParams} from "../../constants/apiParams";
import styled from "styled-components";

const Index = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  return (
    <StyledDiv>
      <Grid container style={{ padding: "20px 0" }}>
        <Grid item xs={12} sm={12} md={8} style={{ marginBottom: 15 }}>
          <CartList />
        </Grid>
        <Grid item xs={12} sm={12} md={4} style={{ backgroundColor: "#f9f9f9" }}>
          <PaymentSummary location={"main"} />
        </Grid>
      </Grid>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  min-height: 60vh;
  .place-holder {
    height: 120px;
    width: 120px;
  }
`

export default Index;
