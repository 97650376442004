import {ORDER} from "../../constants/order";

export function createOrder(data, token) {
    return {
        type: ORDER.PLACE_ORDER.MAIN,
        data,
        token
    }
}

export function fetchOrderList(params, token) {
    return {
        type: ORDER.ORDER_LIST_OK.MAIN,
        params: params,
        token: token
    }
}

export function fileUpload(data, token) {
    return {
        type: ORDER.UPLOAD_FILE_SET.MAIN,
        data,
        token
    }
}

export function clearOrder() {
    return {
        type: ORDER.PLACE_ORDER_CLEAR.MAIN
    }
}

export function clearOrderList() {
    return {
        type: ORDER.ORDER_CLEAR.MAIN
    }
}

export function fetchPayCreate(data, token) {
    return {
        type: ORDER.ONLINE_PAYMENT_OK.MAIN,
        data,
        token
    }
}
