import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const NewButton = (props) => {
  let { classes, loading, text, handleSubmit } = props;
  return (
    <div className={classes.booking} onClick={handleSubmit}>
      {text}
    </div>
  );
};

const bookingStyle = (theme) => ({
  iconText: {
    color: "#313132",
    fontSize: 14,
  },
  booking: {
    backgroundColor: "#d8d8d8",
    margin: "15px 5px",
    borderRadius: "25px",
    cursor: "pointer",
    position: "absolute",
    bottom: 0,
    width: "93%",
    padding: "8px 0px",
    height: 40,
    textAlign: "center",
  },
  buttonIcon: {
    position: "absolute",
    right: 36,
    [theme.breakpoints.down("sm")]: {
      right: 24,
    },
  },
  loader: {
    color: "white",
    position: "absolute",
    marginTop: "0.75%",
  },
});

let style = (theme) => bookingStyle(theme);

export default withStyles(style)(NewButton);
