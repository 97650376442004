import { combineReducers } from "redux";
import homeReducer from "./home";
import detailsReducer from "./details";
import cartReducer from "./cart";
import authReducer from "./auth";
import oderReducer from "./order";
import allProductsReducer from "./allProducts";
import blogReducer from "./blog"

export default combineReducers({
  homeReducer,
  detailsReducer,
  cartReducer,
  authReducer,
  oderReducer,
  allProductsReducer,
  blogReducer
});
