import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { motion } from "framer-motion";
import Menu from "../../components/Menu";
import { PageAnimation } from "../../components/PageAnimation";
import BannerSlider from "./components/BannerSlider";
import FeatureProduct from "./components/FeatureProduct";
import TodaysDeal from "./components/TodaysDeal";
import BrandSlider from "./components/featuredBrand/BrandSlider";
import OurSpeciality from "./components/OurSpeciality/OurSpeciality";
import ExpHealthConcern from "./components/expHealthConcern/ExpHealthConcern";
import EssentialProduct from "./components/EssentialProduct";
import RegularProduct from "./components/RegularProduct";

class HomeIndex extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <motion.div
        exit="exit"
        animate="show"
        variants={PageAnimation}
        initial="hidden"
      >
        <BannerSlider/>
        <BrandSlider/>
        {/*<TodaysDeal/>*/}
        <EssentialProduct title={"COVID Essentials"} />
        {/* <FeatureProduct title={"Regular Items"}/> */}
        <RegularProduct title={"Regular Items"} />
        <FeatureProduct title={"Feature Items"} />
        <ExpHealthConcern />
        <OurSpeciality />
      </motion.div>
    );
  }
}

export default HomeIndex;
