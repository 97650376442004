import {AUTH} from "../../constants/auth";

let initState = {
    otpData: null,
    otpDataLoading: false,
    otpDataErr: null,
    signData: null,
    signDataLoading: false,
    signDataErr: null,
    authData: null,
    authDataLoading: false,
    authDataErr: null,
    token: null,
    addressData: null,
    addressDataLoading: false,
    addressDataErr: null,
    passwordReq: null,
    passwordReqLoading: false,
    passwordReqErr: null,
    codeVerify: null,
    codeVerifyLoading: false,
    codeVerifyErr: null,
    passwordReset: null,
    passwordResetLoading: false,
    passwordResetErr: null
}

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case AUTH.SET_OTP.MAIN:
            return {
                ...state,
                otpData: null,
                otpDataLoading: true,
                otpDataErr: null
            }

        case AUTH.SET_OTP.SUCCESS:
            return {
                ...state,
                otpData: action.result,
                otpDataLoading: false,
                otpDataErr: null
            }

        case AUTH.SET_OTP.FAILURE:
            return {
                ...state,
                otpData: null,
                otpDataLoading: false,
                otpDataErr: action.result
            }

        case AUTH.FORGET_CODE.MAIN:
            return {
                ...state,
                otpData: null,
                otpDataLoading: true,
                otpDataErr: null
            }

        case AUTH.FORGET_CODE.SUCCESS:
            return {
                ...state,
                otpData: action.result,
                otpDataLoading: false,
                otpDataErr: null
            }

        case AUTH.FORGET_CODE.FAILURE:
            return {
                ...state,
                otpData: null,
                otpDataLoading: false,
                otpDataErr: action.result
            }
        case AUTH.FORGET_PASS_REQ.MAIN:
            return {
                ...state,
                passwordReq: null,
                passwordReqLoading: true,
                passwordReqErr: null
            }

        case AUTH.FORGET_PASS_REQ.SUCCESS:
            return {
                ...state,
                passwordReq: action.result,
                passwordReqLoading: false,
                passwordReqErr: null
            }

        case AUTH.FORGET_PASS_REQ.FAILURE:
            return {
                ...state,
                passwordReq: null,
                passwordReqLoading: false,
                passwordReqErr: action.result
            }

        case AUTH.SET_LOGIN.MAIN:
            return {
                ...state,
                authData: null,
                authDataLoading: true,
                authDataErr: null,
                token: null
            }

        case AUTH.SET_LOGIN.SUCCESS:
            return {
                ...state,
                authData: action.result,
                authDataLoading: false,
                authDataErr: null,
                token: action.result.data.token
            }

        case AUTH.SET_LOGIN.FAILURE:
            return {
                ...state,
                authData: null,
                authDataLoading: false,
                authDataErr: action.result,
                token: null
            }

        case AUTH.ALL_ADDRESS.MAIN:
            return {
                ...state,
                addressData: null,
                addressDataLoading: true,
                addressDataErr: null
            }

        case AUTH.ALL_ADDRESS.SUCCESS:
            return {
                ...state,
                addressData: action.result,
                addressDataLoading: false,
                addressDataErr: null
            }

        case AUTH.ALL_ADDRESS.FAILURE:
            return {
                ...state,
                addressData: null,
                addressDataLoading: false,
                addressDataErr: action.result
            }

        case AUTH.UPDATE_ADDRESS.MAIN:
            return {
                ...state,
                addressDataLoading: true
            }

        case AUTH.UPDATE_ADDRESS.SUCCESS:
            let fetched = action.result;
            let data = fetched.data

            let found = state.addressData.data.length
                ? state.addressData.data.find((item) => item.address_id === data.address_id)
                : null;

            let newData = [];
            if (found) {
                newData = [...state.addressData.data];
                let idx = state.addressData.data.findIndex(
                    (item) => item.address_id ===  data.address_id
                );
                newData[idx].firstname = data.firstname
                newData[idx].lastname = data.lastname
                newData[idx].company = data.company
                newData[idx].address_1 = data.address_1
                newData[idx].address_2 = data.address_2
                newData[idx].city = data.city
                newData[idx].postcode = data.postcode
                newData[idx].country_id = data.country_id
                newData[idx].zone_id = data.zone_id
            }

            let newAddressData = {
                message: state.addressData.message,
                data: newData
            }

            return {
                ...state,
                addressData: newAddressData,
                addressDataLoading: false,
                addressDataErr: null
            }

        case AUTH.UPDATE_ADDRESS.FAILURE:
            return {
                ...state,
                addressDataLoading: false,
                addressDataErr: action.result
            }

        case AUTH.UPDATE_PROFILE.MAIN:
            return {
                ...state,
                addressDataLoading: true
            }

        case AUTH.UPDATE_PROFILE.SUCCESS:
            return {
                ...state,
                addressDataLoading: false
            }
        case AUTH.UPDATE_PROFILE.FAILURE:
            return {
                ...state,
                addressDataLoading: false
            }
        case AUTH.DELETE_ADDRESS.MAIN:
            return {
                ...state,
                addressDataLoading: true,
                addressDataErr: null
            }

        case AUTH.DELETE_ADDRESS.SUCCESS:
            let removeItem = action.result
            let updated = []

            let isAvailable = state.addressData.data.length ? state.addressData.data.find (item => item.address_id === parseInt(removeItem.address_id)) : null
            if (isAvailable){
                updated = state.addressData.data.filter (item => item.address_id !== parseInt(removeItem.address_id))
            }

            let newAddress = {
                message: state.addressData.message,
                data: updated
            }

            return {
                ...state,
                addressData: newAddress,
                addressDataLoading: false,
                addressDataErr: null
            }

        case AUTH.DELETE_ADDRESS.FAILURE:
            return {
                ...state,
                addressDataLoading: false,
                addressDataErr: action.result
            }

        case AUTH.SET_SIGN_UP.MAIN:
            return {
                ...state,
                signData: null,
                signDataLoading: true,
                signDataErr: null
            }

        case AUTH.SET_SIGN_UP.SUCCESS:
            return {
                ...state,
                signData: action.result,
                signDataLoading: false,
                signDataErr: null
            }

        case AUTH.SET_SIGN_UP.FAILURE:
            return {
                ...state,
                signData: null,
                signDataLoading: false,
                signDataErr: action.result
            }

        case AUTH.VERIFY_CODE.MAIN:
            return {
                ...state,
                codeVerify: null,
                codeVerifyLoading: true,
                codeVerifyErr: null
            }

        case AUTH.VERIFY_CODE.SUCCESS:
            return {
                ...state,
                codeVerify: action.result,
                codeVerifyLoading: false,
                codeVerifyErr: null
            }

        case AUTH.VERIFY_CODE.FAILURE:
            return {
                ...state,
                codeVerify: null,
                codeVerifyLoading: false,
                codeVerifyErr: action.result
            }

        case AUTH.CHANGE_PASSWORD.MAIN:
            return {
                ...state,
                passwordReset: null,
                passwordResetLoading: true,
                passwordResetErr: null
            }

        case AUTH.CHANGE_PASSWORD.SUCCESS:
            return {
                ...state,
                passwordReset: action.result,
                passwordResetLoading: false,
                passwordResetErr: null
            }

        case AUTH.CHANGE_PASSWORD.FAILURE:
            return {
                ...state,
                passwordReset: null,
                passwordResetLoading: false,
                passwordResetErr: action.result
            }

        case AUTH.LOGOUT_USER.SUCCESS:
            return {
                ...state,
                otpData: null,
                otpDataLoading: false,
                otpDataErr: null,
                signData: null,
                signDataLoading: false,
                signDataErr: null,
                authData: null,
                authDataLoading: false,
                authDataErr: null,
                token: null,
                passwordReq: null,
                passwordReqLoading: false,
                passwordReqErr: null,
                codeVerify: null,
                codeVerifyLoading: false,
                codeVerifyErr: null,
                passwordReset: null,
                passwordResetLoading: false,
                passwordResetErr: null
            }

        default:
            return state
    }
}
