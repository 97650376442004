import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    color: "red",
    padding: "5%",
    minHeight: "60vh",
  },
  linkTxt: {
    color: "#666666",
    "&:hover": {
      color: "#32aeb0",
    },
  },
  sendBtn: {
    backgroundColor: "#32aeb0",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    "&:hover": {
      backgroundColor: "#3ccdd0",
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container className={classes.paper}>
      <Grid item xs={12} md={4} style={{ borderRight: "2px solid #e7e7e7" }}>
        <h1
          className="contact-h1 t_align_l"
          style={{ color: "#505050", fontSize: "30px" }}
        >
          Contact Us
        </h1>
        <div className="m_top_20">
          <p style={{ color: "#666666" }}>
            Registered Office &amp; Billing Address:
          </p>
          <p style={{ fontSize: "120%" }} style={{ color: "#666666" }}>
            <b>
              25/5 Taj Square, Taj Mahal Road <br />
              Mohammadpur, Dhaka 1207 <br />
              Bangladesh
            </b>
          </p>
          <br />
          <p style={{ color: "#666666" }}>
            Contact Number: <b>09617171193</b> <br />
            E-mail: <b>info.Netmed24@gmail.com</b> <br />
            <b>
              <a
                className={classes.linkTxt}
                target="_blank"
                href="https://web.facebook.com/Netmed24-107374681618676"
              >
                Follow us on facebook
              </a>
            </b>{" "}
            <br />
            ©2022 Netmed24
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <div style={{ paddingLeft: "3%" }}>
          <div>
            {!true && (
              <span>
                Your enquiry has been submitted successfully. We will get back
                to you shortly
              </span>
            )}
          </div>
          <h2 style={{ color: "#616161", fontSize: "22px" }}>
            Get in touch with us
          </h2>
          <p style={{ color: "#797979" }}>
            Please feel free to reach out to us. We will be more than happy to
            help.
          </p>
        </div>
        <div class="connect-with" style={{ paddingLeft: "3%" }}>
          <form className="form-horizontal contact-form">
            {/* <input type="hidden" name="_token" id="_token" value=""> */}
            <Grid container>
              <Grid item xs={12} md={6} style={{ paddingRight: "1%" }}>
                <TextField
                  fullWidth
                  name="name"
                  id="outlined-basic"
                  placeholder="Your Name"
                  variant="outlined"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6} style={{ paddingLeft: "1%" }}>
                <TextField
                  fullWidth
                  name="email"
                  id="outlined-basic"
                  placeholder="Email ID"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "2% 0" }}>
                <TextField
                  fullWidth
                  name="msg"
                  id="outlined-basic"
                  placeholder="Enter your message here..."
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
              <button type="submit" className={classes.sendBtn}>
                &nbsp;SEND&nbsp;
              </button>
            </Grid>
            <div className="col-sm-4"></div>
            <div className="clear"></div>
          </form>
          <div class="clear"></div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Contact;
