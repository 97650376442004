import React, { useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import RightIcon from "../../../../localization/images/navRightBlack.svg";
import LeftIcon from "../../../../localization/images/navLeftBlack.svg";
import productImage from "../../../../localization/images/product.jpg";
import { featureData } from "../../../../localization/data/products";
import { Button } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BrandCard from "./BrandCard";
import { fetchFeatureBrandData } from "../../../../redux/actions/home";
import { connect } from "react-redux";
import { ApiParams } from "../../../../constants/apiParams";

const BrandSlider = ({ featureBrand, getFetchFeatureBrnadData }) => {
  useEffect(() => {
    let params = {
      [ApiParams.manufacture_list]: "17,20,22,24",
      [ApiParams.limit]: "1",
      [ApiParams.offset]: "0",
    };
    getFetchFeatureBrnadData(params);
  }, []);

  let brandData = featureBrand?.data ? featureBrand?.data : [];

  const params = {
    slidesPerView: 2,
    spaceBetween: 20,
    speed: 1000,
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    // },
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    breakpoints: {
      960: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      1700: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev'
    // },
  };

  return (
    <StyledToday>
      <Container className="news-events-slider">
        <Row>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 24,
                marginTop: 32,
              }}
            >
              <div className="top-title">FEATURED BRAND</div>
              <div
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400 }}
                >
                  View all
                </div>
                <NavigateNextIcon style={{ color: "#1a1a1a" }} /> */}
              </div>
            </div>
            {brandData.length ? (
              <Swiper {...params}>
                {brandData.map((item) => {
                  return (
                    <div className="news-events-slider__single">
                      <BrandCard item={item} />
                    </div>
                  );
                })}
              </Swiper>
            ) : null}
          </Col>
        </Row>
      </Container>
    </StyledToday>
  );
};

const StyledToday = styled.div`
  background-color: #fff;
  position: relative;

  .container {
    max-width: 95%;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }

  .top-title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    // padding-left: 8px;
    padding-bottom: 16px;
    color: #2fa5aa;
  }

  .news-events-slider {
    &__single {
      &__content {
        background-color: #f0f0f0;
        padding: 20px;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.5);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
        }

        .line {
          margin-top: 4px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
      }

      &__img {
        padding-top: 71%;
        position: relative;
        overflow: hidden;
        z-index: 3;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;
        }

        &:hover {
          img {
            transform: scale(1.05);
            filter: contrast(140%);
          }
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    //margin-left: -50px;
    //margin-right: -50px;
    //padding-right: 50px;
    //padding-left: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      left: 0;
      top: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      background-color: rgba(99, 110, 127, 0);
      right: 0;
      top: 0;
      z-index: 2;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }

  @media (max-width: 1024px) {
    .swiper-button-next {
      position: absolute;
      right: 12px;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: 0;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
    min-height: 123px;
  }

  .product-title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    //margin: -10px 0 7px 0;
    width: 100%;
    text-align: center;
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  .product-weight {
    text-align: center;
    margin-top: 14px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #898686;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .product-meta {
    margin-top: 8px;
    //display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-height: 32px;
    }

    .productPriceWrapper {
      position: relative;
      margin-bottom: 8px;
      .product-price {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #b61026;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #d98b99;
        padding: 0 5px;
        margin-right: 15px;
        text-decoration: line-through;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .cart-button {
      border: 1px solid #000000;
      border-radius: 4px;
      height: 24px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      font-weight: 700;
      color: #000;
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 8%;
      }

      .btn-text {
        @media (max-width: 767px) {
          display: block;
        }
      }

      &:hover {
        color: #ffffff;
        background-color: #009e7f;
        border-color: #009e7f;
      }

      svg {
        fill: currentColor;
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }

      button {
        width: 100%;
        height: 27px;
      }

      .incBtn {
        top: 0;
        justify-content: center;
      }

      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }

      input[type="number"] {
        left: 0;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: block;
  }
`;

function mapStateToProps(state) {
  return {
    featureBrand: state.homeReducer.featureBrand,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFetchFeatureBrnadData: (params) =>
      dispatch(fetchFeatureBrandData(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSlider);
