import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
} from "./file-upload.styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles((theme) => ({
  paper: {
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    // padding: "0 5%",
  },
  browseBtn: {
    backgroundColor: "#1ec3aa",
    borderRadius: 0,
    padding: "0 20px",
    textTransform: "none",
    color: "#fff",
    fontSize: "14px",
  },
}));

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const classes = useStyles();
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <Grid container className={classes.paper}>
      <Grid item xs={12} sm={12} md={6}>
        <FileUploadContainer>
          <DragDropText>
            <CloudUploadIcon /> <br />
            Drag and Drop files <br /> or
          </DragDropText>
          <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
            Browse Files
          </UploadFileBtn>

          <FormField
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            {...otherProps}
          />
        </FileUploadContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={6} style={{marginTop: 40}}>
        <FilePreviewContainer>
          <PreviewList>
            {Object.keys(files).map((fileName, index) => {
              let file = files[fileName];
              let isImageFile = file.type.split("/")[0] === "image";
              let fileType = file.type.split("/")[1];
              return (
                <PreviewContainer key={fileName}>
                  <div style={{ display: "flex", flexDirection: "row", marginLeft: 30 }}>
                    <div>
                      <Avatar
                        src={URL.createObjectURL(file)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        {fileType}
                      </Avatar>
                    </div>
                    <div
                      style={{
                        padding: "0 5px",
                        minWidth: "200px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          color: "#1ec3aa",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file.name}
                      </div>{" "}
                      <div style={{ fontSize: "10px" }}>
                        {convertBytesToKB(file.size)} kb/10MB
                      </div>
                    </div>
                    <div>
                      <Button onClick={() => removeFile(fileName)}>
                        <ClearRoundedIcon/>
                      </Button>
                    </div>
                    {/* <FileMetaData isImageFile={isImageFile}>
                      <span>{file.name}</span>
                      <aside>
                        <span>{convertBytesToKB(file.size)} kb</span>
                        <RemoveFileIcon
                          className="fas fa-trash-alt"
                          onClick={() => removeFile(fileName)}
                        />
                      </aside>
                    </FileMetaData> */}
                  </div>
                </PreviewContainer>
              );
            })}
          </PreviewList>
        </FilePreviewContainer>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
