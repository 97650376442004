import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    // textAlign: "center",
    padding: "5% 15%",
    // fontWeight: 600,
    // paddingBottom: "7.3vw",
    "& .MuiTypography-body1": {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial"`,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textStyle: {
    fontSize: ".9vw",
    color: "#7b7b7b",
    margin: "5px 0",
  },
}));

const FilterByType = () => {
  const classes = useStyles();

  const [categories, setCategories] = React.useState([
    { name: "Napa", qty: 26, checked: true, color: "#d71512" },
    { name: "Paracitamol", qty: 24, checked: false, color: "#7b7b7b" },
    { name: "Parapyrol", qty: 13, checked: false, color: "#7b7b7b" },
    { name: "Histasin", qty: 19, checked: false, color: "#7b7b7b" },
  ]);

  const handleChange = (e) => {
    let cat = [...categories];

    cat.map((item) => {
      if (item.name === e.target.name) {
        item.checked = !item.checked;
        item.checked ? (item.color = "#d71512") : (item.color = "#7b7b7b");
      }
    });
    setCategories(cat);
  };

  return (
    <div className={classes.paper}>
      <Typography
        style={{ fontSize: "1.1vw", color: "#1b1d1e", fontWeight: "500" }}
      >
        FILTER BY TYPE
      </Typography>

      {categories.map((item) => (
        <Box className={classes.boxItem}>
          <Typography
            className={classes.textStyle}
            style={{ color: item.color }}
          >
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle style={{ color: item.color }} />}
              checked={item.checked}
              onChange={handleChange}
              // inputProps={{ "aria-label": "primary checkbox" }}
              name={item.name}
            />
            &nbsp; {item.name}
          </Typography>
          <Typography className={classes.textStyle}>({item.qty})</Typography>
        </Box>
      ))}
    </div>
  );
};

export default FilterByType;
