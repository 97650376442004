export const AUTH = {
    SET_OTP: {
        MAIN: 'SET_OTP',
        SUCCESS: 'SET_OTP_SUCCESS',
        FAILURE: 'SET_OTP_FAILURE'
    },
    SET_LOGIN: {
        MAIN: 'SET_LOGIN',
        SUCCESS: 'SET_LOGIN_SUCCESS',
        FAILURE: 'SET_LOGIN_FAILURE'
    },
    SET_SIGN_UP: {
        MAIN: 'SET_SIGN_UP',
        SUCCESS: 'SET_SIGN_UP_SUCCESS',
        FAILURE: 'SET_SIGN_UP_FAILURE'
    },
    ALL_ADDRESS: {
        MAIN: 'ALL_ADDRESS',
        SUCCESS: 'ALL_ADDRESS_SUCCESS',
        FAILURE: 'ALL_ADDRESS_FAILURE'
    },
    FORGET_PASS_REQ: {
        MAIN: 'FORGET_PASS_REQ',
        SUCCESS: 'FORGET_PASS_REQ_SUCCESS',
        FAILURE: 'FORGET_PASS_REQ_FAILURE'
    },
    FORGET_CODE: {
        MAIN: 'FORGET_CODE',
        SUCCESS: 'FORGET_CODE_SUCCESS',
        FAILURE: 'FORGET_CODE_FAILURE'
    },
    VERIFY_CODE: {
        MAIN: 'VERIFY_CODE',
        SUCCESS: 'VERIFY_CODE_SUCCESS',
        FAILURE: 'VERIFY_CODE_FAILURE'
    },
    CHANGE_PASSWORD: {
        MAIN: 'CHANGE_PASSWORD',
        SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
        FAILURE: 'CHANGE_PASSWORD_FAILURE'
    },
    LOGOUT_USER: {
        MAIN: 'LOGOUT_USER',
        SUCCESS: 'LOGOUT_USER_SUCCESS',
        FAILURE: 'LOGOUT_USER_FAILURE'
    },
    UPDATE_ADDRESS: {
        MAIN: 'UPDATE_ADDRESS',
        SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
        FAILURE: 'UPDATE_ADDRESS_FAILURE'
    },
    UPDATE_PROFILE: {
        MAIN: 'UPDATE_PROFILE',
        SUCCESS: 'UPDATE_PROFILE_SUCCESS',
        FAILURE: 'UPDATE_PROFILE_FAILURE'
    },
    DELETE_ADDRESS: {
        MAIN: 'DELETE_ADDRESS',
        SUCCESS: 'DELETE_ADDRESS_SUCCESS',
        FAILURE: 'DELETE_ADDRESS_FAILURE'
    },
}
