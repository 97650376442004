import React from "react";
import {withStyles} from "@material-ui/core/styles";
import { authStyle } from "./authStyle";
import {Col, Form, Row} from "react-bootstrap";
import WishlistButton from "../../../components/WishlistButton";
import NewButtonSubmit from "../../../components/NewButtonSubmit";

const ContactField = (props) =>{
    let { classes, phone, email, handlePhone, handleEmail, handleCancel } = props;

    return(
        <Form>
            <Form.Group>
                <Row>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={phone}
                            onChange={handlePhone}
                            placeholder={"Your Phone Number"}
                        />
                    </Col>
                    <Col className={classes.formField} md={6} sm={12}>
                        <Form.Label className={classes.formLabel}>Email</Form.Label>
                        <Form.Control
                            type="text"
                            className={classes.formControl}
                            value={email}
                            placeholder={"Your Email"}
                            onChange={handleEmail}
                        />
                    </Col>
                    <Col className="button-field" md={6} sm={12}>
                        <Row>
                            <Col md={12} sm={12}>
                                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
                            </Col>
                            <Col md={12} sm={12}>
                                <NewButtonSubmit text={"Save Changes"} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )

}

let styles = (theme) => authStyle(theme);

export default withStyles(styles)(ContactField);
